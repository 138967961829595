import React, { useState, useEffect } from 'react';

const ModifyCountDialog = ({ modifyItemId, modifyItem, modifyType, modifyCount, setPrintCount, setShowPrintCountDialog }) => {
  const [replace, setReplace] = useState(null);

  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  const updateQuantity = () => {
    setPrintCount(modifyType, modifyItemId, modifyItem, replace);
    setShowPrintCountDialog(false);
  }

  const validateQuantity = (event) => {
    let value = event.target.value;
    setReplace(value.replace(/\D/g,''));
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  return(
    <div className="main-overlay">
      <div style={{ top: `${window.innerHeight/2}px` }} className="main-popup popup-center-always main-popup_center main-popup--thin-padding main-popup_animation">
        <div className="main-popup__container">
          <div className="main-popup__close" onClick={() => setShowPrintCountDialog(false)}>
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.7929 1.7929c.3905-.3905 1.0237-.3905 1.4142 0L9 7.5858l5.7929-5.793c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L10.4142 9l5.7929 5.7929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L9 10.4142l-5.7929 5.7929c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L7.5858 9l-5.793-5.7929c-.3904-.3905-.3904-1.0237 0-1.4142z">
              </path>
            </svg>
          </div>
          <div className="main-popup__subcontainer">
            <div className="flex-table">
              <div className="flex-table__row">
                <div className="flex-table__col flex-table__col--align-left">Name</div>
                <div className="flex-table__col flex-table__col--align-right">{modifyItem.name}</div>
              </div>

              <div className="flex-table__row">
                <div className="flex-table__col flex-table__col--align-left">SKU</div>
                <div className="flex-table__col flex-table__col--align-right">{modifyItem.sku}</div>
              </div>

              <div className="flex-table__row">
                <div className="flex-table__col flex-table__col--align-left">Stock</div>
                <div className="flex-table__col flex-table__col--align-right">{modifyItem.quantity}</div>
              </div>

              { modifyItem.options && modifyItem.options.map(option =>
                <div className="flex-table__row">
                  <div className="flex-table__col flex-table__col--align-left">{ option.name }</div>
                  <div className="flex-table__col flex-table__col--align-right">{ option.value }</div>
                </div>
              )}
            </div>

            <div className="fieldset">
              <div className="field field--medium">
                <span className="fieldset__svg-icon"></span>
                <label className="field__label">Update Quantity</label>
                <input type="text" value={replace} onFocus={focus} onBlur={blur} onChange={(e) => validateQuantity(e)} className="field__input" tabindex="4" maxlength="64" />
                <div className="field__placeholder">Update Quantity</div>
              </div>
            </div>

            <br/>
            { replace && <button className="bottom-5 btn btn-primary btn-medium" onClick={() => updateQuantity()}>Update Print Quantity</button> }
            { !replace && <button className="bottom-5 btn btn-primary btn-medium" disabled>Update Print Quantity</button> }
          </div>
        </div>
      </div>
    </div>
  )

}

export default ModifyCountDialog;


