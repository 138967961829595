import { Link } from "react-router-dom";

const NoLabelTemplatesBox = ({ }) => {
  return(
    <>
      <div className="a-card a-card--normal a-card--warning">
        <div className="a-card__paddings">
          <div className="feature-element has-picture">
            <div className="feature-element__core">
              <div className="feature-element__data">
                <div className="feature-element__title">Create Label Template</div>
                <div className="feature-element__content">
                  <div className="feature-element__text">
                    <strong>You don't have any label templates saved.</strong>
                    <br/>
                    <a target="_blank" href="https://athousandapps.com/ecwid/manage-barcode-label-templates/">
                      Read about label templates here
                    </a>
                  </div>
                  <Link to='/templates/new'>
                    <button type="button" className="btn btn-primary btn-medium feature-element__button">
                      Create a label template
                    </button>
                  </Link>
                </div>
              </div>
              <div className="feature-element__icon"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NoLabelTemplatesBox;
