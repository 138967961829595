export const FIND_OR_CREATE_REQUESTED = 'find_or_create_requested';
export const FIND_OR_CREATE_SUCCESS = 'find_or_create_success';
export const FIND_OR_CREATE_FAILURE = 'find_or_create_failure';

export const ASSIGN_BARCODE_REQUESTED = 'assign_barcode_requested';
export const ASSIGN_BARCODE_SUCCESS = 'assign_barcode_success';
export const ASSIGN_BARCODE_FAILURE = 'assign_barcode_failure';

export const CREATE_BARCODES_REQUESTED = 'create_barcodes_requested';
export const CREATE_BARCODES_SUCCESS = 'create_barcodes_success';
export const CREATE_BARCODES_FAILURE = 'create_barcodes_failure';

export const CREATE_ALL_BARCODES_REQUESTED = 'create_all_barcodes_requested';
export const CREATE_ALL_BARCODES_SUCCESS = 'create_all_barcodes_success';
export const CREATE_ALL_BARCODES_FAILURE = 'create_all_barcodes_failure';

export const UPDATE_INVENTORY_REQUESTED = 'update_inventory_requested';
export const UPDATE_INVENTORY_SUCCESS = 'update_inventory_success';
export const UPDATE_INVENTORY_FAILURE = 'update_inventory_failure';

export const BULK_UPDATE_INVENTORY_REQUESTED = 'bulk_update_inventory_requested';
export const BULK_UPDATE_INVENTORY_SUCCESS = 'bulk_update_inventory_success';
export const BULK_UPDATE_INVENTORY_FAILURE = 'bulk_update_inventory_failure';

export const SEARCH_PRODUCT_REQUESTED = 'search_product_requested';
export const SEARCH_PRODUCT_SUCCESS = 'search_product_success';
export const SEARCH_PRODUCT_FAILURE = 'search_product_failure';

export const REFRESH_BATCHES_REQUESTED = 'refresh_batches_requested';
export const REFRESH_BATCHES_SUCCESS = 'refresh_batches_success';
export const REFRESH_BATCHES_FAILURE = 'refresh_batches_failure';

export const DOWNLOAD_BARCODE_IMAGE_REQUESTED = 'download_barcode_image_requested';
export const DOWNLOAD_BARCODE_IMAGE_SUCCESS = 'download_barcode_image_success';
export const DOWNLOAD_BARCODE_IMAGE_FAILURE = 'download_barcode_image_failure';

export const BARCODE_TEMPLATE_CREATE_REQUESTED = 'barcode_template_create_requested';
export const BARCODE_TEMPLATE_CREATE_SUCCESS = 'barcode_template_create_success';
export const BARCODE_TEMPLATE_CREATE_FAILURE = 'barcode_template_create_failure';

export const BARCODE_TEMPLATE_DELETE_REQUESTED = 'barcode_template_delete_requested';
export const BARCODE_TEMPLATE_DELETE_SUCCESS = 'barcode_template_delete_success';
export const BARCODE_TEMPLATE_DELETE_FAILURE = 'barcode_template_delete_failure';

export const BARCODE_TEMPLATE_INDEX_REQUESTED = 'barcode_template_index_requested';
export const BARCODE_TEMPLATE_INDEX_SUCCESS = 'barcode_template_index_success';
export const BARCODE_TEMPLATE_INDEX_FAILURE = 'barcode_template_index_failure';

export const BARCODE_TEMPLATE_UPDATE_REQUESTED = 'barcode_template_update_requested';
export const BARCODE_TEMPLATE_UPDATE_SUCCESS = 'barcode_template_update_success';
export const BARCODE_TEMPLATE_UPDATE_FAILURE = 'barcode_template_update_failure';

export const BARCODE_TEMPLATE_PREVIEW_REQUESTED = 'barcode_template_preview_requested';
export const BARCODE_TEMPLATE_PREVIEW_SUCCESS = 'barcode_template_preview_success';
export const BARCODE_TEMPLATE_PREVIEW_FAILURE = 'barcode_template_preview_failure';

export const SEARCH_PRODUCT_BARCODE_VALUE_REQUESTED = 'search_product_barcode_value_requested';
export const SEARCH_PRODUCT_BARCODE_VALUE_SUCCESS = 'search_product_barcode_value_success';
export const SEARCH_PRODUCT_BARCODE_VALUE_FAILURE = 'search_product_barcode_value_failure';

// export const REPLACE_PRODUCT_SKU_REQUESTED = 'replace_product_sku_requested';
// export const REPLACE_PRODUCT_SKU_SUCCESS = 'replace_product_sku_success';
// export const REPLACE_PRODUCT_SKU_FAILURE = 'replace_product_sku_failure';

// export const DELETE_PRODUCT_SKU_REQUESTED = 'delete_product_sku_requested';
// export const DELETE_PRODUCT_SKU_SUCCESS = 'delete_product_sku_success';
// export const DELETE_PRODUCT_SKU_FAILURE = 'delete_product_sku_failure';

export const DOWNLOAD_PREVIEW_REQUESTED = 'download_preview_requested'
export const DOWNLOAD_PREVIEW_SUCCESS = 'download_preview_success'
export const DOWNLOAD_PREVIEW_FAILURE = 'download_preview_failure'

export const UPDATE_SELECTED_ITEMS = 'update_selected_items';

export const PRINT_CREATE_REQUESTED = 'print_create_requested';
export const PRINT_CREATE_SUCCESS = 'print_create_success';
export const PRINT_CREATE_FAILURE = 'print_create_failure';

export const ADD_INVENTORY_REQUESTED = 'add_inventory_requested';
export const ADD_INVENTORY_SUCCESS = 'add_inventory_success';
export const ADD_INVENTORY_FAILURE = 'add_inventory_failure';

export const SUBTRACT_INVENTORY_REQUESTED = 'subtract_inventory_requested';
export const SUBTRACT_INVENTORY_SUCCESS = 'subtract_inventory_success';
export const SUBTRACT_INVENTORY_FAILURE = 'subtract_inventory_failure';

export const SET_INVENTORY_REQUESTED = 'set_inventory_requested';
export const SET_INVENTORY_SUCCESS = 'set_inventory_success';
export const SET_INVENTORY_FAILURE = 'set_inventory_failure';

export const SCAN_INVENTORY_REQUESTED = 'scan_inventory_requested';
export const SCAN_INVENTORY_SUCCESS = 'scan_inventory_success';
export const SCAN_INVENTORY_FAILURE = 'scan_inventory_failure';

export const ADD_PLACEHOLDER_ACTION = 'add_placeholder_action';
export const CLEAR_SESSION = 'clear_session';
export const CLEAR_ACTION = 'clear_action';

export const ADD_BULK_PLACEHOLDER_ACTION = 'add_bulk_placeholder_action';
export const CLEAR_BULK_SESSION = 'clear_bulk_session';

export const BULK_INVENTORY_CHANGE_REQUESTED = 'bulk_inventory_change_requested';
export const BULK_INVENTORY_CHANGE_SUCCESS = 'bulk_inventory_change_success';
export const BULK_INVENTORY_CHANGE_FAILURE = 'bulk_inventory_change_failure';

export const BATCH_DETAILS_REQUESTED = 'batch_details_requested';
export const BATCH_DETAILS_SUCCESS = 'batch_details_success';
export const BATCH_DETAILS_FAILURE = 'batch_details_failure';

export const BATCH_INVENTORY_RESULTS_REQUESTED = 'batch_inventory_results_requested';
export const BATCH_INVENTORY_RESULTS_SUCCESS = 'batch_inventory_results_success';
export const BATCH_INVENTORY_RESULTS_FAILURE = 'batch_inventory_results_failure';

export const BATCH_INVENTORY_DOWNLOAD_REQUESTED = 'batch_inventory_download_requested';
export const BATCH_INVENTORY_DOWNLOAD_SUCCESS = 'batch_inventory_download_success';
export const BATCH_INVENTORY_DOWNLOAD_FAILURE = 'batch_inventory_download_failure';

export const FIND_BARCODE_IMAGE_REQUESTED = 'find_barcode_image_requested';
export const FIND_BARCODE_IMAGE_SUCCESS = 'find_barcode_image_success';

export const SETTINGS_REQUESTED = 'settings_requested';
export const SETTINGS_SUCCESS = 'settings_success';
export const SETTINGS_FAILURE = 'settings_failure';

export const SETTINGS_UPDATE_REQUESTED = 'settings_update_requested';
export const SETTINGS_UPDATE_SUCCESS = 'settings_update_success';
export const SETTINGS_UPDATE_FAILURE = 'settings_update_failure';

export const GET_FUNGIBLES_REQUESTED = 'get_fungibles_requested';
export const GET_FUNGIBLES_SUCCESS = 'get_fungibles_success';
export const GET_FUNGIBLES_FAILURE = 'get_fungibles_failure';

export const GET_LINE_SETTINGS_REQUESTED = 'get_line_settings_requested';
export const GET_LINE_SETTINGS_SUCCESS = 'get_line_settings_success';
export const GET_LINE_SETTINGS_FAILURE = 'get_line_settings_failure';

export const BARCODE_TEMPLATES_UPGRADE_REQUESTED = 'barcode_templates_upgrade_requested';
export const BARCODE_TEMPLATES_UPGRADE_SUCCESS = 'barcode_templates_upgrade_success';
export const BARCODE_TEMPLATES_UPGRADE_FAILURE = 'barcode_templates_upgrade_failure';

export const CATEGORIES_REQUESTED = 'categories_requested';
export const CATEGORIES_SUCCESS = 'categories_success';
export const CATEGORIES_FAILURE = 'categories_failure';

export const CREATE_ORDER_ACTION = 'create_order_action';
export const DELETE_ORDER_ACTION = 'delete_order_action';
export const CLEAR_ORDER_ACTIONS = 'clear_order_actions';

export const PUBLISH_ORDER_REQUESTED = 'publish_order_requested';
export const PUBLISH_ORDER_SUCCESS = 'publish_order_success';
export const PUBLISH_ORDER_FAILURE = 'publish_order_failure';

export const READ_ORDER_REQUESTED = 'read_order_requested';
export const READ_ORDER_SUCCESS = 'read_order_success';
export const READ_ORDER_FAILURE = 'read_order_failure';

export const INDEX_ORDER_STATUSES_REQUESTED = 'index_order_statuses_requested';
export const INDEX_ORDER_STATUSES_SUCCESS = 'index_order_statuses_success';
export const INDEX_ORDER_STATUSES_FAILURE = 'index_order_statuses_failure';

export const UPDATE_ORDER_STATUS_REQUESTED = 'update_order_status_requested';
export const UPDATE_ORDER_STATUS_SUCCESS = 'update_order_status_success';
export const UPDATE_ORDER_STATUS_FAILURE = 'update_order_status_failure';

export const CLEAR_ORDER = 'clear_order';
export const SET_CHECKED_LIST = 'set_checked_list';
export const SET_UNPICKABLE_LIST = 'set_unpickable_list';

export const UPLOAD_TOKEN_REQUESTED = 'upload_token_requested';
export const UPLOAD_TOKEN_SUCCESS = 'upload_token_success';

export const LOGO_UPLOAD_REQUESTED = 'logo_upload_requested';
export const LOGO_UPLOAD_SUCCESS = 'logo_upload_success';
export const LOGO_UPLOAD_FAILURE = 'logo_upload_failure';

export const DOWNLOAD_RECEIPT_REQUESTED = 'download_receipt_requested';
export const DOWNLOAD_RECEIPT_SUCCESS = 'download_receipt_success';
export const DOWNLOAD_RECEIPT_FAILURE = 'download_receipt_failure';

export const BATCH_INVENTORY_REVERSE_REQUESTED = 'batch_inventory_reverse_requested';
export const BATCH_INVENTORY_REVERSE_SUCCESS = 'batch_inventory_reverse_success';
export const BATCH_INVENTORY_REVERSE_FAILURE = 'batch_inventory_reverse_failure';

// export const _REQUESTED = '_requested';
// export const _SUCCESS = '_success';
// export const _FAILURE = '_failure';
