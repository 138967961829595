import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { uploadLogo } from '../../redux/actions/settings';

const UploadStoreLogo = ({ payload, settings, uploadLogo }) => {
  const [submitted, setSubmitted] = useState(false);
  const [file, setFile] = useState();
  const [error, setError] = useState(null);

  const handleChange = (e) => {
    if(e.target.files[0]) {
      setSubmitted(false);

      if(e.target.files[0].size <= 1048576) {
        setError(null);
        setFile(e.target.files[0]);
      } else {
        setError('File size is too large (max size 1MB).');
        setFile(null);
      }

    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);

    if (file !== undefined && !file.cancelled && file.name) {
      uploadLogo(payload, file);
    }
  }

  return(
    <div className="a-card a-card--compact">
      <div className="a-card__paddings">
        <h3>Store Logo</h3>
        <h4>Current Logo:</h4>
        { settings.settings.logo_location &&
          <span>
            {settings.settings.logo_location}
          </span> 
        }
        { !settings.settings.logo_location &&
          <span className="warning">No store logo set.</span>
        }
        <br/>
        <br/>
        <h4>Upload New Logo:</h4>
        <div className="muted">
          Supported file types: jpg, jpeg, png
        </div>
        <form onChange={ (e) => handleChange(e) } onSubmit={ (e) => handleSubmit(e) }>
          <input type="file" name="file" accept="image/png, image/jpeg" />
          <br/>
          <br/>
          <button className="btn btn-primary btn-medium" disabled={submitted || !file}>
            Upload Logo
          </button>
        </form>
        { error && 
          <span className="error">{error}</span>
        }
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  settings: state.settings,
});

const mapDispatchToProps = (dispatch) => ({
  uploadLogo: (payload, file) => dispatch(uploadLogo(payload, file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadStoreLogo);