// import logo from './logo.svg';
import './App.css';

import React from 'react';
import MainPage from './components/MainPage';
import ManageBarcodes from './components/ManageBarcodes';
import PrintBarcodes from './components/PrintBarcodes';
import BarcodeTemplates from './components/BarcodeTemplates';
import LiveModify from './components/LiveModify';
import Add from './components/BulkModify/add';
import Remove from './components/BulkModify/remove';
import Update from './components/BulkModify/update';
import BarcodeActions from './components/BarcodeActions';
import BatchUpdateResults from './components/BatchUpdateResults';
import StoreSettings from './components/StoreSettings';
import OrderCreation from './components/OrderCreation';
import PickOrder from './components/PickOrder';

import NewTemplateForm from './components/BarcodeTemplates/NewTemplateForm';
import EditTemplateForm from './components/BarcodeTemplates/EditTemplateForm';

import '@shoelace-style/shoelace/dist/themes/light.css';
import { setBasePath } from '@shoelace-style/shoelace/dist/utilities/base-path';

import {
  BrowserRouter as Router,
  Route,
  Routes
} from 'react-router-dom';

setBasePath('https://cdn.jsdelivr.net/npm/@shoelace-style/shoelace@2.14.0/cdn/');

const App = ({ payload }) => {
  return (
    <Router>
      <div>
        <Routes>
          <Route path='/' element={<MainPage payload={payload} />} />
          <Route path='/manage' element={<ManageBarcodes payload={payload} />} />
          <Route path='/print' element={<PrintBarcodes payload={payload} />} />
          <Route path='/templates' element={<BarcodeTemplates payload={payload} />} />
          <Route path='/templates/new' element={<NewTemplateForm payload={payload} />} />
          <Route path='/templates/edit/:uuid' element={<EditTemplateForm payload={payload} />} />
          <Route path='/inventory/add' element={<Add payload={payload} />} />
          <Route path='/inventory/remove' element={<Remove payload={payload} />} />
          <Route path='/inventory/update' element={<Update payload={payload} />} />
          <Route path='/inventory/live' element={<LiveModify payload={payload} />} />
          <Route path='/barcode_actions/:barcodeValue' element={<BarcodeActions payload={payload} />} />
          <Route path='/update_results' element={<BatchUpdateResults payload={payload} />} />
          <Route path='/store_settings' element={<StoreSettings payload={payload} />} />
          <Route path='/order' element={<OrderCreation payload={payload} />} />
          <Route path='/pick/:orderId?' element={<PickOrder payload={payload} />} />
        </Routes>
      </div>
    </Router>
  )
}

export default App;
