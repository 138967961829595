import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Document, Page, pdfjs } from 'react-pdf';

import { searchProductBarcodeValue, downloadBarcodePreview } from '../../redux/actions/products';
import { indexBarcodeTemplates, previewBarcodeTemplate, getTemplateFungibles, getTemplateLineSettings } from '../../redux/actions/barcodeTemplates';

import LoadingSpinner from '../partials/LoadingSpinner';
import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import PageHeader from "../partials/PageHeader";
import NoLabelTemplatesBox from '../partials/NoLabelTemplatesBox';

const BarcodeActions = ({ payload, getByBarcodeValue, getTemplates, activeBarcode, barcodeTemplates, loadPreview, downloadPreview, downloadUrl, products, templateFungibles, templateLineSettings, loadFungibles, loadLineSettings }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  
  let { barcodeValue } = useParams();

  const [activeTemplate, setActiveTemplate] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [downloadSubmitted, setDownloadSubmitted] = useState(false);

  useEffect(() => {
    getTemplates(payload);
    getByBarcodeValue(payload, barcodeValue);
  }, []);

  useEffect(() => {
    if(downloadUrl && downloadSubmitted) { 
      setDownloadSubmitted(false); 
      let win = window.open(downloadUrl, '_blank');
      win.focus();
    }
  }, [downloadUrl]);

  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  const switchActiveTemplate = (event) => {
    let template = barcodeTemplates.templates.find(template => template.uuid === event.target.value);

    loadFungibles(payload, event.target.value);
    loadLineSettings(payload, event.target.value);

    setActiveTemplate(template);
  }

  useEffect(() => {
    if(templateFungibles && templateLineSettings && barcodeTemplates.fungiblesLoaded && barcodeTemplates.lineSettingsLoaded) {
      let updatedTemplate = activeTemplate;
      updatedTemplate['barcode_enabled'] = templateFungibles[activeTemplate['barcode_element_uuid']]['enabled'];
      updatedTemplate['barcode_line'] = templateFungibles[activeTemplate['barcode_element_uuid']]['line'];
      updatedTemplate['barcode_symbol_enabled'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['enabled'];
      updatedTemplate['barcode_symbol_line'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['line'];
      updatedTemplate['barcode_symbol_priority'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['priority'];
      updatedTemplate['barcode_symbol_label_enabled'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['label_enabled'];
      updatedTemplate['barcode_symbol_label_override'] = templateFungibles[activeTemplate['barcode_symbol_element_uuid']]['label_override'];
      updatedTemplate['product_name_enabled'] = templateFungibles[activeTemplate['product_name_element_uuid']]['enabled'];
      updatedTemplate['product_name_line'] = templateFungibles[activeTemplate['product_name_element_uuid']]['line'];
      updatedTemplate['product_name_priority'] = templateFungibles[activeTemplate['product_name_element_uuid']]['priority'];
      updatedTemplate['product_name_label_enabled'] = templateFungibles[activeTemplate['product_name_element_uuid']]['label_enabled'];
      updatedTemplate['product_name_label_override'] = templateFungibles[activeTemplate['product_name_element_uuid']]['label_override'];
      updatedTemplate['option_1_enabled'] = templateFungibles[activeTemplate['option_1_element_uuid']]['enabled'];
      updatedTemplate['option_1_line'] = templateFungibles[activeTemplate['option_1_element_uuid']]['line'];
      updatedTemplate['option_1_priority'] = templateFungibles[activeTemplate['option_1_element_uuid']]['priority'];
      updatedTemplate['option_1_label_enabled'] = templateFungibles[activeTemplate['option_1_element_uuid']]['label_enabled'];
      updatedTemplate['option_1_label_override'] = templateFungibles[activeTemplate['option_1_element_uuid']]['label_override'];
      updatedTemplate['option_2_enabled'] = templateFungibles[activeTemplate['option_2_element_uuid']]['enabled'];
      updatedTemplate['option_2_line'] = templateFungibles[activeTemplate['option_2_element_uuid']]['line'];
      updatedTemplate['option_2_priority'] = templateFungibles[activeTemplate['option_2_element_uuid']]['priority'];
      updatedTemplate['option_2_label_enabled'] = templateFungibles[activeTemplate['option_2_element_uuid']]['label_enabled'];
      updatedTemplate['option_2_label_override'] = templateFungibles[activeTemplate['option_2_element_uuid']]['label_override'];
      updatedTemplate['option_3_enabled'] = templateFungibles[activeTemplate['option_3_element_uuid']]['enabled'];
      updatedTemplate['option_3_line'] = templateFungibles[activeTemplate['option_3_element_uuid']]['line'];
      updatedTemplate['option_3_priority'] = templateFungibles[activeTemplate['option_3_element_uuid']]['priority'];
      updatedTemplate['option_3_label_enabled'] = templateFungibles[activeTemplate['option_3_element_uuid']]['label_enabled'];
      updatedTemplate['option_3_label_override'] = templateFungibles[activeTemplate['option_3_element_uuid']]['label_override'];
      updatedTemplate['price_enabled'] = templateFungibles[activeTemplate['price_element_uuid']]['enabled'];
      updatedTemplate['price_line'] = templateFungibles[activeTemplate['price_element_uuid']]['line'];
      updatedTemplate['price_priority'] = templateFungibles[activeTemplate['price_element_uuid']]['priority'];
      updatedTemplate['price_label_enabled'] = templateFungibles[activeTemplate['price_element_uuid']]['label_enabled'];
      updatedTemplate['price_label_override'] = templateFungibles[activeTemplate['price_element_uuid']]['label_override'];
      updatedTemplate['brand_enabled'] = templateFungibles[activeTemplate['brand_element_uuid']]['enabled'];
      updatedTemplate['brand_line'] = templateFungibles[activeTemplate['brand_element_uuid']]['line'];
      updatedTemplate['brand_priority'] = templateFungibles[activeTemplate['brand_element_uuid']]['priority'];
      updatedTemplate['brand_label_enabled'] = templateFungibles[activeTemplate['brand_element_uuid']]['label_enabled'];
      updatedTemplate['brand_label_override'] = templateFungibles[activeTemplate['brand_element_uuid']]['label_override'];

      updatedTemplate['line_settings'] = templateLineSettings;

      setActiveTemplate(updatedTemplate);

      loadPreview(payload, updatedTemplate, activeBarcode);
    }
  }, [templateFungibles, templateLineSettings]);

  const handleDownload = () => {
    setDownloadSubmitted(true);
    downloadPreview(payload);
  }

  if(barcodeTemplates.status === 'success' && products.status === 'success' && activeBarcode) {
    return(
      <>
        <PageHeader stripeBackground='#5bc6cc' helpLink="ecwid/print-barcode-labels-2/" />
        <div className="settings-page cf">
          <div className="settings-page__header">
            <div className="named-area">
              <div className="named-area__header">
                <div className="named-area__titles">
                  <div className="named-area__title">Barcode Actions</div>
                </div>
                <div className="named-area__description">
                </div>
              </div>
              <div className="named-area__body">
                <div className="a-card-stack">
                  <div className="a-card a-card--compact">
                    <div className="a-card__paddings">
                    
                      <h3>Preview With Template</h3>
                      { barcodeTemplates.templates.length === 0 && 
                        <NoLabelTemplatesBox/>
                      }
                      { barcodeTemplates.templates.length > 0 &&
                        <div className="fieldset fieldset--select">
                          <div className="field field--medium field--filled">
                            <label className="field__label">Templates</label>
                            <select value={activeTemplate?.uuid} id="templates" onChange={(e) => switchActiveTemplate(e)} className="field__select">
                              <option hidden disabled selected value></option>
                              { barcodeTemplates.templates.map(template =>
                                <option value={template.uuid}>{template.template_name}</option>
                              )}

                            </select>

                            <div className="field__placeholder">Templates</div>
                          </div>
                        </div>
                      }

                    </div>
                  </div>

                  <div className="a-card a-card--compact">
                    <div className="a-card__paddings">
                      <div className="a-card a-card--vertical-compact a-card--info a-card--info-blue">
                        <div className="a-card__paddings">

                          <div className="spacing--mb1">Preview</div>
                          { (Object.keys(activeTemplate).length > 0) && barcodeTemplates.previewUri &&
                            <>
                              <Document className="pdf-document" file={{ url: barcodeTemplates.previewUri }} loading={<LoadingSpinner />}>
                                <Page
                                  className="pdf-page"
                                  pageNumber={1} 
                                  renderTextLayer={false}
                                  renderAnnotationLayer={false}
                                />
                              </Document>
                              { !downloadSubmitted && <button className="btn btn-primary btn-medium top-15" onClick={() => handleDownload()}>Download Preview</button> }
                              { downloadSubmitted && <button className="btn btn-primary btn-medium btn-loading top-15" disabled>Download Preview</button> }
                            </>
                          }
                          { !barcodeTemplates.previewUri && (Object.keys(activeTemplate).length === 0) &&
                            <span className="text-default muted">Select template to preview</span>
                          }
                          { !barcodeTemplates.previewUri && (Object.keys(activeTemplate).length > 0) &&
                            <LoadingSpinner />
                          }
                        </div>
                      </div>

                      <div>{activeBarcode.thumbnailUrl}</div>

                    </div>

                  </div>

                  <div className="a-card a-card--compact">
                    <div className="a-card__paddings">
                      <div className="flex-table">
                        <div className="flex-table__row">
                          <div className="flex-table__col flex-table__col--align-left">Barcode Value</div>
                          <div className="flex-table__col flex-table__col--align-right">{ barcodeValue }</div>
                        </div>

                        <div className="flex-table__row">
                          <div className="flex-table__col flex-table__col--align-left">Product</div>
                          <div className="flex-table__col flex-table__col--align-right">{ activeBarcode.name }</div>
                        </div>

                        { activeBarcode.options?.map(option =>
                          <div className="flex-table__row">
                            <div className="flex-table__col flex-table__col--align-left">{ option.name }</div>
                            <div className="flex-table__col flex-table__col--align-right">{ option.value }</div>
                          </div>
                        )}

                      </div>

                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <PageHeader stripeBackground='#5bc6cc' helpLink="ecwid/print-barcode-labels-2/" />
      <LoadingPlaceholder title="Barcode Actions"/>
    </>
  )
}

const mapStateToProps = state => ({
  products: state.products,
  searchedBarcodes: state.products.searchedBarcodes,
  barcodeTemplates: state.barcodeTemplates,
  activeBarcode: state.products.activeBarcode,
  downloadUrl: state.products.downloadUrl,
  templateFungibles: state.barcodeTemplates.templateFungibles,
  templateLineSettings: state.barcodeTemplates.templateLineSettings,
});

const mapDispatchToProps = (dispatch) => ({
  getByBarcodeValue: (payload, barcodeValue) => dispatch(searchProductBarcodeValue(payload, barcodeValue)),
  getTemplates: (payload) => dispatch(indexBarcodeTemplates(payload)),
  loadPreview: (payload, options, values) => dispatch(previewBarcodeTemplate(payload, options, values)),
  downloadPreview: (payload) => dispatch(downloadBarcodePreview(payload)),
  loadFungibles: (payload, uuid) => dispatch(getTemplateFungibles(payload, uuid)),
  loadLineSettings: (payload, uuid) => dispatch(getTemplateLineSettings(payload, uuid)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeActions);  


