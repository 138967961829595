import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { findOrCreate } from '../../redux/actions/accounts';
import { getSettings } from '../../redux/actions/settings';

import FeatureCard from '../partials/FeatureCard';
import LoadingPlaceholder from '../partials/LoadingPlaceholder';

import { upgradeTemplates } from '../../redux/actions/barcodeTemplates';

const MainPage = ({ accountLoad, settingsLoad, payload, accounts, checkTemplateVersions }) => {
  useEffect(() => {
    initialLoad();
  }, []);

  const initialLoad = () => {
    accountLoad(payload);
    settingsLoad(payload);
    checkTemplateVersions(payload);
  };

  if (accounts.status === 'success') {
    return(
      <div className="settings-page cf">
        <div className="settings-page__header">
          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Barcode Inventory Management</div>
              </div>
              <div className="named-area__description">
              </div>
            </div>
            <div className="named-area__body">
              <FeatureCard
                title="Manage and Print Product Barcode Labels"
                description="Search products, manage and print barcodes associated with them."
                buttonText="Manage your barcodes"
                image={<svg fill="#000000" width="150" height="150" viewBox="0 0 32 32" version="1.1"><path d="M30 14.25h-3.25v-8.25c0-0 0-0.001 0-0.001 0-0.206-0.084-0.393-0.219-0.529v0l-4-4c-0.136-0.136-0.324-0.22-0.531-0.22-0 0-0 0-0 0h-17c-0.414 0-0.75 0.336-0.75 0.75v0 12.25h-2.25c-0.414 0-0.75 0.336-0.75 0.75v0 9c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-8.25h26.5v8.25c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-9c-0-0.414-0.336-0.75-0.75-0.75v0zM5.75 2.75h15.939l3.561 3.561v7.939h-19.5zM26 21.25h-20c-0.414 0-0.75 0.336-0.75 0.75v0 8c0 0.414 0.336 0.75 0.75 0.75h20c0.414-0 0.75-0.336 0.75-0.75v0-8c-0-0.414-0.336-0.75-0.75-0.75v0zM25.25 29.25h-18.5v-6.5h18.5zM26.279 19.199c0.178-0.099 0.322-0.242 0.417-0.415l0.003-0.005c0.027-0.067 0.043-0.145 0.043-0.227 0-0.018-0.001-0.037-0.002-0.054l0 0.002c-0.004-0.21-0.087-0.399-0.221-0.54l0 0c-0.142-0.122-0.327-0.196-0.53-0.196s-0.389 0.074-0.531 0.196l0.001-0.001c-0.122 0.145-0.197 0.334-0.199 0.54v0c-0.001 0.011-0.001 0.024-0.001 0.037 0 0.189 0.077 0.359 0.2 0.483v0c0.132 0.136 0.317 0.221 0.521 0.221 0.007 0 0.014-0 0.021-0l-0.001 0c0.016 0.001 0.034 0.002 0.052 0.002 0.082 0 0.16-0.016 0.231-0.045l-0.004 0.001z"></path></svg>}
                linkUrl="/manage"
                showImage={true}
                stripeBackground='#1774bb'
              />

              <FeatureCard
                title="Create Order"
                description="Create an order by scanning barcodes and setting quantities."
                buttonText="Create Order"
                image={<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 24 24" fill="#000000"><path fill-rule="evenodd" clip-rule="evenodd" d="m13.262 12.51.879-.981a1 1 0 1 1 1.49 1.335l-.877.978-2.77 3.13a.5.5 0 0 1-.714.035l-2.252-2.074a1 1 0 0 1 1.355-1.471L11.5 14.5l1.762-1.99z" fill="#000000"/><path fill-rule="evenodd" clip-rule="evenodd" d="M7.1 5a5.002 5.002 0 0 1 9.8 0H21a1 1 0 0 1 1 1v10a7 7 0 0 1-7 7H9a7 7 0 0 1-7-7V6a1 1 0 0 1 1-1h4.1zm2.07 0a3.001 3.001 0 0 1 5.66 0H9.17zM7 7v1a1 1 0 0 0 2 0V7h6v1a1 1 0 1 0 2 0V7h3v9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V7h3z" fill="#000000"/></svg>}
                linkUrl="/order"
                showImage={true}
                stripeBackground='#39a0a0'
              />

              <FeatureCard
                title="Order Pick and Verify"
                description="Increase order picking accuracy by scanning items from an order list."
                buttonText="Pick and Verify"
                image={<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 18 18" fill="none"><path fill-rule="evenodd" clip-rule="evenodd" d="M8.743.571a.5.5 0 0 1 .514 0l7.5 4.5A.5.5 0 0 1 17 5.5v7a.5.5 0 0 1-.223.416l-7.5 5a.5.5 0 0 1-.534.013l-7.5-4.5A.5.5 0 0 1 1 13V5.5a.5.5 0 0 1 .243-.429l7.5-4.5zM6.222 3.25l6.528 3.917L15.528 5.5 9 1.583 6.222 3.25zM16 6.383l-6.5 3.9v6.283l6.5-4.334V6.383zM8.5 16.617v-6.334L2 6.383v6.334l6.5 3.9zM2.472 5.5 9 9.417l2.778-1.667L5.25 3.833 2.472 5.5z" fill="#000000"/></svg>}
                linkUrl="/pick"
                showImage={true}
                stripeBackground='#070808'
              />

              <FeatureCard
                title="Live Inventory Modification"
                description="Scan barcodes, check inventory levels and make instant changes to inventory."
                buttonText="Modify inventory"
                image={<svg width="150" height="150" viewBox="0 0 24 24"><path fill-rule="evenodd" clip-rule="evenodd" d="M12.261 1.03462C12.6971 1.15253 13 1.54819 13 1.99997V8.99997H19C19.3581 8.99997 19.6888 9.19141 19.8671 9.50191C20.0455 9.8124 20.0442 10.1945 19.8638 10.5038L12.8638 22.5038C12.6361 22.8941 12.1751 23.0832 11.739 22.9653C11.3029 22.8474 11 22.4517 11 22V15H5C4.64193 15 4.3112 14.8085 4.13286 14.498C3.95452 14.1875 3.9558 13.8054 4.13622 13.4961L11.1362 1.4961C11.3639 1.10586 11.8249 0.916719 12.261 1.03462ZM6.74104 13H12C12.5523 13 13 13.4477 13 14V18.301L17.259 11H12C11.4477 11 11 10.5523 11 9.99997V5.69889L6.74104 13Z" fill="#000000"/></svg>}
                linkUrl="/inventory/live"
                showImage={true}
                stripeBackground='#c66628'
              />

              <FeatureCard
                title="Add to Inventory"
                description="Increase product inventories for products associated with scanned barcodes. Quickly receive products and capture information about the products being added to inventory."
                buttonText="Add inventory"
                image={<svg width="150" height="150" viewBox="0 0 18 18"><path d="M9.9968 8.0396h6.0302c.5374 0 .973.4356.973.973s-.4356.973-.973.973H9.9968V16c0 .5523-.4477 1-1 1h-.023c-.5523 0-1-.4477-1-1V9.9884H1.9744C1.4363 9.9884 1 9.5521 1 9.014s.4363-.9744.9744-.9744h5.9994V2c0-.5523.4477-1 1-1h.0244c.5523 0 1 .4477 1 1v6.0396h-.0014z"/></svg>}
                linkUrl="/inventory/add"
                showImage={true}
                stripeBackground='#2c9c7c'
              />

              <FeatureCard
                title="Update Inventory"
                description="Change product inventories for products associated with scanned barcodes. Cycle count products by scanning labels instead of searching for each product in your catalog."
                buttonText="Update inventory"
                image={<svg width="150" height="150" viewBox="0 0 18 18"><path d="M5.0355 15.7929 2.207 12.9645 1.5 16.5l3.5355-.7071z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.8136.9437c.3906-.3905 1.0237-.3905 1.4143 0l2.8284 2.8284c.3905.3905.3905 1.0237 0 1.4142L5.5285 16.7141.2252 17.7748l1.0606-5.3033L12.8136.9437zm.7072 2.1213-9.8995 9.8995 1.4142 1.4142 9.8995-9.8995-1.4142-1.4142zM3.0071 15.1788l-.186-.186-.0464.2325.2324-.0465z"/></svg>}
                linkUrl="/inventory/update"
                showImage={true}
                stripeBackground='#fbe194'
              />

              <FeatureCard
                title="Reduce Inventory"
                description="Reduce product inventories for products associated with scanned barcodes. Ship orders, remove damaged or spoiled products, or set products aside if they shouldn't be sold."
                buttonText="Reduce inventory"
                image={<svg width="150" height="150" viewBox="0 0 10 2"><path d="M0 1c0-.5523.4477-1 1-1h8c.5523 0 1 .4477 1 1s-.4477 1-1 1H1c-.5523 0-1-.4477-1-1Z"/></svg>}
                linkUrl="/inventory/remove"
                showImage={true}
                stripeBackground='#943737'
              />

              <FeatureCard
                title="View Batch Changes"
                description="See and download historical changes from add, update and reduce inventory tools. Live modification is not recorded."
                buttonText="View history"
                image={<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 20 20"><path d="M19 15v2H3V2c1.1046 0 2 .8954 2 2v11h14z"/><path d="M6 7h3v7H6V7zM10 9h3v5h-3V9zM14 5h3v9h-3V5z"/></svg>}
                linkUrl="/update_results"
                showImage={true}
                stripeBackground='#124b5f'
              />

              <FeatureCard
                title="App Settings"
                description="View and modify app settings for this store."
                buttonText="View settings"
                image={<svg xmlns="http://www.w3.org/2000/svg" width="150" height="150" viewBox="0 0 20 20"><path fill-rule="evenodd" clip-rule="evenodd" d="M4.5 5.5a2.489 2.489 0 0 0-.5089-1.512c1.0017-1.0012 2.252-1.7536 3.6505-2.1566C7.9842 2.8034 8.9108 3.5 10 3.5s2.0157-.6966 2.3584-1.6686c1.3984.403 2.6488 1.1554 3.6505 2.1566A2.4885 2.4885 0 0 0 15.5 5.5C15.5 6.8807 16.6193 8 18 8c.0878 0 .1746-.0046.2601-.0134A8.5197 8.5197 0 0 1 18.5 10c0 .6936-.0831 1.3679-.2399 2.0133A2.5403 2.5403 0 0 0 18 12c-1.3807 0-2.5 1.1193-2.5 2.5 0 .5682.1895 1.0921.5089 1.5119-1.0017 1.0012-2.2521 1.7536-3.6505 2.1566C12.0157 17.1965 11.0892 16.5 10 16.5s-2.0157.6965-2.3584 1.6685c-1.3984-.403-2.6488-1.1554-3.6505-2.1566A2.4887 2.4887 0 0 0 4.5 14.5C4.5 13.1193 3.3807 12 2 12c-.0878 0-.1746.0045-.2601.0133A8.5204 8.5204 0 0 1 1.5 10c0-.6937.083-1.368.2399-2.0134A2.529 2.529 0 0 0 2 8c1.3807 0 2.5-1.1193 2.5-2.5zm5.5 7c1.3807 0 2.5-1.1193 2.5-2.5S11.3807 7.5 10 7.5 7.5 8.6193 7.5 10s1.1193 2.5 2.5 2.5z"/></svg>}
                linkUrl="/store_settings"
                showImage={true}
                stripeBackground='#ef8f2f'
              />

              <div className="small-text">
                By using the Barcode Inventory Management app, you acknowledge that you have read and agree to A Thousand Apps Inc.’s <a target="_blank" href="https://athousandapps.com/terms-of-service/">Terms of Service</a> and <a target="_blank" href="https://athousandapps.com/privacy-policy/">Privacy Notice</a>, both of which govern your use of this service.
              </div>
            
            </div>
          </div>
        </div>
      </div>
    )
  }

  return(
    <LoadingPlaceholder title="Barcode Inventory Management" />
  )
}

const mapStateToProps = state => ({
  accounts: state.accounts,
});

const mapDispatchToProps = (dispatch) => ({
  accountLoad: (payload) => dispatch(findOrCreate(payload)),
  settingsLoad: (payload) => dispatch(getSettings(payload)),
  checkTemplateVersions: (payload) => dispatch(upgradeTemplates(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(MainPage); 