import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { generateBarcodes } from '../../redux/actions/barcodes';

const ConfirmGenerationDialog = ({ payload, setShowGenerateDialog, selectedProductList, generateImages, barcodes }) => {
  const [replace, setReplace] = useState(true);

  return(
    <div className="main-overlay">
      <div className="main-popup main-popup-top-only main-popup_center main-popup--thin-padding main-popup_animation">
        <div className="main-popup__container">
          <div className="main-popup__close" onClick={() => setShowGenerateDialog(false)}>
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.7929 1.7929c.3905-.3905 1.0237-.3905 1.4142 0L9 7.5858l5.7929-5.793c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L10.4142 9l5.7929 5.7929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L9 10.4142l-5.7929 5.7929c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L7.5858 9l-5.793-5.7929c-.3904-.3905-.3904-1.0237 0-1.4142z">
              </path>
            </svg>
          </div>
          <div className="main-popup__subcontainer">
            <h3 className="main-popup__title">
              <span className="gwt-InlineHTML">Generate Barcodes</span>
            </h3>
            <div className="main-popup__text" aria-hidden="true" style={{display: 'none'}}></div>
            <div className="main-popup__content">
              <div className="text-default warning">
                Generate barcodes for selected products/variations. Existing barcodes will be deleted and replaced. This action can't be undone.
              </div>
            </div>
            <div className="main-popup__buttons">
              { replace !== null && barcodes.status !== "requesting" &&
                <button type="button" className="btn btn-primary btn-medium" tabindex="1000" onClick={() => generateImages(payload, selectedProductList, replace)}>
                  Generate Barcodes
                </button>
              }
              { replace === null && barcodes.status !== "requesting" &&
                <button type="button" className="btn btn-primary btn-medium" tabindex="1000" disabled>
                  Generate Barcodes
                </button>
              }
              { barcodes.status === "requesting" &&
                <button type="button" className="btn btn-primary btn-medium btn-loading" tabindex="1000" disabled>
                  Generate Barcodes
                </button>
              }
            </div>
            <div className="main-popup__footer-text" aria-hidden="true" style={{display: 'none'}}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  selectedProductList: state.products.selectedProductList,
  barcodes: state.barcodes,
});

const mapDispatchToProps = (dispatch) => ({
  generateImages: (payload, barcodes, replace) => dispatch(generateBarcodes(payload, barcodes, replace)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmGenerationDialog);