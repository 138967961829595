import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  CREATE_ORDER_ACTION,
  DELETE_ORDER_ACTION,
  CLEAR_ORDER_ACTIONS,
  PUBLISH_ORDER_REQUESTED,
  PUBLISH_ORDER_SUCCESS,
  PUBLISH_ORDER_FAILURE,
  DOWNLOAD_RECEIPT_REQUESTED,
  DOWNLOAD_RECEIPT_SUCCESS,
  DOWNLOAD_RECEIPT_FAILURE,
} from '../types';

export const addLineItemPlaceholder = (barcode, actionId) => {
  return {
    type: CREATE_ORDER_ACTION,
    barcode: barcode,
    actionId: actionId
  }
}

export const deleteItem = (actionId) => {
  return {
    type: DELETE_ORDER_ACTION,
    actionId: actionId
  }
}

export const clearOrderActions = () => {
  return {
    type: CLEAR_ORDER_ACTIONS
  }
}

export const publishOrderRequested = () => {
  return {
    type: PUBLISH_ORDER_REQUESTED
  }
};

export const publishOrderSuccess = (payload) => {
  return {
    type: PUBLISH_ORDER_SUCCESS,
    payload: payload
  }
};

export const publishOrderFailure = () => {
  return {
    type: PUBLISH_ORDER_FAILURE
  }
};


export const downloadReceiptRequested = () => {
  return {
    type: DOWNLOAD_RECEIPT_REQUESTED
  }
}

export const downloadReceiptSuccess = (payload) => {
  return {
    type: DOWNLOAD_RECEIPT_SUCCESS,
    payload: payload
  }
}

export const downloadReceiptFailure = (error) => {
  return {
    type: DOWNLOAD_RECEIPT_FAILURE,
    error: error
  }
}


export const addLineItem = (barcode, actionId) => {
  return (dispatch) => {
    dispatch(addLineItemPlaceholder(barcode, actionId));
  }
}

export const deleteLineItem = (actionId) => {
  return (dispatch) => {
    dispatch(deleteItem(actionId));
  }
}

export const clearOrderSession = () => {
  return (dispatch) => {
    dispatch(clearOrderActions());
  }
}

export const publishNewOrder = (ecwidPayload, publishCounts) => {
  return (dispatch) => {
    dispatch(publishOrderRequested());

    let endPoint = Config.baseUrl + Config.order;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.post(endPoint, { counts: publishCounts } , { headers: headers })
      .then((response) => {
        dispatch(publishOrderSuccess(response.data));
      })
      .catch((error) => {
        dispatch(publishOrderFailure());
      });
  }
}

export const downloadOrderReceipt = (ecwidPayload, orderId) => {
  return (dispatch) => {
    dispatch(downloadReceiptRequested());

    let endPoint = Config.baseUrl + Config.order + '/' + orderId + '/invoice-pdf';
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        if(response.data.order_pdf) {
          dispatch(downloadReceiptSuccess(response.data));
        } else {
          dispatch(downloadReceiptFailure('Could not load order invoice. Please try again.'));
        }
      })

  }
}
