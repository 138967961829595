import {
  CREATE_ORDER_ACTION,
  DELETE_ORDER_ACTION,
  CLEAR_ORDER_ACTIONS,
  PUBLISH_ORDER_REQUESTED,
  PUBLISH_ORDER_SUCCESS,
  PUBLISH_ORDER_FAILURE,
  SCAN_INVENTORY_REQUESTED,
  SCAN_INVENTORY_SUCCESS,
  SCAN_INVENTORY_FAILURE,
  DOWNLOAD_RECEIPT_REQUESTED,
  DOWNLOAD_RECEIPT_SUCCESS,
  DOWNLOAD_RECEIPT_FAILURE,
} from '../types';


const INITIAL_STATE = {
  isFetching: false,
  lineItems: [],
  status: 'waiting',
  orderId: null,
  order: null,
  invoicePdf: null,
  invoiceError: null,
  errors: null
};

const orderSessions = (state = INITIAL_STATE, action) => {
  const newState = { ...state };

  switch(action.type) {
    case CREATE_ORDER_ACTION:
      return Object.assign({}, state, {
        lineItems: [...state.lineItems, { sku: action.barcode, placeholder: true }]
      });

    case DELETE_ORDER_ACTION:
      newState.lineItems.splice(action.actionId, 1);
      return newState;

    case SCAN_INVENTORY_REQUESTED:
      newState.isFetching = true;
      newState.status = 'requesting';
      return newState;

    case SCAN_INVENTORY_SUCCESS:
      newState.isFetching = false;
      newState.status = 'success';
      newState.errors = null;
      if(newState.lineItems[action.payload.actionId]){
        newState.lineItems[action.payload.actionId] = action.payload.item;
      }
      return newState;

    case SCAN_INVENTORY_FAILURE:
      newState.isFetching = false;
      newState.status = 'waiting';
      newState.errors = action.error.error;
      if(newState.lineItems[action.error.actionId]){
        newState.lineItems[action.error.actionId] = action.error;
      }
      return newState;

    case PUBLISH_ORDER_REQUESTED:
      newState.isFetching = true;
      newState.status = 'requesting';
      newState.orderId = null;
      newState.order = null;
      newState.errors = null;
      return newState;

    case PUBLISH_ORDER_SUCCESS:
      newState.isFetching = false;
      newState.status = 'success';
      newState.orderId = action.payload.orderId;
      newState.order = action.payload.order;
      return newState

    case PUBLISH_ORDER_FAILURE:
      newState.isFetching = false;
      newState.status = 'error';
      newState.error = action.payload.error;
      return newState

    case DOWNLOAD_RECEIPT_REQUESTED:
      newState.invoiceError = null;
      newState.invoicePdf = null;
      return newState;

    case DOWNLOAD_RECEIPT_SUCCESS:
      newState.invoicePdf = action.payload.order_pdf;
      return newState;

    case DOWNLOAD_RECEIPT_FAILURE:
      newState.invoiceError = action.error;
      return newState;

    case CLEAR_ORDER_ACTIONS:
      return INITIAL_STATE;

    default:
      return state;
  }
}

export default orderSessions;
