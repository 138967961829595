import {
  READ_ORDER_REQUESTED,
  READ_ORDER_SUCCESS,
  READ_ORDER_FAILURE,
  UPDATE_ORDER_STATUS_REQUESTED,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_FAILURE,
  INDEX_ORDER_STATUSES_REQUESTED,
  INDEX_ORDER_STATUSES_SUCCESS,
  INDEX_ORDER_STATUSES_FAILURE,
  CLEAR_ORDER,
  SET_CHECKED_LIST,
  SET_UNPICKABLE_LIST,
  CLEAR_ORDER_ACTIONS,
} from '../types';


const INITIAL_STATE = {
  isFetching: false,
  lineItems: [],
  checkedItems: [],
  unpickableItems: [],
  status: 'waiting',
  orderId: null,
  order: null,
  fulfillmentStatuses: [],
  paymentStatuses: [],
  fulfillmentStatus: null,
  orderStatusesFetching: false,
  orderStatusestMessage: null,
  errors: null
};


const pickOrder = (state = INITIAL_STATE, action) => {
  const newState = { ...state };

  switch(action.type) {
    case READ_ORDER_REQUESTED:
      newState.isFetching = true;
      newState.status = 'requesting';
      newState.lineItems = [];
      newState.checkedItems = [];
      newState.unpickableItems = [];
      return newState;

    case READ_ORDER_SUCCESS:
      newState.isFetching = false;
      newState.status = 'success';
      newState.orderId = action.payload.order.order_id;
      newState.lineItems = action.payload.order.line_items;
      newState.fulfillmentStatus = action.payload.order.fulfillment_status;
      return newState;

    case READ_ORDER_FAILURE:
      newState.isFetching = false;
      newState.status = 'failure';
      return newState;

    case UPDATE_ORDER_STATUS_REQUESTED:
      newState.isFetching = true;
      newState.status = 'requesting';
      newState.orderStatusestMessage = null;
      return newState;

    case UPDATE_ORDER_STATUS_SUCCESS:
      newState.isFetching = false;
      newState.status = 'waiting';
      newState.orderStatusestMessage = "Order Status Updated";
      return newState;

    case INDEX_ORDER_STATUSES_REQUESTED:
      newState.fulfillmentStatuses = [];
      newState.paymentStatuses = [];
      newState.orderStatusesFetching = true;
      return newState;

    case INDEX_ORDER_STATUSES_SUCCESS:
      newState.fulfillmentStatuses = action.payload.statuses.fulfillment_statuses;
      newState.paymentStatuses = action.payload.statuses.payment_statuses;
      newState.orderStatusesFetching = false;
      return newState;

    case SET_CHECKED_LIST:
      newState.checkedItems = action.checkedList;
      return newState;

    case SET_UNPICKABLE_LIST:
      newState.unpickableItems = action.unpickableList;
      return newState;

    case READ_ORDER_FAILURE:
      return newState;

    case CLEAR_ORDER:
      newState.isFetching = false;
      newState.lineItems = [];
      newState.checkedItems = [];
      newState.unpickableItems = [];
      newState.status = 'waiting';
      newState.orderId = null;
      newState.order = null;
      newState.fulfillmentStatus = null;
      newState.errors = null;
      newState.orderStatusestMessage = null;
      return newState;

    case CLEAR_ORDER_ACTIONS:
      newState.orderStatusestMessage = null;
      return newState;

    default:
      return state;
  }
}

export default pickOrder;