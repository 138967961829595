import { useNavigate, Link } from "react-router-dom";

const PageHeader = ({ stripeBackground, helpLink }) => {
  let navigate = useNavigate();
  const location = window.location.href;

  return(
    <div className="utilities-bar__tight">
      <div className="utilities-bar__body" style={{ 'padding-left': '40px', 'border-bottom': `2px solid ${stripeBackground}` }}>
        <span className="utilities-bar__left">
          <span className="utilities-bar__item">
            <Link to="/">
              <button type="button" className="btn btn-link btn-medium">
                <span className="svg-icon">
                  <svg width="18" height="18" viewBox="0 0 32 32" id="svg5" version="1.1">
                    <defs id="defs2"/>
                    <g id="layer1" transform="translate(-108,-100)">
                      <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                      <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    </g>
                  </svg>
                </span>
                <span className="gwt-InlineLabel">Home</span>
              </button>
            </Link>
          </span>

          <span className="utilities-bar__item utilities-bar__left-border">
            <Link to="/manage">
              <button type="button" className="btn btn-link btn-medium" disabled={ location.endsWith('/manage') }>
                <span className="svg-icon">
                  <svg width="18" height="18" viewBox="0 0 32 32" id="svg5" version="1.1">
                    <path d="M30 14.25h-3.25v-8.25c0-0 0-0.001 0-0.001 0-0.206-0.084-0.393-0.219-0.529v0l-4-4c-0.136-0.136-0.324-0.22-0.531-0.22-0 0-0 0-0 0h-17c-0.414 0-0.75 0.336-0.75 0.75v0 12.25h-2.25c-0.414 0-0.75 0.336-0.75 0.75v0 9c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-8.25h26.5v8.25c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-9c-0-0.414-0.336-0.75-0.75-0.75v0zM5.75 2.75h15.939l3.561 3.561v7.939h-19.5zM26 21.25h-20c-0.414 0-0.75 0.336-0.75 0.75v0 8c0 0.414 0.336 0.75 0.75 0.75h20c0.414-0 0.75-0.336 0.75-0.75v0-8c-0-0.414-0.336-0.75-0.75-0.75v0zM25.25 29.25h-18.5v-6.5h18.5zM26.279 19.199c0.178-0.099 0.322-0.242 0.417-0.415l0.003-0.005c0.027-0.067 0.043-0.145 0.043-0.227 0-0.018-0.001-0.037-0.002-0.054l0 0.002c-0.004-0.21-0.087-0.399-0.221-0.54l0 0c-0.142-0.122-0.327-0.196-0.53-0.196s-0.389 0.074-0.531 0.196l0.001-0.001c-0.122 0.145-0.197 0.334-0.199 0.54v0c-0.001 0.011-0.001 0.024-0.001 0.037 0 0.189 0.077 0.359 0.2 0.483v0c0.132 0.136 0.317 0.221 0.521 0.221 0.007 0 0.014-0 0.021-0l-0.001 0c0.016 0.001 0.034 0.002 0.052 0.002 0.082 0 0.16-0.016 0.231-0.045l-0.004 0.001z"></path>
                  </svg>
                </span>
                <span className="gwt-InlineLabel">Labels + Print</span>
              </button>
            </Link>
          </span>

          <span className="utilities-bar__item utilities-bar__left-border">
            <Link to="/order">
              <button type="button" className="btn btn-link btn-medium" disabled={ location.endsWith('/order') }>
                <span className="svg-icon">
                  <svg width="18" height="18" viewBox="0 0 32 32" id="svg5" version="1.1" style={{ 'margin-top': '3px' }}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="m13.262 12.51.879-.981a1 1 0 1 1 1.49 1.335l-.877.978-2.77 3.13a.5.5 0 0 1-.714.035l-2.252-2.074a1 1 0 0 1 1.355-1.471L11.5 14.5l1.762-1.99z"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M7.1 5a5.002 5.002 0 0 1 9.8 0H21a1 1 0 0 1 1 1v10a7 7 0 0 1-7 7H9a7 7 0 0 1-7-7V6a1 1 0 0 1 1-1h4.1zm2.07 0a3.001 3.001 0 0 1 5.66 0H9.17zM7 7v1a1 1 0 0 0 2 0V7h6v1a1 1 0 1 0 2 0V7h3v9a5 5 0 0 1-5 5H9a5 5 0 0 1-5-5V7h3z"/>
                  </svg>
                </span>
                <span className="gwt-InlineLabel">Create Order</span>
              </button>
            </Link>
          </span>

          <span className="utilities-bar__item utilities-bar__left-border">
            <Link to="/pick">
              <button type="button" className="btn btn-link btn-medium" disabled={ location.endsWith('/pick') }>
                <span className="svg-icon">
                  <svg width="18" height="18" viewBox="0 0 32 32" id="svg5" version="1.1" style={{ 'margin-top': '3px' }}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M8.743.571a.5.5 0 0 1 .514 0l7.5 4.5A.5.5 0 0 1 17 5.5v7a.5.5 0 0 1-.223.416l-7.5 5a.5.5 0 0 1-.534.013l-7.5-4.5A.5.5 0 0 1 1 13V5.5a.5.5 0 0 1 .243-.429l7.5-4.5zM6.222 3.25l6.528 3.917L15.528 5.5 9 1.583 6.222 3.25zM16 6.383l-6.5 3.9v6.283l6.5-4.334V6.383zM8.5 16.617v-6.334L2 6.383v6.334l6.5 3.9zM2.472 5.5 9 9.417l2.778-1.667L5.25 3.833 2.472 5.5z" />
                  </svg>
                </span>
                <span className="gwt-InlineLabel">Pick + Verify</span>
              </button>
            </Link>
          </span>


          <span className="utilities-bar__item utilities-bar__left-border">
            <Link to="/inventory/live">
              <button type="button" className="btn btn-link btn-medium" disabled={ location.endsWith('/inventory/live') }>
                <span className="svg-icon">
                  <svg width="18" height="18" viewBox="0 0 32 32" id="svg5" version="1.1" style={{ 'margin-top': '2px' }}>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.261 1.03462C12.6971 1.15253 13 1.54819 13 1.99997V8.99997H19C19.3581 8.99997 19.6888 9.19141 19.8671 9.50191C20.0455 9.8124 20.0442 10.1945 19.8638 10.5038L12.8638 22.5038C12.6361 22.8941 12.1751 23.0832 11.739 22.9653C11.3029 22.8474 11 22.4517 11 22V15H5C4.64193 15 4.3112 14.8085 4.13286 14.498C3.95452 14.1875 3.9558 13.8054 4.13622 13.4961L11.1362 1.4961C11.3639 1.10586 11.8249 0.916719 12.261 1.03462ZM6.74104 13H12C12.5523 13 13 13.4477 13 14V18.301L17.259 11H12C11.4477 11 11 10.5523 11 9.99997V5.69889L6.74104 13Z"/>
                  </svg>
                </span>
                <span className="gwt-InlineLabel">Live Modify</span>
              </button>
            </Link>
          </span>

          <span className="utilities-bar__item utilities-bar__left-border">
            <Link to="/inventory/add">
              <button type="button" className="btn btn-link btn-medium" disabled={ location.endsWith('/inventory/add') }>
                <span className="svg-icon">
                  <svg width="18" height="18" viewBox="0 0 32 32" id="svg5" version="1.1" style={{ 'margin-top': '3px' }}>
                    <path d="M9.9968 8.0396h6.0302c.5374 0 .973.4356.973.973s-.4356.973-.973.973H9.9968V16c0 .5523-.4477 1-1 1h-.023c-.5523 0-1-.4477-1-1V9.9884H1.9744C1.4363 9.9884 1 9.5521 1 9.014s.4363-.9744.9744-.9744h5.9994V2c0-.5523.4477-1 1-1h.0244c.5523 0 1 .4477 1 1v6.0396h-.0014z"/>
                  </svg>
                </span>
                <span className="gwt-InlineLabel">Add Inventory</span>
              </button>
            </Link>
          </span>

          <span className="utilities-bar__item utilities-bar__left-border">
            <Link to="/inventory/update">
              <button type="button" className="btn btn-link btn-medium" disabled={ location.endsWith('/inventory/update') }>
                <span className="svg-icon">
                  <svg width="18" height="18" viewBox="0 0 32 32" id="svg5" version="1.1" style={{ 'margin-top': '3px' }}>
                    <path d="M5.0355 15.7929 2.207 12.9645 1.5 16.5l3.5355-.7071z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.8136.9437c.3906-.3905 1.0237-.3905 1.4143 0l2.8284 2.8284c.3905.3905.3905 1.0237 0 1.4142L5.5285 16.7141.2252 17.7748l1.0606-5.3033L12.8136.9437zm.7072 2.1213-9.8995 9.8995 1.4142 1.4142 9.8995-9.8995-1.4142-1.4142zM3.0071 15.1788l-.186-.186-.0464.2325.2324-.0465z"/>
                  </svg>
                </span>
                <span className="gwt-InlineLabel">Update Inventory</span>
              </button>
            </Link>
          </span>

          <span className="utilities-bar__item utilities-bar__left-border">
            <Link to="/inventory/remove">
              <button type="button" className="btn btn-link btn-medium" disabled={ location.endsWith('/inventory/remove') }>
                <span className="svg-icon">
                  <svg width="18" height="18" viewBox="0 0 32 32" id="svg5" version="1.1" style={{ 'margin-top': '8px' }}>
                    <path d="M0 1c0-.5523.4477-1 1-1h8c.5523 0 1 .4477 1 1s-.4477 1-1 1H1c-.5523 0-1-.4477-1-1Z"/>
                  </svg>
                </span>
                <span className="gwt-InlineLabel">Reduce Inventory</span>
              </button>
            </Link>
          </span>

        </span>

        <span className="utilities-bar__right">
          <span className="utilities-bar__item">
            
            <button type="button" className="btn btn-link btn-medium" onClick={() => navigate(-1)}>
              <span className="svg-icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M5.284 9.9992H15c.5523 0 1-.4477 1-1 0-.5522-.4477-1-1-1H5.284l2.7725-3.0035c.3746-.4059.3493-1.0385-.0565-1.4131-.4058-.3746-1.0385-.3493-1.413.0565L2.2651 8.321a1 1 0 0 0 0 1.3565l4.3217 4.6819c.3746.4058 1.0073.4311 1.4131.0565.4058-.3746.4311-1.0072.0565-1.4131L5.284 9.9992z"/>
                </svg>
              </span>
              <span className="gwt-InlineLabel">Back</span>
            </button>

          </span>
        </span>

      </div>

      <div className="utilities-bar__footer utilities-bar__footer-left">
        <a href={`https://athousandapps.com/${helpLink}`} target="_blank">See more details on using this page here.</a>
      </div>
    </div>
  )
}

export default PageHeader;
