import React, { useState, useEffect } from 'react';

const IncorrectScanDialog = ({ setShowIncorrectScanDialog, focusScanArea }) => {
  useEffect(() => {
    let confirm = document.getElementById('confirm-button');
    confirm.focus();
  }, []);
  
  const close = () => {
    setShowIncorrectScanDialog(false);
    focusScanArea();
  }

  return(
    <div className="main-overlay main-overlay__error">
      <div style={{ top: `${window.innerHeight/2}px` }} className="main-popup popup-center-always main-popup_center main-popup--thin-padding main-popup_animation">
        <div className="main-popup__container">
          <div className="main-popup__close" onClick={() => close()}>
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.7929 1.7929c.3905-.3905 1.0237-.3905 1.4142 0L9 7.5858l5.7929-5.793c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L10.4142 9l5.7929 5.7929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L9 10.4142l-5.7929 5.7929c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L7.5858 9l-5.793-5.7929c-.3904-.3905-.3904-1.0237 0-1.4142z">
              </path>
            </svg>
          </div>
          <div className="main-popup__subcontainer">
            <h2 className="error">Incorrect Item</h2>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12 3a9 9 0 1 0 0 18 9 9 0 0 0 0-18zM1 12C1 5.925 5.925 1 12 1s11 4.925 11 11-4.925 11-11 11S1 18.075 1 12z" fill="currentColor"/>
                <path d="M10.586 12 9 13.586A1 1 0 1 0 10.414 15L12 13.414 13.586 15A1 1 0 1 0 15 13.586L13.414 12 15 10.414A1 1 0 0 0 13.586 9L12 10.586 10.414 9A1 1 0 1 0 9 10.414L10.586 12z" fill="currentColor"/>
              </svg>
              <span className="left-15">The scanned item is not in this order. Do not pick.</span>
            </div>

            <br/>
            <button id="confirm-button" className="bottom-5 btn btn-primary btn-medium" onClick={() => close()}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default IncorrectScanDialog;
