import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import ModifyRow from "./ModifyRow";
import SubmittedMessage from "./SubmittedMessage";
import MultipleBatchesMessage from './MultipleBatchesMessage';
import LoadingSpinner from '../partials/LoadingSpinner';
import PageHeader from '../partials/PageHeader';

import { addBulkPlaceholderAction, clearBulkActions, publishBulkActions } from '../../redux/actions/bulkInventorySessions';
import { scanInventory } from '../../redux/actions/inventorySessions';

const Add = ({ payload, clearSession, postScanInventory, addAction, bulkInventorySession, accountUnits, settings, publishBulkAdd }) => {
  const [submitted, setSubmitted] = useState(false);
  const [scanTerm, setScanTerm] = useState('');
  const [changeNotes, setChangeNotes] = useState('');
  const [bulkChangeValues, setBulkChangeValues] = useState([]);
  const [bulkChangeTotals, setBulkChangeTotals] = useState([]);
  const [totalChangeValues, setTotalChangeValues] = useState(0);
  const [totalPriceValue, setTotalPriceValue] = useState(0.0);

  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  const updateScan = (event) => {
    let value = event.target.value;
    setScanTerm(value);
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const updateNotes = (event) => {
    let value = event.target.value;
    setChangeNotes(value);
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const publishSession = () => {
    let submitCounts = [];

    bulkInventorySession.events.map((event, index) => {
      if(!(event.error)){
        event.modifyCount = bulkChangeValues[event.sku];
        submitCounts.push(event);
      }
    })

    let textArea = document.getElementById("notes");
    let clearButton = document.getElementById("clear-button");
    let submitButton = document.getElementById("submit-button");

    textArea.disabled = "true";
    clearButton.disabled = "true";
    submitButton.disabled = "true";

    publishBulkAdd(payload, submitCounts, changeNotes);
    setSubmitted(true);
  }

  const setValue = (value, batchEvent) => {
    setBulkChangeTotals(bulkChangeTotals=>({
      ...bulkChangeTotals,
      [batchEvent.sku]: ((batchEvent?.price || batchEvent?.defaultDisplayedPrice || 0.0) * value)
    }));

    setBulkChangeValues(bulkChangeValues=>({
      ...bulkChangeValues,
      [batchEvent.sku]: value
    }));
  }

  const resetSession = () => {
    clearSession();
    setBulkChangeValues([]);
    setBulkChangeTotals([]);
    setTotalChangeValues(0);
    setTotalPriceValue(formatPriceValue(0.0));
  }

  const setTotals = () => {
    let tempValue = 0;
    let tempTotal = 0.0;

    for (const [key, value] of Object.entries(bulkChangeValues)) {
      tempValue += (parseInt(value) || 0);
    }

    for (const [key, value] of Object.entries(bulkChangeTotals)) {
      tempTotal += (parseFloat(value) || 0);
    }

    setTotalChangeValues(tempValue);
    setTotalPriceValue(formatPriceValue(tempTotal));
  }

  const formatPriceValue = (value) => {
    return accountUnits.currencyPrefix + ' ' + value + ' ' + accountUnits.currencySuffix;
  }

  const bulkEventsLoaded = () => {
    let firstLoading = bulkInventorySession.events.find((event) => {
      return event.placeholder
    });

    return !firstLoading;
  }

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(scanTerm !== ''){
        addAction(scanTerm);
        postScanInventory(payload, scanTerm, bulkInventorySession.events.length);
        setScanTerm('');
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [scanTerm, 500]);

  useEffect(() => {
    clearSession();
    flashScanBar();
  }, []);

  useEffect(() => {
    setTotals();
  }, [bulkChangeValues]);

  const flashScanBar = () => {
    let scanBarElement = document.querySelector("#scan-bar");

    setTimeout(() => {
      scanBarElement?.classList?.add("blink-action");
    }, 500);

    setTimeout(() => {
      scanBarElement?.classList?.remove("blink-action");
    }, 1500);
  }

  return(
    <>
      <PageHeader stripeBackground='#2c9c7c' helpLink="ecwid/add-inventory-2/" />
      <div className="settings-page cf">
        <div className="settings-page__header">
          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Add Inventory</div>
              </div>
              <div className="named-area__description">
                <p>Select the "Scan Barcode" bar and scan a generated Code 128 SKU (the generated barcodes from the "Labels + Print" section of this app, or similar) with a barcode scanner. This scan should also work with UPCs, EANs or ISBN values if they have been set in products or variations.</p>
              </div>
            </div>
            <div className="named-area__body">

              <div className="a-card a-card--compact">
                <div className="a-card__paddings">
                  <div className="iconable-block iconable-block--hide-in-mobile">
                    <div className="iconable-block__infographics">
                      <span className="iconable-block__icon">
                        <svg width="1.25rem" width="18" height="18" fill="#189dee" viewBox="0 0 32 32" id="svg5" version="1.1">
                          <defs id="defs2"/>
                          <g id="layer1" transform="translate(-108,-100)">
                            <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="iconable-block__content">
                      <div className="status-block">
                        <div className="status-block__central">
                          <div className="status-block__header">
                            <span className="status-block__title">Scan Barcode (SKU, UPC, EAN)</span>
                            <span className="status-block__edit">Edit</span>
                          </div>
                          <div className="status-block__content">
                            <div className="fieldset fieldset__max">
                              <div id="scan-bar" className="field field--large">
                                <span className="fieldset__svg-icon"></span>
                                <label className="field__label">Scan Value</label>
                                
                                { !submitted && <input type="text" value={scanTerm} onFocus={focus} onBlur={blur} onChange={updateScan} className="field__input" tabindex="4" /> }
                                { submitted && <input type="text" value={scanTerm} onFocus={focus} onBlur={blur} onChange={updateScan} className="field__input" tabindex="4" disabled /> }
                                
                                <div className="field__placeholder">Click here to begin scanning</div>
                                <span className="field-state--success"></span>
                                <span className="field-state--close"></span>
                              </div>
                              <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Add Inventory Session</div>
              </div>
              <div className="named-area__description">
                <p>When items are scanned, they should appear below. Add a value to the "Modify Quantity" field for each item. When submitted, the quantity of that item that's in stock will change by the set amount.</p>
                <p>You can add a description of what's happening to the "Add Notes" section. For example, you might be receiving a shipment of products from a given carrier, so you might want to log the shipment number, or if certain products needed to be sent back because of damage, etc.</p>
                <p>Once quantities have been set and information has been added to the notes section, click the "Submit Changes" button to submit the inventory modifications.</p>
              </div>
            </div>
            <div className="named-area__body">
              { submitted && 
                <SubmittedMessage
                  payload={payload}
                />
              }
              { submitted && bulkInventorySession.batchId === 'multiple batches' &&
                <MultipleBatchesMessage />
              }

              <div className="a-card a-card--compact">
                <div className="a-card__paddings">
                  <div className="fieldset fieldset--textarea bottom-5">
                    <div className="field">
                        <textarea id="notes" className="field__textarea" tabindex="1" maxlength="200" onFocus={focus} onBlur={blur} onChange={updateNotes}></textarea>
                        <div className="field__placeholder">Add Notes</div>
                    </div>
                  </div>
                  <div className="flex-container">
                    { settings.display_totals && <div className="flex-1">
                      <b>Price Total</b>: {totalPriceValue}
                    </div> }
                    <div className="flex-container flex-end flex-1 left-15">
                      { !submitted && !bulkInventorySession.isFetching && <button id="clear-button" className="btn btn-default btn--destructive btn-medium flex-1 fit-content" onClick={() => resetSession()}>Clear session</button> }
                      { !submitted && bulkInventorySession.isFetching && <button id="clear-button" className="btn btn-default btn--destructive btn-medium flex-1 fit-content" disabled>Clear session</button> }
                      { submitted && <button id="clear-button" className="btn btn-default btn--destructive btn-medium flex-1 fit-content" disabled>Clear session</button> }

                      { !submitted && !bulkInventorySession.isFetching && (totalChangeValues > 0) && bulkEventsLoaded() && <button id="submit-button" className="btn btn-primary btn-medium flex-1 fit-content left-15" onClick={() => publishSession()}>Submit Changes</button> }
                      { !submitted && (bulkInventorySession.isFetching || (totalChangeValues === 0) || !bulkEventsLoaded()) && <button id="submit-button" className="btn btn-primary btn-medium flex-1 fit-content left-15" disabled>Submit Changes</button> }
                      { submitted && (bulkInventorySession.batchId && bulkInventorySession.batchId !== 'multiple batches' && bulkInventorySession?.batchDetails?.status !== 'COMPLETED') &&
                        <LoadingSpinner statusText="Applying Changes" /> 
                      }
                      { submitted && (bulkInventorySession.batchId && bulkInventorySession?.batchDetails?.status === 'COMPLETED') &&
                        <div className="text-default muted">Changes complete</div>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div className="filtered-list">
                <div className="filtered-list__items long-list">
                  { bulkInventorySession.events.toReversed().map((event, index) =>
                    <ModifyRow
                      bulkValues={bulkChangeValues}
                      setValue={setValue}
                      actionId={bulkInventorySession.events.length - 1 - index}
                      submitted={submitted}
                      batchEvent={event}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}


const mapStateToProps = state => ({
  accountUnits: state.accounts.account.store_details.formatsAndUnits,
  bulkInventorySession: state.bulkInventorySessions,
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  postScanInventory: (payload, barcode, actionId) => dispatch(scanInventory(payload, barcode, actionId)),
  addAction: (barcode, actionType) => dispatch(addBulkPlaceholderAction(barcode)),
  clearSession: () => dispatch(clearBulkActions()),
  publishBulkAdd: (payload, actions, comments) => dispatch(publishBulkActions(payload, 'add', actions, comments)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Add);
