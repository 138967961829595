import { combineReducers } from 'redux';
import accounts from './accounts';
import barcodes from './barcodes';
import barcodeTemplates from './barcodeTemplates';
import inventorySessions from './inventorySessions';
import bulkInventorySessions from './bulkInventorySessions'
import batchInventoryResults from './batchInventoryResults'
import products from './products';
import prints from './prints';
import settings from './settings';
import categories from './categories';
import orderSessions from './orderSessions';
import pickOrder from './pickOrder';

export default combineReducers({
  accounts,
  barcodes,
  barcodeTemplates,
  inventorySessions,
  bulkInventorySessions,
  batchInventoryResults,
  products,
  prints,
  settings,
  categories,
  orderSessions,
  pickOrder,
});
