import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

const LineItem = ({ lineItem, toggleChecked, checkedItems, unpickableItems, switchUnpickable, hidePickedItems }) => {
  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  const toggle = () => {
    if(!unpickableItems.includes(lineItem.lineItemId)) {
      toggleChecked(lineItem.lineItemId);
    }
  }

  const removeUnpickable = () => {
    switchUnpickable(lineItem.lineItemId);
  }

  if(hidePickedItems && checkedItems.includes(lineItem.lineItemId) && !unpickableItems.includes(lineItem.lineItemId)) {
    return (
      <></>
    )
  }

  return (
    <div className={`list-element list-element--normal list-element--has-hover ${unpickableItems.includes(lineItem.lineItemId) ? 'main-overlay__warning' : ''}`} onClick={() => toggle()}>
      <div className="list-element__toggle">
        { !unpickableItems.includes(lineItem.lineItemId) &&
          <>
            <input
              type="checkbox"
              value="on"
              checked={checkedItems.includes(lineItem.lineItemId) ? 'checked' : ''}
              id={lineItem.lineItemId}
              tabindex="0"
              className="list-element__toggle-checkbox"
              onClick={() => toggle()}
            >
            </input>

            <label for={lineItem.lineItemId} className="list-element__toggle-label">
            </label>
          </>
        }
      </div>
      <div className="list-element__image">
        { lineItem.smallThumbnailUrl && <img src={lineItem.smallThumbnailUrl} /> }
        { !lineItem.smallThumbnailUrl && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 123"><g stroke="#c2cbd3" stroke-miterlimit="10"><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M77.46 22.7H48.28l2.02-7.04h25.15zm0 43.26H48.28l2.02 7.05h25.15z"/><path fill="#edeff2" d="M52.63 7.59h20.48v74.29H52.63z"/><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M91.903 39.907v8.59h-3.82v-8.59z"/><ellipse cx="63.08" cy="44.21" fill="#fff" stroke-linecap="round" stroke-linejoin="round" rx="25.76" ry="25.53"/><g fill="#fff" stroke-linecap="round" stroke-linejoin="round"><ellipse cx="63.08" cy="44.13" rx="21.8" ry="21.6"/><path d="M51.34 33.05l11.91 11.24 7.47-3.93"/><ellipse cx="63.08" cy="44.13" rx="1.78" ry="1.76"/></g></g></svg> }
      </div>

      <div className="list-element__content">
        <div className="list-element__info">
          <div className="list-element__header">
            <div className="list-element__main-info">
              <div className="list-element__title"><span>{lineItem.name}</span></div>
              <div className="list-element__description"><span className="muted">{lineItem.sku}</span></div>
            </div>
          </div>
          <div className="list-element__data-row">

          </div>
          <div className="list-element__data-row">
            <div className="flex-container">
              <div className="flex-2">
                <ul>
                  { lineItem.selectedOptions && lineItem.selectedOptions.map(option =>
                      <li>
                        <strong>{ option.name }</strong>: { option.value }
                      </li>
                    )
                  }
                  { !lineItem.selectedOptions &&
                    <div class="text-default warning">
                      Base Product
                    </div>
                  }
                </ul>
              </div>
              <div className="flex-1">
                <h4>Pick Quantity</h4>
                <h2>{ lineItem.quantity }</h2>
                { unpickableItems.includes(lineItem.lineItemId) && 
                  <button className="btn btn-muted-accent btn-medium" onClick={() => removeUnpickable()}>Clear Unpickable Status</button>
                }
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>

  )

}

const mapStateToProps = state => ({
  pickOrder: state.pickOrder,
  checkedItems: state.pickOrder.checkedItems,
  unpickableItems: state.pickOrder.unpickableItems,
});

const mapDispatchToProps = (dispatch) => ({
  // searchOrder: (payload, orderId) => dispatch(readOrder(payload, orderId)),
  // pushCheckedList: (skuList) => dispatch(setCheckedList(skuList))
});

export default connect(mapStateToProps, mapDispatchToProps)(LineItem);
