import {
  SEARCH_PRODUCT_REQUESTED,
  SEARCH_PRODUCT_SUCCESS,
  SEARCH_PRODUCT_FAILURE,
  UPDATE_SELECTED_ITEMS,
  SEARCH_PRODUCT_BARCODE_VALUE_REQUESTED,
  SEARCH_PRODUCT_BARCODE_VALUE_SUCCESS,
  SEARCH_PRODUCT_BARCODE_VALUE_FAILURE,
  DOWNLOAD_PREVIEW_REQUESTED,
  DOWNLOAD_PREVIEW_SUCCESS,
  DOWNLOAD_PREVIEW_FAILURE,
  FIND_BARCODE_IMAGE_REQUESTED,
  FIND_BARCODE_IMAGE_SUCCESS
} from '../types';

const INITIAL_STATE = {
  searchedProducts: null,
  searchedBarcodes: [],
  searchingBarcodes: [],
  missingBarcodes: [],
  activeBarcode: null,
  sessionProductActions: [],
  batchProductActions: [],
  isFetching: false,
  isUpdating: false,
  status: 'waiting',
  printable: 'false',
  selectedProductList: { variations: {}, products: {} },
  downloadUrl: null,
  errors: null
};

const products = (state = INITIAL_STATE, action) => {
  const newState = { ...state };

  switch(action.type) {
    case SEARCH_PRODUCT_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        status: 'requesting',
        searchedProducts: null,
        searchedBarcodes: [],
        searchingBarcodes: [],
        missingBarcodes: []
      });

    case SEARCH_PRODUCT_SUCCESS:
      return Object.assign({}, state, {
        searchedProducts: action.payload.products,
        isFetching: false,
        status: 'success',
        errors: null
      });

    case SEARCH_PRODUCT_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        errors: 'error here'
      });

    case UPDATE_SELECTED_ITEMS:
      return Object.assign({}, state, {
        selectedProductList: action.payload
      });

    case SEARCH_PRODUCT_BARCODE_VALUE_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        status: 'requesting',
        searchedProducts: null
      });

    case SEARCH_PRODUCT_BARCODE_VALUE_SUCCESS:
      return Object.assign({}, state, {
        activeBarcode: action.payload.item_data,
        isFetching: false,
        status: 'success',
        errors: null
      });

    case SEARCH_PRODUCT_BARCODE_VALUE_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        errors: 'error here'
      });

    case DOWNLOAD_PREVIEW_REQUESTED:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'requesting_preview',
        downloadUrl: null,
      });

    case DOWNLOAD_PREVIEW_SUCCESS:
      return Object.assign({}, state, {
        status: 'success',
        downloadUrl: action.payload.preview_url,
        isFetching: false,
        errors: null
      });

    case DOWNLOAD_PREVIEW_FAILURE:
      return Object.assign({}, null, {
        isFetching: false,
        status: 'waiting',
        errors: 'error here'
      });

    case FIND_BARCODE_IMAGE_REQUESTED:
      return Object.assign({}, state, {
        searchingBarcodes: [...state.searchingBarcodes, action.payload]
      });

    case FIND_BARCODE_IMAGE_SUCCESS:
      return Object.assign({}, state, {
        searchingBarcodes: state.searchingBarcodes.filter(item => item !== action.payload.found_barcode_value && item !== action.payload.missing_barcode_value),
        searchedBarcodes: [...state.searchedBarcodes, action.payload.found_barcode_value].filter(item => item),
        missingBarcodes: [...state.missingBarcodes, action.payload.missing_barcode_value].filter(item => item)
      });

    default:
      return state;
  }
}

export default products;
