import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import LoadingSpinner from '../partials/LoadingSpinner';

import { downloadOrderReceipt } from '../../redux/actions/orderSessions';
import { indexOrderStatuses, updateOrderStatus } from '../../redux/actions/pickOrder';

const SubmittedMessage = ({ payload, account, orderSession, resetSession, sessionPdf, downloadReceipt, downloadError, pickOrder, orderStatuses, orderFulfillmentMessage, loadFulfillmentStatuses, updateStatus, formatPriceValue }) => {
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [fulfillmentStatus, setFulfillmentStatus] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('');

  useEffect(() => {
    loadFulfillmentStatuses(payload);
  }, []);

  const requestReceipt = (payload, orderId) => {
    setSubmitted(true);
    setError(null);
    downloadReceipt(payload, orderId);
  }

  const setOrderStatus = (statusType) => {
    let status = fulfillmentStatus;

    if(statusType !== 'fulfillment') {
      status = paymentStatus;
    }

    updateStatus(payload, orderSession.orderId, status, statusType);
  }

  const switchFulfillmentStatus = (event) => {
    let status = pickOrder.fulfillmentStatuses.find(status => status.status_id === event.target.value);
    setFulfillmentStatus(status.status_id);
  }

  const switchPaymentStatus = (event) => {
    let status = pickOrder.paymentStatuses.find(status => status.status_id === event.target.value);
    setPaymentStatus(status.status_id);
  }

  useEffect(() => {
    setSubmitted(false);

    if(sessionPdf) {
      let win = window.open(sessionPdf, '_blank');
      win.focus();
    }
  }, [sessionPdf]);

  useEffect(() => {
    setSubmitted(false);
    setError(downloadError);
  }, [downloadError]);

  return (
    <>
      <div className={`notify notify--show fit-height ${ orderSession.orderId ? 'notify--success' : 'notify--info' }`}>
        <div className="notify__content">
          <div className="notify__title">
          </div>
          <div className="notify__text"></div>
          <div className="notify__action">
            { orderSession.orderId &&
              <>
                <div className="flex-container">
                  <div className="flex-1">
                    <div class="a-card-stack">
                      <div class="a-card a-card--normal color-black">
                        <div class="a-card__paddings">
                      
                          <h3>Order Actions</h3>
                          <h4>Update Order Statuses</h4>
                          { pickOrder.fulfillmentStatuses.length > 0 && !pickOrder.orderStatusesFetching &&
                            <div className="top-15">
                              <div className="fieldset fieldset--select">
                                <div className="field field--medium field--filled">
                                  <label className="field__label">Fulfillment Status</label>
                                  <select value={fulfillmentStatus} id="statuses" onChange={(e) => switchFulfillmentStatus(e)} className="field__select">
                                    <option hidden disabled selected value></option>
                                    { pickOrder.fulfillmentStatuses.map(status =>
                                      <option value={status.status_id}>{ status.name || status.status_id }</option>
                                    )}
                                  </select>
                                  <div className="field__placeholder">Fulfillment Status</div>
                                </div>
                              </div>
                              <br/>
                              <button className={`btn btn-primary-accent btn-medium ${pickOrder.status === "requesting" ? 'btn-loading': ''}`} disabled={pickOrder.status === "requesting"} onClick={() => setOrderStatus('fulfillment')}>
                                Update Fulfillment Status
                              </button>
                            </div>
                          }

                          { pickOrder.paymentStatuses.length > 0 && !pickOrder.orderStatusesFetching &&
                            <div className="top-15">
                              <div className="fieldset fieldset--select">
                                <div className="field field--medium field--filled">
                                  <label className="field__label">Payment Status</label>
                                  <select value={paymentStatus} id="statuses" onChange={(e) => switchPaymentStatus(e)} className="field__select">
                                    <option hidden disabled selected value></option>
                                    { pickOrder.paymentStatuses.map(status =>
                                      <option value={status.status_id}>{ status.name || status.status_id }</option>
                                    )}
                                  </select>
                                  <div className="field__placeholder">Payment Status</div>
                                </div>
                              </div>
                              <br/>
                              <button className={`btn btn-primary-accent btn-medium ${pickOrder.status === "requesting" ? 'btn-loading': ''}`} disabled={pickOrder.status === "requesting"} onClick={() => setOrderStatus('payment')}>
                                Update Payment Status
                              </button>
                            </div>
                          }

                          { (pickOrder.fulfillmentStatuses.length === 0 || pickOrder.paymentStatuses.length === 0) && pickOrder.orderStatusesFetching &&
                            <LoadingSpinner
                              statusText='Loading order statuses'
                            />
                          }
                        </div>
                      </div>

                      <div class="a-card a-card--normal color-black">
                        <div class="a-card__paddings">
                          <h4>Next Steps</h4>
                          <button className={`btn btn-fullwidth btn-default btn--ico-after btn-medium ${submitted ? 'btn-loading': ''}`} disabled={submitted} onClick={() => requestReceipt(payload, orderSession.orderId)}>
                            <span className="gwt-InlineLabel">Download Order Details {`${orderSession.orderId}`}</span>
                            <span className="svg-icon">
                              <svg width="18" height="18" viewBox="0 0 32 32" version="1.1"><path d="M30 14.25h-3.25v-8.25c0-0 0-0.001 0-0.001 0-0.206-0.084-0.393-0.219-0.529v0l-4-4c-0.136-0.136-0.324-0.22-0.531-0.22-0 0-0 0-0 0h-17c-0.414 0-0.75 0.336-0.75 0.75v0 12.25h-2.25c-0.414 0-0.75 0.336-0.75 0.75v0 9c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-8.25h26.5v8.25c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-9c-0-0.414-0.336-0.75-0.75-0.75v0zM5.75 2.75h15.939l3.561 3.561v7.939h-19.5zM26 21.25h-20c-0.414 0-0.75 0.336-0.75 0.75v0 8c0 0.414 0.336 0.75 0.75 0.75h20c0.414-0 0.75-0.336 0.75-0.75v0-8c-0-0.414-0.336-0.75-0.75-0.75v0zM25.25 29.25h-18.5v-6.5h18.5zM26.279 19.199c0.178-0.099 0.322-0.242 0.417-0.415l0.003-0.005c0.027-0.067 0.043-0.145 0.043-0.227 0-0.018-0.001-0.037-0.002-0.054l0 0.002c-0.004-0.21-0.087-0.399-0.221-0.54l0 0c-0.142-0.122-0.327-0.196-0.53-0.196s-0.389 0.074-0.531 0.196l0.001-0.001c-0.122 0.145-0.197 0.334-0.199 0.54v0c-0.001 0.011-0.001 0.024-0.001 0.037 0 0.189 0.077 0.359 0.2 0.483v0c0.132 0.136 0.317 0.221 0.521 0.221 0.007 0 0.014-0 0.021-0l-0.001 0c0.016 0.001 0.034 0.002 0.052 0.002 0.082 0 0.16-0.016 0.231-0.045l-0.004 0.001z"></path></svg>
                            </span>
                          </button>
                          { error &&
                            <div className="text-default error">
                              { error }
                            </div>
                          }
                          <Link to={`/pick/${orderSession.orderId}`}>
                            <button className="btn btn-fullwidth btn-default btn--ico-after btn-medium">Pick and Verify Order</button>
                          </Link>
                        </div>
                      </div>

                    </div>
                  </div>


                  <div className="flex-1 left-15">

                    <div class="a-card-stack">
                      <div class="a-card a-card--normal color-black">
                        <div class="a-card__paddings">
                          <h4>Totals</h4>
                          <table>
                            <tr>
                              <td>Items:</td>
                              <td className="align-right">{formatPriceValue(orderSession.order.subtotal)}</td>
                            </tr>
                            <tr>
                              <td>Tax:</td>
                              <td className="align-right">{formatPriceValue(orderSession.order.tax)}</td>
                            </tr>
                            <tr>
                              <td><strong>Total:</strong></td>
                              <td className="align-right">{formatPriceValue(orderSession.order.total)}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    
                      <div class="a-card a-card--normal color-black">
                        <div class="a-card__paddings">
                          <h4>Other Actions</h4>
                          <a href={`https://my.ecwid.com/store/${account.store_id}#order:id=${orderSession.orderId}`} target="_blank">
                            <button className="btn btn-fullwidth btn-default btn--ico-after btn-medium">
                              <span className="gwt-InlineLabel">View Order {`${orderSession.orderId}`}</span>
                              <span className="svg-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M17.0001 2c0-.5523-.4477-1-1-1h-5c-.5523 0-1 .4477-1 1s.4477 1 1 1h2.5858L7.0002 9.5857c-.3906.3906-.3906 1.0237 0 1.4143.3905.3905 1.0237.3905 1.4142 0L15 4.4142V7c0 .5523.4477 1 1 1s1-.4477 1-1V2z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M1 3c0-1.1046.8954-2 2-2h3c.5523 0 1 .4477 1 1s-.4477 1-1 1H3v12h12v-3c0-.5523.4477-1 1-1s1 .4477 1 1v3c0 1.1046-.8954 2-2 2H3c-1.1046 0-2-.8954-2-2V3z"/></svg>
                              </span>
                            </button>
                          </a>

                          <button className="btn btn-fullwidth btn-default btn--ico-after btn-medium top-15" onClick={() => resetSession()}>
                            <span className="gwt-InlineLabel">New Order</span>
                            <span className="svg-icon">
                              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M9.9968 8.0396h6.0302c.5374 0 .973.4356.973.973s-.4356.973-.973.973H9.9968V16c0 .5523-.4477 1-1 1h-.023c-.5523 0-1-.4477-1-1V9.9884H1.9744C1.4363 9.9884 1 9.5521 1 9.014s.4363-.9744.9744-.9744h5.9994V2c0-.5523.4477-1 1-1h.0244c.5523 0 1 .4477 1 1v6.0396h-.0014z"/></svg>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </>
            }
            { !orderSession.orderId &&
              <button className="btn btn-fullwidth btn-default btn--ico-after btn-medium btn-loading" disabled>
                <span className="gwt-InlineLabel">View Order</span>
              </button>
            }
          </div>
          <div className="notify__close">
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  account: state.accounts.account,
  orderSession: state.orderSessions,
  sessionPdf: state.orderSessions.invoicePdf,
  downloadError: state.orderSessions.invoiceError,
  pickOrder: state.pickOrder,
  orderStatuses: state.pickOrder.orderStatuses,
  orderFulfillmentMessage: state.pickOrder.orderFulfillmentMessage,
});

const mapDispatchToProps = (dispatch) => ({
  downloadReceipt: (payload, orderId) => dispatch(downloadOrderReceipt(payload, orderId)),
  loadFulfillmentStatuses: (payload) => dispatch(indexOrderStatuses(payload)),
  updateStatus: (payload, orderId, status, statusType) => dispatch(updateOrderStatus(payload, orderId, status, statusType)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedMessage);
