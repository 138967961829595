import React from 'react';

const TemplateLineOrderContainer = ({
  barcode,
  storeLogo,
  barcodeSymbol,
  productName,
  optionOne,
  optionTwo,
  optionThree,
  price,
  brand,
  arbitraryText,
  barcodeSymbolPriority,
  productNamePriority,
  optionOnePriority,
  optionTwoPriority,
  optionThreePriority,
  pricePriority,
  brandPriority,
  arbitraryTextPriority,
  increasePriority,
  decreasePriority
}) => {

  let lineElements = [];

  if (barcodeSymbol) {
    lineElements.push({
      element: 'Barcode Symbol',
      priority: barcodeSymbolPriority
    })
  }

  if (productName) {
    lineElements.push({
      element: 'Product Name',
      priority: productNamePriority
    })
  }

  if (optionOne) {
    lineElements.push({
      element: 'Variation Option 1',
      priority: optionOnePriority
    })
  }

  if (optionTwo) {
    lineElements.push({
      element: 'Variation Option 2',
      priority: optionTwoPriority
    })
  }

  if (optionThree) {
    lineElements.push({
      element: 'Variation Option 3',
      priority: optionThreePriority
    })
  }

  if (price) {
    lineElements.push({
      element: 'Price',
      priority: pricePriority
    })
  }

  if (brand) {
    lineElements.push({
      element: 'Brand',
      priority: brandPriority
    })
  }

  if (arbitraryText) {
    lineElements.push({
      element: 'Arbitrary Text',
      priority: arbitraryTextPriority
    })
  }

  lineElements.sort((a,b) => a.priority - b.priority)

  if(barcode) {
    return (
      <div className="a-card a-card--compact a-card--info a-card--info-blue">
        <div className="a-card__paddings">
          <div className="iconable-block iconable-block--hide-in-mobile iconable-block--info">
            <div className="iconable-block__infographics"></div>
            <div className="iconable-block__content">
              <div className="cta-block">
                <div className="cta-block__central">
                  <div className="cta-block__title">Barcode Line</div>
                  <div className="cta-block__content">Only the barcode appears on barcode lines. Text elements on this line will not appear.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(storeLogo) {
    return (
      <div className="a-card a-card--compact a-card--info a-card--info-blue">
        <div className="a-card__paddings">
          <div className="iconable-block iconable-block--hide-in-mobile iconable-block--info">
            <div className="iconable-block__infographics"></div>
            <div className="iconable-block__content">
              <div className="cta-block">
                <div className="cta-block__central">
                  <div className="cta-block__title">Store Logo Line</div>
                  <div className="cta-block__content">Only the logo appears on store logo lines. Text elements on this line will not appear.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  if(lineElements.length === 0) {
    return (
      <div className="a-card a-card--compact a-card--warning">
        <div className="a-card__paddings">
          <div className="iconable-block iconable-block--hide-in-mobile iconable-block--warning">
            <div className="iconable-block__infographics"></div>
            <div className="iconable-block__content">
              <div className="cta-block">
                <div className="cta-block__central">
                  <div className="cta-block__title">Empty Line</div>
                  <div className="cta-block__content">No text elements selected for this line. Enable an element and select this line number from its dropdown in the "Label Parts" section to add to this line.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div>
      {lineElements.map(element => (
        <div id={`line-element-${element.priority}`} className="flex-container template-line-row">
          <div className="flex-4 text-center">
            {element.element}
          </div>
          <div className="flex-1">
            <div className="flex-container">
              <button className="btn btn-default" onClick={() => increasePriority(element, lineElements)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.0015 4.284V15c0 .5523-.4477 1-1 1s-1-.4477-1-1V4.284l-4.087 3.7725c-.4058.3746-1.0384.3493-1.413-.0565-.3746-.4058-.3493-1.0385.0565-1.413l5.7649-5.3215a1 1 0 0 1 1.3569.0003l5.7625 5.324c.4041.3733.4299 1.0032.0577 1.4083-.3735.4066-1.0062.4326-1.4119.0582L10.0015 4.284z"/></svg>
              </button>
              <button className="btn btn-default" onClick={() => decreasePriority(element, lineElements)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill-rule="evenodd" clip-rule="evenodd" d="M8 12.716V2c0-.5523.4478-1 1-1 .5524 0 1.0001.4477 1.0001 1v10.716l4.0869-3.7725c.4058-.3746 1.0385-.3493 1.4131.0565.3746.4058.3493 1.0385-.0566 1.4131l-5.7652 5.3217a1 1 0 0 1-1.3565 0l-5.7652-5.3217C2.1508 10.0385 2.1255 9.4058 2.5 9c.3746-.4058 1.0072-.4311 1.413-.0565l4.087 3.7725z"/></svg>
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default TemplateLineOrderContainer;