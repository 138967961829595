import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import LiveSession from "./LiveSession";
import PageHeader from "../partials/PageHeader";

import { addInventory, subtractInventory, setInventory, scanInventory, addPlaceholderAction, clearActions, dismissInventoryScan } from '../../redux/actions/inventorySessions';

const LiveModify = ({ payload, accountUnits, dismissScan, postAddInventory, inventorySession, postSubtractInventory, postSetInventory, postScanInventory, addAction, settings, clearSession, downloadSessionData }) => {
  const [submitted, setSubmitted] = useState(false);
  const [actionType, setActionType] = useState('scan');
  const [actionQuantity, setActionQuantity] = useState(null);
  const [scanTerm, setScanTerm] = useState('');

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(scanTerm !== ''){
        addAction(scanTerm, actionType);
        submitAction();
        setScanTerm('');
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [scanTerm, 500]);

  const downloadActions = () => {
    let rows = [['Name', 'Options', 'SKU', 'Price', 'Action Type', 'Action Quantity', 'Quantity', 'Updated Quantity']];

    inventorySession.events.toReversed().map(event =>
      appendAction(rows, event)
    )

    let csvContent = "data:text/csv;charset=utf-8," 
        + rows.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  const appendAction = (rowsArray, event) => {
    let options = [];

    event.options?.map(option => 
      options.push(`${option.name} -- ${option.value}`)
    )

    let row = [event.name, options.join(' | '), event.sku, event.price, event.actionType, event.actionQuantity, event.quantity, event.updatedQuantity]

    rowsArray.push(row);
  }

  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  const updateScan = (event) => {
    let value = event.target.value;
    setScanTerm(value);
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const validateQuantity = (event) => {
    let value = event.target.value;
    setActionQuantity(value.replace(/\D/g,''));
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const submitAction = () => {
    if(!actionQuantity && actionType !== 'scan') { dismissScan(inventorySession.events.length) }

    if (actionQuantity && actionType === 'add') { postAddInventory(payload, scanTerm, inventorySession.events.length, actionQuantity) }
    if (actionQuantity && actionType === 'subtract') { postSubtractInventory(payload, scanTerm, inventorySession.events.length, actionQuantity) }
    if (actionQuantity && actionType === 'set') { postSetInventory(payload, scanTerm, inventorySession.events.length, actionQuantity) }
    if (actionType === 'scan') { postScanInventory(payload, scanTerm, inventorySession.events.length) }
  }

  const actionChange = (e) => {
    setActionType(e.target.value);
    let actionQuantityField = document.querySelector('.actionQuantityField');
    actionQuantityField.disabled = false;

    if(e.target.value === 'scan') {
      actionQuantityField.disabled = true;
    }

    flashScanBar();
  }

  useEffect(() => {
    flashScanBar();
  }, []);

  const formatPriceValue = (value) => {
    return accountUnits.currencyPrefix + ' ' + value + ' ' + accountUnits.currencySuffix;
  }

  const flashScanBar = () => {
    let scanBarElement = document.querySelector("#scan-bar");

    setTimeout(() => {
      scanBarElement?.classList?.add("blink-action");
    }, 500);

    setTimeout(() => {
      scanBarElement?.classList?.remove("blink-action");
    }, 1500);
  }

  return(
    <>
      <PageHeader stripeBackground='#c66628' helpLink="ecwid/live-inventory-modification-2/" />
      <div className="settings-page cf">
        <div className="settings-page__header">
          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Live Inventory</div>
              </div>
              <div className="named-area__description">
                <p>Select an action to perform from the large radio buttons. 
                Select "Add Inventory" to add the "Change by amount" to the inventory of a scanned product or variation.
                Select "Subtract Inventory" to subtract the "Change by amount" from the inventory of a scanned product or variation.
                Select "Set Inventory" to set the inventory of a scanned product or variation to exactly the "Change by amount".
                Select "View Inventory" to see the inventory of a scanned product or variation.</p>
                <p>Select the "Scan Barcode" bar and scan a generated Code 128 SKU (the generated barcodes from the "Labels + Print" section of this app, or similar) with a barcode scanner. This scan should also work with UPCs, EANs or ISBN values if they have been set in products or variations.</p>
              </div>
            </div>
            <div className="named-area__body">


              <div className="a-card-stack">
                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">

                    <div className="graphic-radio-group">
                      <div className="graphic-radio-group__items">
                        <div className="graphic-radio graphic-radio--small">
                          <label className="graphic-radio__label">
                            <input 
                              type="radio" 
                              name="action-type" 
                              value="add" 
                              checked={actionType === "add"}
                              className="graphic-radio__input" 
                              onChange={ (e) => actionChange(e) }
                            />
                            <div className="graphic-radio__icon">
                              <span className="svg-icon">
                                <svg width="18" height="18" viewBox="0 0 18 18"><path d="M9.9968 8.0396h6.0302c.5374 0 .973.4356.973.973s-.4356.973-.973.973H9.9968V16c0 .5523-.4477 1-1 1h-.023c-.5523 0-1-.4477-1-1V9.9884H1.9744C1.4363 9.9884 1 9.5521 1 9.014s.4363-.9744.9744-.9744h5.9994V2c0-.5523.4477-1 1-1h.0244c.5523 0 1 .4477 1 1v6.0396h-.0014z"/></svg>
                              </span>
                              <div className="graphic-radio__bg"></div>
                            </div>
                            <span className="graphic-radio__title">Add Inventory</span>
                          </label>
                        </div>
                        <div className="graphic-radio graphic-radio--small">
                          <label className="graphic-radio__label">
                            <input 
                              type="radio" 
                              name="action-type" 
                              value="subtract" 
                              checked={actionType === "subtract"}
                              className="graphic-radio__input" 
                              onChange={ (e) => actionChange(e) }
                            />
                            <div className="graphic-radio__icon">
                              <span className="svg-icon">
                                <svg width="10" height="10" viewBox="0 -4 10 10"><path d="M0 1c0-.5523.4477-1 1-1h8c.5523 0 1 .4477 1 1s-.4477 1-1 1H1c-.5523 0-1-.4477-1-1Z"/></svg>
                              </span>
                              <div className="graphic-radio__bg"></div>
                            </div>
                            <span className="graphic-radio__title">Subtract Inventory</span>
                          </label>
                        </div>
                        <div className="graphic-radio graphic-radio--small">
                          <label className="graphic-radio__label">
                            <input 
                              type="radio" 
                              name="action-type" 
                              value="set" 
                              checked={actionType === "set"}
                              className="graphic-radio__input" 
                              onChange={ (e) => actionChange(e) }
                            />
                            <div className="graphic-radio__icon">
                              <span class="svg-icon">
                                <svg width="18" height="18" viewBox="0 0 18 18" focusable="false"><path d="M5.0355 15.7929 2.207 12.9645 1.5 16.5l3.5355-.7071z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.8136.9437c.3906-.3905 1.0237-.3905 1.4143 0l2.8284 2.8284c.3905.3905.3905 1.0237 0 1.4142L5.5285 16.7141.2252 17.7748l1.0606-5.3033L12.8136.9437zm.7072 2.1213-9.8995 9.8995 1.4142 1.4142 9.8995-9.8995-1.4142-1.4142zM3.0071 15.1788l-.186-.186-.0464.2325.2324-.0465z"/></svg>
                              </span>
                              <div className="graphic-radio__bg"></div>
                            </div>
                            <span className="graphic-radio__title">Set Inventory</span>
                          </label>
                        </div>
                        <div className="graphic-radio graphic-radio--small">
                          <label className="graphic-radio__label">
                            <input 
                              type="radio" 
                              name="action-type" 
                              value="scan" 
                              checked={actionType === "scan"}
                              className="graphic-radio__input" 
                              onChange={ (e) => actionChange(e) }
                            />
                            <div className="graphic-radio__icon">
                              <span className="svg-icon">
                                <svg width="18" height="18" viewBox="0 0 18 18"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 7c0 3.866-3.134 7-7 7-1.5724 0-3.0236-.5184-4.1922-1.3936l-4.1007 4.1007c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3906-.3905-1.0237 0-1.4143l4.1007-4.1007C4.5184 10.0236 4 8.5723 4 7c0-3.866 3.134-7 7-7s7 3.134 7 7zm-2 0c0 2.7614-2.2386 5-5 5S6 9.7614 6 7s2.2386-5 5-5 5 2.2386 5 5z"/></svg>                            </span>
                              <div className="graphic-radio__bg"></div>
                            </div>
                            <span className="graphic-radio__title">View Inventory</span>
                          </label>
                        </div>
                      </div>
                    </div>

                    <div className="iconable-block iconable-block--hide-in-mobile">
                      <div className="iconable-block__infographics">
                        <span className="iconable-block__icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill="#189dee" d="M15 2H3v14h12ZM3 0a2 2 0 0 0-2 2v14c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2Z" fill-rule="evenodd"/><path fill="#189dee" d="M5 5.5a1 1 0 0 1 1-1h6c1.33 0 1.33 2 0 2H6a1 1 0 0 1-1-1ZM6 8h6a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2ZM5 12.5a1 1 0 0 1 1-1h6c1.33 0 1.33 2 0 2H6a1 1 0 0 1-1-1z" /></svg>
                        </span>
                      </div>
                      <div className="iconable-block__content">
                        <div className="status-block">
                          <div className="status-block__central">
                            <div className="status-block__header">
                              <span className="status-block__title">Action Options</span>
                              <span className="status-block__edit">Edit</span>
                            </div>
                            <div className="status-block__content">
                              <div className="fieldset">
                                <div className="field field--medium">
                                  <span className="fieldset__svg-icon"></span>
                                  <label className="field__label">Change by amount</label>
                                  
                                  { !submitted && <input type="text" value={actionQuantity} onFocus={focus} onBlur={blur} onChange={validateQuantity} className="field__input actionQuantityField" tabindex="4" maxlength="64" /> }
                                  { submitted && <input type="text" value={actionQuantity} onFocus={focus} onBlur={blur} onChange={validateQuantity} className="field__input actionQuantityField" tabindex="4" maxlength="64" disabled /> }
                                  
                                  <div className="field__placeholder">Change by amount</div>
                                  <span className="field-state--success"></span>
                                  <span className="field-state--close"></span>
                                </div>
                                <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">
                    <div className="iconable-block iconable-block--hide-in-mobile">
                      <div className="iconable-block__infographics">
                        <span className="iconable-block__icon">
                          <svg width="1.25rem" width="18" height="18" fill="#189dee" viewBox="0 0 32 32" id="svg5" version="1.1">
                            <defs id="defs2"/>
                            <g id="layer1" transform="translate(-108,-100)">
                              <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            </g>
                          </svg>
                        </span>
                      </div>
                      <div className="iconable-block__content">
                        <div className="status-block">
                          <div className="status-block__central">
                            <div className="status-block__header">
                              <span className="status-block__title">Scan Barcode (SKU, UPC, EAN)</span>
                              <span className="status-block__edit">Edit</span>
                            </div>
                            <div className="status-block__content">
                              <div className="fieldset fieldset__max">
                                <div id="scan-bar" className="field field--large">
                                  <span className="fieldset__svg-icon"></span>
                                  <label className="field__label">Scan Value</label>
                                  
                                  { !submitted && <input type="text" value={scanTerm} onFocus={focus} onBlur={blur} onChange={updateScan} className="field__input" tabindex="4" /> }
                                  { submitted && <input type="text" value={scanTerm} onFocus={focus} onBlur={blur} onChange={updateScan} className="field__input" tabindex="4" disabled /> }
                                  
                                  <div className="field__placeholder">Click here to begin scanning</div>
                                  <span className="field-state--success"></span>
                                  <span className="field-state--close"></span>
                                </div>
                                <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

              </div>

            </div>
          </div>

          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Live Inventory Session</div>
              </div>
              <div className="named-area__description">
                <p>See the results of scan actions in this session section. You can clear the session, or download the actions taken by clicking the "Clear session" or "Download session information" buttons on the right.</p>
                <p>Individual actions results can be seen below. The results should have SKUs, item information as well as action types and results displayed.</p>
              </div>
            </div>
            <div className="named-area__body">
              <div className="a-card a-card--compact">
                <div className="a-card__paddings">
                  <div className="flex-container">
                    <div className="flex-1">
                      
                      { settings.display_totals && <div className="flex-table">
                        <div className="flex-table__head">
                          <div className="flex-table__col flex-table__col--align-left">Total Type</div>
                          <div className="flex-table__col flex-table__col--align-right">Price Totals</div>
                        </div>
                        <div className="flex-table__row">
                          <div className="flex-table__col flex-table__col--align-left">
                            Add Total:
                          </div>
                          <div className="flex-table__col flex-table__col--align-right">
                            { formatPriceValue(inventorySession.addTotal) }
                          </div>
                        </div>
                       
                        <div className="flex-table__row">
                          <div className="flex-table__col flex-table__col--align-left">
                            Subtract Total:
                          </div>
                          <div className="flex-table__col flex-table__col--align-right">
                            { formatPriceValue(inventorySession.subtractTotal) }
                          </div>
                        </div>

                        <div className="flex-table__row">
                          <div className="flex-table__col flex-table__col--align-left">
                            Set Total:
                          </div>
                          <div className="flex-table__col flex-table__col--align-right">
                            { formatPriceValue(inventorySession.updateTotal) }
                          </div>
                        </div>

                        <div className="flex-table__row">
                          <div className="flex-table__col flex-table__col--align-left">
                            View Total:
                          </div>
                          <div className="flex-table__col flex-table__col--align-right">
                            { formatPriceValue(inventorySession.scanTotal) }
                          </div>
                        </div>
                      </div> }

                    </div>
                    <div className="flex-container flex-1 flex-end left-15">
                      { inventorySession.status === 'success' &&
                        <>
                          <button className="btn btn-default btn--destructive btn-medium flex-1 fit-content" onClick={() => clearSession()}>Clear session</button>
                          <button className="btn btn-primary btn-medium flex-1 fit-content left-15" onClick={() => downloadActions()}>Download session information</button>
                        </>
                      }
                      { inventorySession.status !== 'success' &&
                        <>
                          <button className="btn btn-default btn--destructive btn-medium flex-1 fit-content" onClick={() => clearSession()} disabled>Clear session</button>
                          <button className="btn btn-primary btn-medium flex-1 fit-content left-15" onClick={() => downloadActions()} disabled>Download session information</button>
                        </>
                      }
                    </div>
                  </div>
                </div>
              </div>

              <div class="filtered-list">
                <div className="filtered-list__items long-list">
                  { inventorySession.events.toReversed().map(event =>
                    <LiveSession
                      event={event}
                    />
                  )}
                </div>
              </div>

            </div>
          </div>

        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  accountUnits: state.accounts.account.store_details.formatsAndUnits,
  inventorySession: state.inventorySessions,
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  postAddInventory: (payload, barcode, actionId, actionQuantity) => dispatch(addInventory(payload, barcode, actionId, actionQuantity)),
  postSubtractInventory: (payload, barcode, actionId, actionQuantity) => dispatch(subtractInventory(payload, barcode, actionId, actionQuantity)),
  postSetInventory: (payload, barcode, actionId, actionQuantity) => dispatch(setInventory(payload, barcode, actionId, actionQuantity)),
  postScanInventory: (payload, barcode, actionId) => dispatch(scanInventory(payload, barcode, actionId)),
  dismissScan: (actionId) => dispatch(dismissInventoryScan(actionId)),
  addAction: (barcode, actionType) => dispatch(addPlaceholderAction(barcode, actionType)),
  clearSession: () => dispatch(clearActions()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LiveModify);