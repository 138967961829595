import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  ADD_INVENTORY_REQUESTED,
  ADD_INVENTORY_SUCCESS,
  ADD_INVENTORY_FAILURE,
  SUBTRACT_INVENTORY_REQUESTED,
  SUBTRACT_INVENTORY_SUCCESS,
  SUBTRACT_INVENTORY_FAILURE,
  SET_INVENTORY_REQUESTED,
  SET_INVENTORY_SUCCESS,
  SET_INVENTORY_FAILURE,
  SCAN_INVENTORY_REQUESTED,
  SCAN_INVENTORY_SUCCESS,
  SCAN_INVENTORY_FAILURE,
  ADD_PLACEHOLDER_ACTION,
  CLEAR_SESSION,
  CLEAR_ACTION,
} from '../types';

export const addInventoryRequested = () => {
  return {
    type: ADD_INVENTORY_REQUESTED
  }
};

export const addInventorySuccess = (payload) => {
  return {
    type: ADD_INVENTORY_SUCCESS,
    payload: payload
  }
};

export const addInventoryFailure = (error) => {
  return {
    type: ADD_INVENTORY_FAILURE,
    error: error
  }
};

export const subtractInventoryRequested = () => {
  return {
    type: SUBTRACT_INVENTORY_REQUESTED
  }
};

export const subtractInventorySuccess = (payload) => {
  return {
    type: SUBTRACT_INVENTORY_SUCCESS,
    payload: payload
  }
};

export const subtractInventoryFailure = (error) => {
  return {
    type: SUBTRACT_INVENTORY_FAILURE,
    error: error
  }
};

export const setInventoryRequested = () => {
  return {
    type: SET_INVENTORY_REQUESTED
  }
};

export const setInventorySuccess = (payload) => {
  return {
    type: SET_INVENTORY_SUCCESS,
    payload: payload
  }
};

export const setInventoryFailure = (error) => {
  console.log(error);
  return {
    type: SET_INVENTORY_FAILURE,
    error: error
  }
};


export const scanInventoryRequested = () => {
  return {
    type: SCAN_INVENTORY_REQUESTED
  }
};

export const scanInventorySuccess = (payload) => {
  return {
    type: SCAN_INVENTORY_SUCCESS,
    payload: payload
  }
};

export const scanInventoryFailure = (error) => {
  return {
    type: SCAN_INVENTORY_FAILURE,
    error: error
  }
};

export const addPlaceholder = (barcode, actionType) => {
  return {
    type: ADD_PLACEHOLDER_ACTION,
    barcode: barcode,
    actionType: actionType
  }
}

export const clearSessionActions = () => {
  return {
    type: CLEAR_SESSION
  }
}

export const clearAction = (actionId) => {
  return {
    type: CLEAR_ACTION,
    actionId: actionId
  }
}

export const addInventory = (ecwidPayload, barcode, actionId, actionQuantity) => {
  return (dispatch) => {
    dispatch(addInventoryRequested());

    let endPoint = Config.baseUrl + Config.sku + Config.addInventory;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.post(endPoint, { actionId: actionId, barcode: barcode, actionQuantity: actionQuantity } , { headers: headers })
      .then((response) => {
        dispatch(addInventorySuccess(response.data));
      })
      .catch((error) => {
        dispatch(addInventoryFailure(error.response.data));
      });
  }
}

export const subtractInventory = (ecwidPayload, barcode, actionId, actionQuantity) => {
  return (dispatch) => {
    dispatch(subtractInventoryRequested());

    let endPoint = Config.baseUrl + Config.sku + Config.subtractInventory;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.post(endPoint, { actionId: actionId, barcode: barcode, actionQuantity: actionQuantity } , { headers: headers })
      .then((response) => {
        dispatch(subtractInventorySuccess(response.data));
      })
      .catch((error) => {
        dispatch(subtractInventoryFailure(error.response.data));
      });
  }
}

export const setInventory = (ecwidPayload, barcode, actionId, actionQuantity) => {
  return (dispatch) => {
    dispatch(setInventoryRequested());

    let endPoint = Config.baseUrl + Config.sku + Config.setInventory;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.post(endPoint, { actionId: actionId, barcode: barcode, actionQuantity: actionQuantity } , { headers: headers })
      .then((response) => {
        dispatch(setInventorySuccess(response.data));
      })
      .catch((error) => {
        dispatch(setInventoryFailure(error.response.data));
      });
  }
}

export const scanInventory = (ecwidPayload, barcode, actionId) => {
  return (dispatch) => {
    dispatch(scanInventoryRequested());

    let endPoint = Config.baseUrl + Config.sku + Config.scanInventory;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.post(endPoint, { actionId: actionId, barcode: barcode } , { headers: headers })
      .then((response) => {
        dispatch(scanInventorySuccess(response.data));
      })
      .catch((error) => {
        dispatch(scanInventoryFailure(error.response.data));
      });
  }
}

export const addPlaceholderAction = (barcode, actionType) => {
  return (dispatch) => {
    dispatch(addPlaceholder(barcode, actionType));
  }
}

export const clearActions = () => {
  return (dispatch) => {
    dispatch(clearSessionActions());
  }
}

export const dismissInventoryScan = (actionId) => {
  return (dispatch) => {
    dispatch(clearAction(actionId));
  }
}

