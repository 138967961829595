import { Link } from "react-router-dom";

const FeatureCard = ({ title, description, buttonText, iconSvg, linkUrl, image, showImage, cardClasses, stripeBackground }) => {
  return(
    <Link to={linkUrl}>
      <div className={`a-card a-card--normal ${cardClasses}`}>
        <div className="a-card__paddings">
          <div className="feature-element has-picture">
            <div className="feature-element__core">
              <div className="feature-element__data" style={{ 'border-bottom': `2px solid ${stripeBackground}` }}>
                <div className="feature-element__title">{title}</div>
                <div className="feature-element__content">
                  <div className="feature-element__text">{description}</div>
                  <button type="button" className="btn btn-primary btn-medium feature-element__button">{buttonText}</button>
                </div>
              </div>
              <div className="feature-element__icon">{iconSvg}</div>
              { showImage && <div className="feature-element__picture right-15">{image}</div> }
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default FeatureCard;
