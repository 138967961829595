import {
  BATCH_INVENTORY_RESULTS_REQUESTED,
  BATCH_INVENTORY_RESULTS_SUCCESS,
  BATCH_INVENTORY_RESULTS_FAILURE,
  BATCH_INVENTORY_DOWNLOAD_REQUESTED,
  BATCH_INVENTORY_DOWNLOAD_SUCCESS,
  BATCH_INVENTORY_DOWNLOAD_FAILURE,
  BATCH_INVENTORY_REVERSE_REQUESTED,
  BATCH_INVENTORY_REVERSE_SUCCESS,
  BATCH_INVENTORY_REVERSE_FAILURE,
} from '../types';

const INITIAL_STATE = {
  isFetching: false,
  status: 'waiting',
  batchResults: [],
  downloadUrl: null,
  errors: null
};

const batchInventoryResults = (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case BATCH_INVENTORY_RESULTS_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        batchResults: [],
        status: 'requesting'
      });

    case BATCH_INVENTORY_RESULTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        batchResults: action.payload.batchResults,
        errors: null
      });

    case BATCH_INVENTORY_RESULTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'waiting',
        batchResults: [],
        errors: 'error here'
      });

    case BATCH_INVENTORY_RESULTS_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        downloadUrl: null,
        status: 'requesting'
      });

    case BATCH_INVENTORY_RESULTS_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success',
        downloadUrl: action.payload.url,
        errors: null
      });

    case BATCH_INVENTORY_RESULTS_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'waiting',
        downloadUrl: null,
        errors: 'error here'
      });

    case BATCH_INVENTORY_REVERSE_REQUESTED:
      return Object.assign({}, state, {
        isFetching: true,
        status: 'requesting'
      });
    
    case BATCH_INVENTORY_REVERSE_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'success'
      });
  
    case BATCH_INVENTORY_REVERSE_FAILURE:
      return Object.assign({}, state, {
        isFetching: false,
        status: 'error',
        errors: 'error here'
      });

    default:
      return state;
  }
}


export default batchInventoryResults;