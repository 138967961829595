import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { getBatchDetails } from '../../redux/actions/bulkInventorySessions';

const SubmittedMessage = ({ payload, bulkInventorySession, batchRefresh }) => {
  const [complete, setComplete] = useState(false);
  const [completeFailure, setCompleteFailure] = useState(false);
  const [notifyClass, setNotifyClass] = useState("notify--info");

  useEffect(() => {
    if(bulkInventorySession.batchId && bulkInventorySession.batchId !== 'multiple batches') {
      const timeoutId = setTimeout(() => {
        if(bulkInventorySession.batchId && bulkInventorySession?.batchDetails?.status !== 'COMPLETED'){
          batchRefresh(payload, bulkInventorySession.batchId);
        }

        if(bulkInventorySession.batchId && bulkInventorySession?.batchDetails?.status === 'COMPLETED'){
          setComplete(true);

          let failure = bulkInventorySession?.batchDetails?.responses.find(response => {
            return response.status === 'FAILED'
          });

          failure ? setNotifyClass("notify--error") : setNotifyClass("notify--success");
          setCompleteFailure(!!failure);
        }
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [bulkInventorySession]);

  if ( bulkInventorySession.batchId !== 'multiple batches' ) {
    return (
      <div className={`notify notify--show ${ notifyClass }`}>
        <div className="notify__content notify__content-darken">
          <div className="notify__title">
            Inventory Update Results - Skus Change Status
          </div>
          <div className="notify__text">
            <div class="flex-table">
              <div class="flex-table__head">
                <div class="flex-table__col flex-table__col--align-left">SKU</div>
                <div class="flex-table__col flex-table__col--align-right">Status</div>
              </div>
              { bulkInventorySession?.batchDetails?.responses.map(response => 
                  <div class="flex-table__row">
                    <div class="flex-table__col flex-table__col--align-left">{response.id}</div>
                    <div class="flex-table__col flex-table__col--align-right">{response.status}</div>
                  </div>
                )
              }
            </div>
          </div>
          <div className="notify__action">
            { complete && !completeFailure && 
              <Link to='../../update_results' relative="path">
                <button className="btn btn-fullwidth btn-default btn--ico-after btn-medium">
                  <span className="gwt-InlineLabel">View Results</span>
                  <span className="svg-icon">
                    <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M2 16c0-.5523.4477-1 1-1h12c.5523 0 1 .4477 1 1s-.4477 1-1 1H3c-.5523 0-1-.4477-1-1z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M9 1c.5523 0 1 .4477 1 1v7.5858l2.2929-2.293c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143l-4 4c-.3905.3905-1.0237.3905-1.4142 0l-4-4c-.3905-.3905-.3905-1.0237 0-1.4142.3905-.3905 1.0237-.3905 1.4142 0L8 9.5858V2c0-.5523.4477-1 1-1z"></path></svg>
                  </span>
                </button>
              </Link>
            }
            { !complete &&
              <button className="btn btn-fullwidth btn-default btn--ico-after btn-medium" disabled>
                <span className="gwt-InlineLabel">Updating SKUs</span>
              </button>
            }
          </div>
          <div className="notify__close">
          </div>
        </div>
      </div>
    )
  }

  return (
    <></>
  )
}

const mapStateToProps = state => ({
  bulkInventorySession: state.bulkInventorySessions,
});

const mapDispatchToProps = (dispatch) => ({
  batchRefresh: (payload, batchId) => dispatch(getBatchDetails(payload, batchId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubmittedMessage);
