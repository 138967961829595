import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import EmptyResults from './EmptyResults';
import PageHeader from "../partials/PageHeader";
import ConfirmReverse from './ConfirmReverse';

import { getBatchResults, reverseBatchAction } from '../../redux/actions/batchInventoryResults';

const BatchUpdateResults = ({ payload, batchInventoryResults, loadBatchResults, reverseAction }) => {
  const [reversalId, setReversalId] = useState(false);

  useEffect(() => {
    loadBatchResults(payload);
  }, []);

  const showConfirmation = (payload, ticketId) => {
    setReversalId(ticketId);
  }

  const downloadActions = (ticketId) => {
    let rows = [['Ticket ID', 'Date', 'Action Type', 'Comments', 'SKU', 'Quantity', 'Result']];

    appendAction(rows, ticketId);

    let csvContent = "data:text/csv;charset=utf-8," 
        + rows.map(e => e.join(",")).join("\n");

    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  const appendAction = (rowsArray, ticketId) => {
    let matchingTicket = batchInventoryResults.batchResults.find(result => result.ticketId === ticketId);

    matchingTicket.batch_body.map(resultRow => 
      rowsArray.push([`"${ticketId}"`, `"${matchingTicket.date_time}"`, `"${matchingTicket.action_type}"`, `"${matchingTicket.comments.replaceAll('"', '""').replaceAll('#', '')}"`, resultRow.id, resultRow.body.quantityDelta || resultRow.body.quantity, `"${resultRow.result_status}"`])
    )
  }

  const reverseAndRefresh = (payload, ticketId) => {
    reverseAction(payload, ticketId);
    loadBatchResults(payload);
  }

  if (batchInventoryResults.status === 'success' && (batchInventoryResults.batchResults.length > 0)) {
    return(
      <>
        <PageHeader stripeBackground='#124b5f' helpLink="ecwid/view-batch-changes-2/" />
        <div className="settings-page cf">
          <div className="settings-page__header">
            <div className="named-area">
              <div className="named-area__header">
                <div className="named-area__titles">
                  <div className="named-area__title">Batch Update Results</div>
                </div>
                <div className="named-area__description">
                  <p>The results for batch inventory adds, updates and reductions appear here. Changes made from live modifications are not saved here and would need to be downloaded from the "Live Modify" session.</p>
                  <p><strong>This data is only stored for 2 weeks.</strong> Click the "Download" button to download the session results.</p>
                </div>
              </div>
              <div className="named-area__body">
                <div class="a-card-stack">
                  <div className="a-card a-card--compact a-card-stack--stripe">
                    <div className="a-card__paddings">
                      <h2>Refresh Batch Results</h2>
                      <button className="btn btn-primary btn-medium" onClick={() => loadBatchResults(payload)}>Refresh Batches</button>
                    </div>
                  </div>
                  { batchInventoryResults.batchResults.map(result =>
                    <div className="a-card a-card--compact a-card-stack--stripe">
                      <div className="a-card__paddings">

                      <h3>Batch Details</h3>
                        <div className="flex-table">
                          <div className="flex-table__head">
                            <div className="flex-table__col flex-table__col--align-left">Batch Option</div>
                            <div className="flex-table__col flex-table__col--align-right">Value</div>
                          </div>
                          <div className="flex-table__row">
                            <div className="flex-table__col flex-table__col--align-left">
                              Ticket:
                            </div>
                            <div className="flex-table__col flex-table__col--align-right">
                              { result.ticketId }
                            </div>
                          </div>
                         
                          <div className="flex-table__row">
                            <div className="flex-table__col flex-table__col--align-left">
                              Date:
                            </div>
                            <div className="flex-table__col flex-table__col--align-right">
                              { result.date_time }
                            </div>
                          </div>

                          <div className="flex-table__row">
                            <div className="flex-table__col flex-table__col--align-left">
                              Comments:
                            </div>
                            <div className="flex-table__col flex-table__col--align-right">
                              { result.comments }
                            </div>
                          </div>

                          <div className="flex-table__row">
                            <div className="flex-table__col flex-table__col--align-left">
                              Action Type:
                            </div>
                            <div className="flex-table__col flex-table__col--align-right">
                              { result.action_type }
                            </div>
                          </div>
                        </div>

                        <br/>
                        <h3>Change Details</h3>

                        <div className="flex-table">
                          <div className="flex-table__head">
                            <div className="flex-table__col flex-table__col--align-left">Request</div>
                            <div className="flex-table__col flex-table__col--align-right">Result</div>
                          </div>
                          { result.batch_body.map(resultRow => 
                            <div className="flex-table__row">
                              <div className="flex-table__col flex-table__col--align-left">
                                Barcode Symbol: {resultRow.id}
                                <br/>
                                Quantity: {resultRow.body.quantityDelta || resultRow.body.quantity}
                              </div>
                              <div className="flex-table__col flex-table__col--align-right">
                                {resultRow.result_status}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>

                      <button className="btn btn-default btn-medium left-15 bottom-15" onClick={() => downloadActions(result.ticketId)}>Download</button>
                      { result.action_type === 'subtract' &&
                        <button
                          className="btn btn-default btn--destructive btn-medium left-15 bottom-15" 
                          onClick={() => showConfirmation(payload, result.ticketId)}
                        >
                            Reverse
                        </button>
                      }
                      { (reversalId === result.ticketId) && 
                        <ConfirmReverse
                          payload={payload}
                          ticketId={result.ticketId}
                          reverseAndRefresh={reverseAndRefresh}
                          setReversalId={setReversalId}
                        />
                      }
                    </div>
                    )
                  }

                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  if (batchInventoryResults.status === 'success' && (batchInventoryResults.batchResults.length === 0)) {
    return(
      <>
        <PageHeader stripeBackground='#124b5f' helpLink="ecwid/view-batch-changes-2/" />
        <EmptyResults 
          payload={payload}
        />
      </>
    )
  }

  return(
    <>
      <PageHeader stripeBackground='#124b5f' helpLink="ecwid/view-batch-changes-2/" />
      <LoadingPlaceholder title="Batch Update Results" />
    </>
  )
}

const mapStateToProps = state => ({
  batchInventoryResults: state.batchInventoryResults,
});

const mapDispatchToProps = (dispatch) => ({
  loadBatchResults: (payload) => dispatch(getBatchResults(payload)),
  reverseAction: (payload, ticketId) => dispatch(reverseBatchAction(payload, ticketId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BatchUpdateResults);
