import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import { updateSelectedItems, findBarcodeImage } from '../../redux/actions/products';
import { downloadBarcodeImage } from '../../redux/actions/barcodes';

const SkuLine = ({ payload, settings, sku, upc, brand, itemId, smallThumbnailUrl, options, name, selectedProductList, setSelectedProductList, products, downloadBarcode, loadBarcodeImage, downloadUrl, type, rowId, quantity, price, setDownloadSubmitted, filterInStock }) => {
  const [localDownloadSubmitted, setLocalDownloadSubmitted] = useState(false);

  let section = !!options ? selectedProductList.variations : selectedProductList.products;
  let inList = Object.keys(section).indexOf(String(itemId)) > -1;

  const switchSelection = () => {
    if (inList) {
      delete section[itemId];
    } else {
      let hasBarcode = false;

      if(!settings.manufacturer_mode) {
        hasBarcode = (products.searchedBarcodes.indexOf(sku) > -1)
      } else {
        hasBarcode = (products.searchedBarcodes.indexOf(upc) > -1)
      }

      section[itemId] = {
        sku: sku,
        upc: upc,
        brand: brand,
        smallThumbnailUrl: smallThumbnailUrl,
        options: options,
        name: name,
        hasBarcode: hasBarcode,
        quantity: quantity,
        price: price
      };
    }

    setSelectedProductList({ ...selectedProductList });
  };

  const downloadImage = (payload, itemId, sku, upc) => {
    setDownloadSubmitted(true);
    setLocalDownloadSubmitted(true);
    downloadBarcode(payload, itemId, sku, upc, settings.manufacturer_mode);
  }

  const initialBarcodeImage = () => {
    loadBarcodeImage(payload, itemId, sku, upc, settings.manufacturer_mode);
  };

  useEffect(() => {
    if(!settings.manufacturer_mode && sku && (products.searchedBarcodes.indexOf(sku) == -1) && (products.searchingBarcodes.indexOf(sku) == -1) && (products.missingBarcodes.indexOf(sku) == -1)) {
      initialBarcodeImage();
    } else if(settings.manufacturer_mode && upc && (products.searchedBarcodes.indexOf(upc) == -1) && (products.searchingBarcodes.indexOf(upc) == -1) && (products.missingBarcodes.indexOf(upc) == -1)) {
      initialBarcodeImage();
    }
  }, [initialBarcodeImage]);

  useEffect(() => {
    if(downloadUrl) { setLocalDownloadSubmitted(false); }
  }, [downloadUrl]);

  if(filterInStock && !quantity) {
    return(
      <></>
    )
  }

  return(
    <div className="list-element list-element--normal list-element--has-hover">
      <div className="list-element__toggle">
        <input type="checkbox" value="on" checked={inList ? 'checked' : ''} id={itemId} tabindex="0" className="list-element__toggle-checkbox" onClick={() => switchSelection()}>
        </input>
        <label for={itemId} className="list-element__toggle-label">
        </label>
      </div>
      <div className="list-element__image">
        { smallThumbnailUrl && <img src={smallThumbnailUrl} /> }
        { !smallThumbnailUrl && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 123"><g stroke="#c2cbd3" stroke-miterlimit="10"><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M77.46 22.7H48.28l2.02-7.04h25.15zm0 43.26H48.28l2.02 7.05h25.15z"/><path fill="#edeff2" d="M52.63 7.59h20.48v74.29H52.63z"/><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M91.903 39.907v8.59h-3.82v-8.59z"/><ellipse cx="63.08" cy="44.21" fill="#fff" stroke-linecap="round" stroke-linejoin="round" rx="25.76" ry="25.53"/><g fill="#fff" stroke-linecap="round" stroke-linejoin="round"><ellipse cx="63.08" cy="44.13" rx="21.8" ry="21.6"/><path d="M51.34 33.05l11.91 11.24 7.47-3.93"/><ellipse cx="63.08" cy="44.13" rx="1.78" ry="1.76"/></g></g></svg> }
      </div>
      <div className="list-element__content">
        <div className="list-element__info">
          <div className="list-element__header">
            <div className="list-element__main-info">
              <div className="list-element__title"><span>{name}</span></div>
              { (!settings.manufacturer_mode && sku) && <div className="list-element__description"><span className="muted">{sku}</span></div> }
              { (settings.manufacturer_mode && upc) && <div className="list-element__description"><span className="muted">{upc}</span></div> }
              { ((!settings.manufacturer_mode && !sku) || (settings.manufacturer_mode && !upc)) && <div className="list-element__description text-default warning">No barcode symbol found, barcode will not generate.</div>}
            </div>
          </div>
          <div className="list-element__data-row">

          </div>
          <div className="list-element__data-row">
            <ul>
              { options && options.map(option =>
                  <li>
                    <strong>{ option.name }</strong>: { option.value }
                  </li>
                )
              }
              { quantity && 
                <li>In Stock: { quantity }</li>
              }
              { brand &&
                <li>Brand: { brand }</li>
              }
              { price &&
                <li>Price: { price }</li>
              }
              { !options &&
                <div class="text-default warning">
                  Base Product
                </div>
              }
            </ul>
          </div>
        </div>
        <div className="list-element__actions">
          { ((products.searchingBarcodes.indexOf(sku) > -1) || (products.searchingBarcodes.indexOf(upc) > -1)) &&
            <div className="canonical-status text-default canonical-status--info canonical-status--loading canonical-status--has-icon canonical-status--prepend-icon">
              <div className="canonical-status__icon">
                <svg width="1.25rem" height="1.25rem" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14,27C6.83,27,1,21.17,1,14c0-1.56,0.27-3.08,0.81-4.52C2.1,8.7,2.96,8.31,3.74,8.59c0.78,0.29,1.17,1.15,0.88,1.93 C4.21,11.63,4,12.8,4,14c0,5.51,4.49,10,10,10c5.51,0,10-4.49,10-10c0-5.51-4.49-10-10-10c-0.83,0-1.5-0.67-1.5-1.5S13.17,1,14,1 c7.17,0,13,5.83,13,13C27,21.17,21.17,27,14,27z"></path>
                </svg>
              </div>
              Finding barcode...
            </div>
          }
          <div className="list-element__price">
            { ((products.searchedBarcodes.indexOf(sku) > -1) || (products.searchedBarcodes.indexOf(upc) > -1)) && !localDownloadSubmitted &&
              <button class="btn-barcode" onClick={() => downloadImage(payload, itemId, sku, upc)}>
                <svg width="1.25rem" height="1.25rem" viewBox="0 0 32 32" id="svg5" version="1.1">
                  <defs id="defs2"/>
                  <g id="layer1" transform="translate(-108,-100)">
                    <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                    <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ color:'#000000', fill:'#000000', 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                  </g>
                </svg>
              </button>
            }
            { ((products.searchedBarcodes.indexOf(sku) > -1) ||  (products.searchedBarcodes.indexOf(upc) > -1)) && localDownloadSubmitted &&
              <div className="canonical-status text-default canonical-status--info canonical-status--loading canonical-status--has-icon canonical-status--prepend-icon">
                <div className="canonical-status__icon">
                  <svg width="1.25rem" height="1.25rem" viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg">
                    <path d="M14,27C6.83,27,1,21.17,1,14c0-1.56,0.27-3.08,0.81-4.52C2.1,8.7,2.96,8.31,3.74,8.59c0.78,0.29,1.17,1.15,0.88,1.93 C4.21,11.63,4,12.8,4,14c0,5.51,4.49,10,10,10c5.51,0,10-4.49,10-10c0-5.51-4.49-10-10-10c-0.83,0-1.5-0.67-1.5-1.5S13.17,1,14,1 c7.17,0,13,5.83,13,13C27,21.17,21.17,27,14,27z"></path>
                  </svg>
                </div>
              </div>
            }
          </div>
          { ((products.searchedBarcodes.indexOf(sku) > -1) || (products.searchedBarcodes.indexOf(upc) > -1)) &&
            <div className="list-element__buttons-set product__actions">
              <Link to={`/barcode_actions/${settings.manufacturer_mode ? upc : sku}`}>
                <button type="button" className="btn btn-default btn-small">Preview Labels</button>
              </Link>
            </div>
          }
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  settings: state.settings.settings,
  selectedProductList: state.products.selectedProductList,
  products: state.products,
  downloadUrl: state.barcodes.downloadUrl,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedProductList: (items) => dispatch(updateSelectedItems(items)),
  downloadBarcode: (payload, itemId, sku, upc, manufacturerMode) => dispatch(downloadBarcodeImage(payload, itemId, sku, upc, manufacturerMode)),
  loadBarcodeImage: (payload, itemId, sku, upc, manufacturerMode) => dispatch(findBarcodeImage(payload, itemId, sku, upc, manufacturerMode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SkuLine);
