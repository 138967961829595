import React, { useState, useEffect } from 'react';

const RepeatScanDialog = ({ quantity, setShowRepeatScanDialog, focusScanArea }) => {
  useEffect(() => {
    let confirm = document.getElementById('confirm-button');
    confirm.focus();
  }, []);

  const close = () => {
    setShowRepeatScanDialog(false);
    focusScanArea();
  }

  return(
    <div className="main-overlay main-overlay__warning">
      <div style={{ top: `${window.innerHeight/2}px` }} className="main-popup popup-center-always main-popup_center main-popup--thin-padding main-popup_animation">
        <div className="main-popup__container">
          <div className="main-popup__close" onClick={() => close()}>
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.7929 1.7929c.3905-.3905 1.0237-.3905 1.4142 0L9 7.5858l5.7929-5.793c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L10.4142 9l5.7929 5.7929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L9 10.4142l-5.7929 5.7929c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L7.5858 9l-5.793-5.7929c-.3904-.3905-.3904-1.0237 0-1.4142z">
              </path>
            </svg>
          </div>
          <div className="main-popup__subcontainer">
            <h2 className="warning">Repeat Item</h2>
            <div>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M11 10a1 1 0 1 1 2 0v3.5a1 1 0 1 1-2 0V10zM12 15.75a1.25 1.25 0 1 0 0 2.5 1.25 1.25 0 0 0 0-2.5z" fill="currentColor"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M10.268 2.991c.771-1.321 2.683-1.322 3.455 0l9.342 16.004c.778 1.332-.184 3.005-1.728 3.005H2.653C1.109 22 .148 20.327.925 18.995L10.268 2.99zm11.07 17.01L11.994 3.998 2.653 20.001h18.684z" fill="currentColor"/>
              </svg>            
              <span className="left-15">The scanned item is in this order, but may have already been picked. Please confirm this quantity:</span>
            </div>
            <p className="pick-quantity">
              { quantity }
            </p>

            <br/>
            <button id="confirm-button" className="bottom-5 btn btn-primary btn-medium" onClick={() => close()}>Confirm</button>
          </div>
        </div>
      </div>
    </div>
  )

}

export default RepeatScanDialog;
