import { Link } from "react-router-dom";

const MultipleBatchesMessage = ({ payload, bulkInventorySession, batchRefresh }) => {
  return (
    <div className={`notify notify--show`}>
      <div className="notify__content notify__content-darken">
        <div className="notify__title">
          Multiple Batches Created
        </div>
        <div className="notify__text">
          View your batch changes to see updates on each set of changes.
        </div>
        <div className="notify__action">
          <Link to='../../update_results' relative="path">
            <button className="btn btn-fullwidth btn-default btn--ico-after btn-medium">
              <span className="gwt-InlineLabel">View Batch Results</span>
              <span className="svg-icon">
                <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path d="M2 16c0-.5523.4477-1 1-1h12c.5523 0 1 .4477 1 1s-.4477 1-1 1H3c-.5523 0-1-.4477-1-1z"></path><path fill-rule="evenodd" clip-rule="evenodd" d="M9 1c.5523 0 1 .4477 1 1v7.5858l2.2929-2.293c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143l-4 4c-.3905.3905-1.0237.3905-1.4142 0l-4-4c-.3905-.3905-.3905-1.0237 0-1.4142.3905-.3905 1.0237-.3905 1.4142 0L8 9.5858V2c0-.5523.4477-1 1-1z"></path></svg>
              </span>
            </button>
          </Link>
        </div>
        <div className="notify__close">
        </div>
      </div>
    </div>
  )
}

export default MultipleBatchesMessage;