import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

const ScanRow = ({ event }) => {
  return(
    <div className="list-element list-element--normal list-element--has-hover">
      <div className="list-element__image">
        { event.smallThumbnailUrl && <img src={event.smallThumbnailUrl} /> }
        { !event.smallThumbnailUrl && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 123"><g stroke="#c2cbd3" stroke-miterlimit="10"><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M77.46 22.7H48.28l2.02-7.04h25.15zm0 43.26H48.28l2.02 7.05h25.15z"/><path fill="#edeff2" d="M52.63 7.59h20.48v74.29H52.63z"/><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M91.903 39.907v8.59h-3.82v-8.59z"/><ellipse cx="63.08" cy="44.21" fill="#fff" stroke-linecap="round" stroke-linejoin="round" rx="25.76" ry="25.53"/><g fill="#fff" stroke-linecap="round" stroke-linejoin="round"><ellipse cx="63.08" cy="44.13" rx="21.8" ry="21.6"/><path d="M51.34 33.05l11.91 11.24 7.47-3.93"/><ellipse cx="63.08" cy="44.13" rx="1.78" ry="1.76"/></g></g></svg> }
      </div>
      <div className="list-element__content">
        <div className="list-element__info">
          <div className="list-element__header">
            <div className="list-element__main-info">
              <div className="list-element__title"><h3>View Inventory: <span>{event.name}</span></h3></div><br/>
            </div>
          </div>

          <div className="flex-container">

            <div className="list-element__data-row flex-1">
              <div className="list-element__description">SKU: <span className="muted">{event.sku}</span></div><br/>
              <div className="list-element__description">Quantity: <span className="success">{event.quantity}</span></div>
            </div>
            <div className="list-element__data-row flex-1">
              <ul>
                { event.options && event.options.map(option =>
                    <li>
                      <strong>{ option.name }</strong>: { option.value }
                    </li>
                  )
                }
                { !event.options &&
                  <div class="text-default warning">
                    Base Product
                  </div>
                }
              </ul>
            </div>

            <div className="list-element__data-row flex-1 text-center">
              <h4>Quantity</h4>
              <h2>{event.quantity}</h2>
            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default ScanRow;