import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

const PrintLine = ({ id, itemId, item, type, printCount, setModifyItem, setModifyType, setModifyCount, setShowPrintCountDialog, setModifyItemId, missingBarcode, settings }) => {
  const setAndShowDialog = () => {
    setModifyCount(printCount);
    setModifyType(type);
    setModifyItem(item);
    setModifyItemId(itemId);
    setShowPrintCountDialog(true);
  }

  return(
    <div onClick={() => setAndShowDialog()} className="list-element list-element--normal list-element--has-hover">
      <div className="list-element__image">
        { item.smallThumbnailUrl && <img src={item.smallThumbnailUrl} /> }
        { !item.smallThumbnailUrl && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 123"><g stroke="#c2cbd3" stroke-miterlimit="10"><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M77.46 22.7H48.28l2.02-7.04h25.15zm0 43.26H48.28l2.02 7.05h25.15z"/><path fill="#edeff2" d="M52.63 7.59h20.48v74.29H52.63z"/><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M91.903 39.907v8.59h-3.82v-8.59z"/><ellipse cx="63.08" cy="44.21" fill="#fff" stroke-linecap="round" stroke-linejoin="round" rx="25.76" ry="25.53"/><g fill="#fff" stroke-linecap="round" stroke-linejoin="round"><ellipse cx="63.08" cy="44.13" rx="21.8" ry="21.6"/><path d="M51.34 33.05l11.91 11.24 7.47-3.93"/><ellipse cx="63.08" cy="44.13" rx="1.78" ry="1.76"/></g></g></svg> }
      </div>
      <div className="list-element__content">
        <div className="list-element__info">
          <div className="list-element__header">
            <div className="list-element__main-info">
              <div className="list-element__title"><span>{item.name}</span></div>
              { (!settings.manufacturer_mode && item.sku) && <div className="list-element__description"><span className="muted">{item.sku}</span></div> }
              { (settings.manufacturer_mode && item.upc) && <div className="list-element__description"><span className="muted">{item.upc}</span></div> }
            </div>
          </div>
          <div className="list-element__data-row">

          </div>
          <div className="list-element__data-row">
            { missingBarcode &&
              <div class="text-default warning">
                Missing Barcode
              </div>
            }
            { ((!settings.manufacturer_mode && !item.sku) || (settings.manufacturer_mode && !item.upc)) &&
              <div class="text-default warning">
                Missing Barcode and Barcode Symbol
              </div>
            }
            <ul>
              { item.options && item.options.map(option =>
                  <li>
                    <strong>{ option.name }</strong>: { option.value }
                  </li>
                )
              }
              { item.quantity && 
                <li>In Stock: { item.quantity }</li>
              }
              { item.price &&
                <li>Price: { item.price }</li>
              }
              { !item.options &&
                <div class="text-default warning">
                  Base Product
                </div>
              }
            </ul>
          </div>
        </div>
        <div className="list-element__actions">
          <div className="list-element__buttons-set product__actions">
            <div>Print Count: {printCount}</div>
            <button className="btn btn-default btn-link">Edit Count</button>
          </div>
        </div>
      </div>

    </div>
  )
}

const mapStateToProps = state => ({
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintLine);
