import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import SkuLine from "./SkuLine";
import ConfirmGenerationDialog from './ConfirmGenerationDialog';
import ConfirmGenerateAllDialog from './ConfirmGenerateAllDialog';
import GeneratingBarcodesNotice from './GeneratingBarcodesNotice';
import FeatureCard from '../partials/FeatureCard';
import LoadingSpinner from '../partials/LoadingSpinner';
import PageHeader from '../partials/PageHeader';
import NoLabelTemplatesBox from '../partials/NoLabelTemplatesBox';

import { updateSelectedItems, searchProducts } from '../../redux/actions/products';
import { refreshBatches } from '../../redux/actions/barcodes';

import { indexBarcodeTemplates } from '../../redux/actions/barcodeTemplates';
import { getCategories } from '../../redux/actions/categories';

const AssignBarcodes = ({ payload, getTemplates, loadCategories, categories, barcodeTemplates, searchedProducts, searchedBarcodes, setSelectedProductList, isSearching, submitSearch, selectedProductCount, selectedProductList, generateActions, batchRefresh, downloadUrl }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [missingBarcodeCount, setMissingBarcodeCount] = useState(0);
  const [showGenerateDialog, setShowGenerateDialog] = useState(false);
  const [showGenerateAllDialog, setShowGenerateAllDialog] = useState(false);
  const [barcodeActionsOptions, setBarcodeActionsOptions] = useState({});
  const [offsetY, setOffsetY] = useState(0);
  const [scrollY, setScrollY] = useState(0);
  const [filterInStock, setFilterInStock] = useState(false);
  const [filterCategory, setFilterCategory] = useState(null);
  const [showFilters, setShowFilters] = useState(false);

  const [enqueueSearch, setEnqueueSearch] = useState(null);
  const [downloadSubmitted, setDownloadSubmitted] = useState(false);

  useEffect(() => {
    getTemplates(payload);
    loadCategories(payload);
  }, []);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(searchTerm !== ''){
        handleSearch(searchTerm);
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [searchTerm, 500]);

  const handleSearch = (searchTerm) => {
    if(!isSearching) {
      setEnqueueSearch(null);
      submitSearch(payload, searchTerm, filterInStock, filterCategory);
    } else {
      setEnqueueSearch(searchTerm);
    }
  }

  useEffect(() => {
    handleSearch(searchTerm);
  }, [filterInStock]);

  useEffect(() => {
    handleSearch(searchTerm);
  }, [filterCategory]);

  useEffect(() => {
    if(!isSearching) {
      readySearch();
    }
  }, [isSearching]);

  const readySearch = () => {
    if(enqueueSearch) {
      submitSearch(payload, enqueueSearch, filterInStock, filterCategory);
      setEnqueueSearch(null);
    }
  }

  useEffect(() => {
    setShowGenerateDialog(false);
    setShowGenerateAllDialog(false);

    if(generateActions.length > 0){
      const timeoutId = setTimeout(() => {
        if(generateActions.length > 0){
          batchRefresh(payload);
        }
      }, 3000);
      return () => clearTimeout(timeoutId);

    }

    if(generateActions.length === 0 && searchTerm !== '') {
      handleSearch(searchTerm);
    }
  }, [generateActions]);

  useEffect(() => {
    countMissingBarcodes();
  }, [selectedProductList]);

  useEffect(() => {
    recountMissingBarcodes();
  }, [searchedProducts]);


  useEffect(() => {
    if(downloadUrl && downloadSubmitted) {
      let win = window.open(downloadUrl, '_blank');
      win.focus();
      setDownloadSubmitted(false);
    }
  }, [downloadUrl]);

  const validateTerm = (event) => {
    let value = event.target.value;

    setSearchTerm(value);
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const toggleFilters = (event) => {
    if(showFilters) {
      setShowFilters(false);
      event.target.parentElement.classList.remove("collapsible--opened");
    } else {
      setShowFilters(true);
      event.target.parentElement.classList.add("collapsible--opened");
    }
  }

  const countMissingBarcodes = () => {
    let count = 0;

    Object.keys(selectedProductList.variations).map(itemId => {
      if(!selectedProductList.variations[itemId].hasBarcode) {
        count = count + 1;
      }
    })

    Object.keys(selectedProductList.products).map(itemId => {
      if(!selectedProductList.products[itemId].hasBarcode) {
        count = count + 1;
      }
    })

    setMissingBarcodeCount(count);
  }

  const recountMissingBarcodes = () => {
    let productSection = selectedProductList.products;
    let variationsSection = selectedProductList.variations;
    let count = 0;
    
    for (const [key, value] of Object.entries(productSection)) {
      if (value.hasBarcode === true) { continue; }

      if (searchedBarcodes.indexOf(value.sku) > -1) {
        value.hasBarcode = true;
      } else {
        value.hasBarcode = false;
        count = count + 1
      }
    }

    for (const [key, value] of Object.entries(variationsSection)) {
      if (value.hasBarcode === true) { continue; }

      if (searchedBarcodes.indexOf(value.sku) > -1) {
        value.hasBarcode = true;
      } else {
        value.hasBarcode = false;
        count = count + 1
      }
  
    }

    setMissingBarcodeCount(count);
    setSelectedProductList({ ...selectedProductList });
  }

  const selectAllSearched = () => {
    let productSection = selectedProductList.products;
    let variationsSection = selectedProductList.variations;

    searchedProducts.forEach((product) => {
      let productInList = Object.keys(productSection).indexOf(String(product.Id)) > -1;
      if (!productInList && product.combinations.length === 0) {

        if(product.quantity > 0 || !filterInStock) {
          let upc = product.attrs.find(attr => attr.name === 'UPC')?.value;
          let brand = product.attrs.find(attr => attr.name === 'Brand')?.value;

          productSection[String(product.id)] = {
            sku: product.sku,
            smallThumbnailUrl: product.smallThumnailUrl,
            options: product.options,
            name: product.name,
            hasBarcode: ((searchedBarcodes.indexOf(product.sku) > -1) || (searchedBarcodes.indexOf(upc) > -1)),
            quantity: product.quantity,
            price: product.price,
            upc: upc,
            brand: brand
          }
        }

      }

      product?.combinations?.forEach((combination) => {
        let combinationInList = Object.keys(variationsSection).indexOf(String(combination.Id)) > -1;
        if (!combinationInList) {

          if(((combination.quantity || product.quantity) > 0) || !filterInStock) {
            let upc = combination.attrs.find(attr => attr.name = 'UPC')?.value;
            let brand = product.attrs.find(attr => attr.name === 'Brand')?.value;

            variationsSection[String(combination.id)] = {
              sku: combination.sku,
              smallThumbnailUrl: combination.smallThumnailUrl || product.smallThumbnailUrl,
              options: combination.options,
              name: combination.name || product.name,
              hasBarcode: ((searchedBarcodes.indexOf(combination.sku) > -1) || (searchedBarcodes.indexOf(upc) > -1)),
              quantity: combination.quantity || product.quantity,
              price: combination.price || combination.defaultDisplayedPrice || product.price,
              upc: upc,
              brand: brand
            }
          }

        }
      })
    })

    setSelectedProductList({ ...selectedProductList });
  }

  const deselectAllSelected = () => {
    setSelectedProductList({ variations: {}, products: {} });
  }

  const handleSwitchFilterInStock = () => {
    setFilterInStock(!filterInStock);
  }

  const handleCategorySelect = (event) => {
    setFilterCategory(event.target.value);
  }

  const clearCategory = () => {
    setFilterCategory(null);
    document.getElementById('categoryFilter').selectedIndex = -1;
  }

  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  return(
    <>
      <PageHeader stripeBackground='#1774bb' helpLink="ecwid/manage-barcode-label-templates/" />
      <div className="settings-page cf">
        <div className="settings-page__header">
          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Labels + Print</div>
              </div>
              <div className="named-area__description">
                <p>Click on the "Manage label templates" button if you're looking to create or modify barcode labels for printing.</p>
                <p>Search for products or variations by the "Catalog Search" bar. From the results, select the items you're looking to take action with.</p>
                <p>After selecting your items, you can generate new barcodes for products missing or with incorrect barcodes by clicking the "Generate Barcodes" button.</p>
                <p>If you have selected items that have barcodes, you can generate a print file for those barcodes with a label template by clicking the "Print Selected Barcodes" button.</p>
                <p>You can also download generated barcodes by clicking the barcode icon on any searched item where a barcode has already been generated.</p>
                <p>To preview and download a barcode with a specific template, click the "Preview Labels" button on any searched item.</p>
              </div>
            </div>
            <div className="named-area__body">

              <div className="a-card-stack">
                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">

                    <div className="feature-element has-picture has-icon">
                      <div className="feature-element__core">
                        <div className="feature-element__data">
                          <div className="feature-element__content">
                            <div className="feature-element__action">

                              { !(barcodeTemplates.status === 'success' && barcodeTemplates.templates.length === 0) && 
                                <FeatureCard
                                  title="My Label Templates"
                                  description="Create and update label templates for different printers and label sizes."
                                  buttonText="Manage label templates"
                                  iconSvg={<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 10 17" focusable="false">
                                            <path d="M7.3 8.5L1 14.8c-.4.4-.4 1.1 0 1.6.4.4 1.1.4 1.6 0l6.8-6.8c.7-.7.7-1.5 0-2.2L2.6.6C2.1.2 1.4.2 1 .6c-.4.5-.4 1.2 0 1.6l6.3 6.3z"></path>
                                          </svg>}
                                  linkUrl="/templates"
                                  showImage={false}
                                  cardClasses="a-card__darken"
                                />
                              }

                              { barcodeTemplates.status === 'success' && barcodeTemplates.templates.length === 0 && 
                                <NoLabelTemplatesBox />
                              }

                              { barcodeTemplates.status !== 'success' && 
                                <LoadingSpinner statusText="Loading Label Templates" />
                              }

                              { barcodeTemplates.status === 'success' && barcodeTemplates.templates.length > 0 &&
                                <span className="text-default">
                                  { barcodeTemplates.templates.length } Templates Available
                                </span>
                              }
                            </div>
                          </div>
                        </div>
                        <div className="feature-element__picture">
                          <div className="smooth-panel">
                            <div className="preview-container preview-container--grey">
                              <div className="spacing--mb1 text-center"><strong>{ selectedProductCount }</strong> items selected</div>
                              { selectedProductCount > 0 &&
                                <button className="bottom-5 btn btn-primary btn-medium btn-fullwidth" onClick={() => setShowGenerateDialog(true)}>Generate Barcodes</button>
                              }
                              { selectedProductCount === 0 &&
                                <button className="bottom-5 btn btn-primary btn-medium btn-fullwidth" onClick={() => setShowGenerateDialog(true)} disabled>Generate Barcodes</button>
                              }
                              
                              <br/>
                              { (selectedProductCount > 0) &&
                                <Link to={`/print`}>
                                  <button className="btn btn-primary-accent btn-medium btn-fullwidth">Print Product Labels</button>
                                </Link>
                              }
                              { (selectedProductCount === 0) &&
                                <button className="btn btn-primary-accent btn-medium btn-fullwidth" disabled>Print Product Labels</button>
                              }
                              { missingBarcodeCount > 0 &&
                                <>
                                  <br/>
                                  { (missingBarcodeCount > 0) && <span className="text-default warning">
                                      <strong>{ missingBarcodeCount }</strong> missing barcodes
                                    </span>
                                  }
                                </>
                              }
                              <hr/>
                              <button className="btn btn-muted-accent btn-medium btn-fullwidth" onClick={() => setShowGenerateAllDialog(true)}>Generate All Barcodes</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>

                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">
                    <div className="iconable-block iconable-block--hide-in-mobile">
                      <div className="iconable-block__infographics">
                        <span className="iconable-block__icon">
                          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path fill-rule="evenodd" clip-rule="evenodd" d="M18 7c0 3.866-3.134 7-7 7-1.5724 0-3.0236-.5184-4.1922-1.3936l-4.1007 4.1007c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3906-.3905-1.0237 0-1.4143l4.1007-4.1007C4.5184 10.0236 4 8.5723 4 7c0-3.866 3.134-7 7-7s7 3.134 7 7zm-2 0c0 2.7614-2.2386 5-5 5S6 9.7614 6 7s2.2386-5 5-5 5 2.2386 5 5z"/></svg>
                        </span>
                      </div>
                      <div className="iconable-block__content">
                        <div className="status-block">
                          <div className="status-block__central">
                            <div className="status-block__header">
                              <span className="status-block__title">
                                Catalog Search
                                { isSearching && 
                                  <div className="left-15 canonical-status text-default canonical-status--info canonical-status--loading canonical-status--has-icon canonical-status--prepend-icon">
                                    <div className="canonical-status__icon">
                                      <svg viewBox="0 0 28 28" xmlns="http://www.w3.org/2000/svg" width="28" height="28">
                                        <path d="M14,27C6.83,27,1,21.17,1,14c0-1.56,0.27-3.08,0.81-4.52C2.1,8.7,2.96,8.31,3.74,8.59c0.78,0.29,1.17,1.15,0.88,1.93 C4.21,11.63,4,12.8,4,14c0,5.51,4.49,10,10,10c5.51,0,10-4.49,10-10c0-5.51-4.49-10-10-10c-0.83,0-1.5-0.67-1.5-1.5S13.17,1,14,1 c7.17,0,13,5.83,13,13C27,21.17,21.17,27,14,27z"></path>
                                      </svg>
                                    </div>
                                  </div>
                                }
                              </span>
                              <span className="status-block__edit">Edit</span>
                            </div>
                            <div className="status-block__content">
                              <div className="fieldset full-width">
                                <div className="field field--medium">
                                  <span className="fieldset__svg-icon"></span>
                                  <label className="field__label">Search</label>
                                  <input type="text" value={searchTerm} onFocus={focus} onBlur={blur} onChange={validateTerm} className="field__input" tabindex="4" maxlength="64" />
                                  <div className="field__placeholder">Search</div>
                                  <span className="field-state--success"></span>
                                  <span className="field-state--close"></span>
                                </div>
                                <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>

                                <div class="collapsible top-15 padding-15 a-card__darken search-filters" onClick={(e) => toggleFilters(e)}>
                                  <div class="collapsible__header">Search filters</div>
                                  <div class="collapsible__body">

                                    <div className="flex-container">
                                      <div className="element flex-1">
                                        <div className="element__title">
                                          In Stock
                                        </div>
                                        <label className="checkbox tiny">
                                          { filterInStock && <input type="checkbox" checked="true" onClick={() => handleSwitchFilterInStock()}/>}
                                          { !filterInStock && <input type="checkbox" onClick={() => handleSwitchFilterInStock()}/>}
                                          <div><div></div></div>
                                        </label>
                                      </div>

                                      <div className="flex-1">
                                        { !categories.isFetching && categories.status === "success" &&
                                          <>
                                            <div className="fieldset fieldset--select">
                                              <div className="field field--medium field--filled">
                                                <label className="field__label">Category</label>
                                                <select value={filterCategory} id="categoryFilter" onChange={(e) => handleCategorySelect(e)} className="field__select">
                                                  <option hidden disabled selected value></option>
                                                  { Object.entries(categories.categories).map(([key, name]) =>
                                                    <option value={key}>{name}</option>
                                                  )}
                                                </select>

                                                <div className="field__placeholder">Category</div>
                                              </div>
                                            </div>
                                            <button className="left-15 bottom-5 btn btn-link btn-medium" onClick={() => clearCategory()}>
                                              Clear Category
                                            </button>
                                          </>
                                        }
                                        { categories.isFetching &&
                                          <LoadingSpinner statusText="Loading categories" />
                                        }
                                      </div>
                                    </div>

                                  </div>
                                </div>

                                { searchedProducts && searchedProducts.length > 0 &&
                                  <button className="top-5 btn btn-primary btn-medium" onClick={() => selectAllSearched()}>Select All Searched Items</button>
                                }
                                { (!searchedProducts || searchedProducts.length === 0) &&
                                  <button className="top-5 btn btn-primary btn-medium" disabled>Select All Searched Items</button>
                                }

                                { (Object.keys(selectedProductList.products).length + Object.keys(selectedProductList.variations).length ) > 0 &&
                                  <button className="top-5 left-15 btn btn-primary btn-medium" onClick={() => deselectAllSelected()}>Deselect All Selected Items</button>
                                }
                                { (Object.keys(selectedProductList.products).length + Object.keys(selectedProductList.variations).length ) === 0 &&
                                  <button className="top-5 left-15 btn btn-primary btn-medium" disabled>Deselect All Selected Items</button>
                                }

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              { generateActions.map(notice =>
                <GeneratingBarcodesNotice notice={notice} />
              )}

              { !isSearching && 
                <div className="filtered-list__items long-list">
                  { (searchTerm !== '' || filterCategory) && searchedProducts !== null && 
                    <div>
                      { searchedProducts.map(product => 
                          <>
                            { product.combinations.length === 0 &&
                              <SkuLine
                                payload={payload}
                                itemId={product.id}
                                sku={product.sku}
                                upc={product.attrs.find(attr => attr.name === 'UPC')?.value || false}
                                brand={product.attrs.find(attr => attr.name === 'Brand')?.value || false}
                                name={product.name}
                                options={false}
                                smallThumbnailUrl={product.smallThumbnailUrl}
                                rowId={product.id}
                                type={'product'}
                                quantity={product.quantity}
                                price={product.price}
                                setDownloadSubmitted={setDownloadSubmitted}
                                filterInStock={filterInStock}
                              />
                            }
                            { product?.combinations?.map(combination =>
                                <SkuLine
                                  payload={payload}
                                  itemId={combination.id}
                                  name={product.name}
                                  sku={combination.sku}
                                  upc={combination.attrs.find(attr => attr.name === 'UPC')?.value || false}
                                  brand={combination.attrs.find(attr => attr.name === 'Brand')?.value || false}
                                  options={combination.options}
                                  smallThumbnailUrl={combination.smallThumbnailUrl || product.smallThumbnailUrl}
                                  rowId={combination.id}
                                  type={'variation'}
                                  quantity={combination.quantity || product.quantity}
                                  price={combination.price || combination.defaultDisplayedPrice || product.price}
                                  setDownloadSubmitted={setDownloadSubmitted}
                                  filterInStock={filterInStock}
                                />
                              )
                            }
                          </>
                        )
                      }
                  </div>
                  }
                </div>
              }

              { searchedProducts === null &&
                <>
                  <span className="text-default">
                    <strong>{ selectedProductCount } </strong>
                    Items selected
                  </span>
                  <div className="filtered-list">
                    <div className="filtered-list__items long-list">
                      { selectedProductList.products && Object.keys(selectedProductList.products).map(itemId => 
                          <SkuLine
                            payload={payload}
                            itemId={itemId}
                            sku={selectedProductList.products[itemId].sku}
                            upc={selectedProductList.products[itemId].upc}
                            name={selectedProductList.products[itemId].name}
                            options={false}
                            smallThumbnailUrl={selectedProductList.products[itemId].smallThumbnailUrl}
                            rowId={itemId}
                            type={'product'}
                            price={selectedProductList.products[itemId].price}
                            setDownloadSubmitted={setDownloadSubmitted}
                            filterInStock={filterInStock}
                          />
                        )
                      }
                      { selectedProductList.variations && Object.keys(selectedProductList.variations).map(itemId => 
                          <SkuLine
                            payload={payload}
                            itemId={itemId}
                            sku={selectedProductList.variations[itemId].sku}
                            upc={selectedProductList.variations[itemId].upc}
                            name={selectedProductList.variations[itemId].name}
                            options={selectedProductList.variations[itemId].options}
                            smallThumbnailUrl={selectedProductList.variations[itemId].smallThumbnailUrl}
                            rowId={itemId}
                            type={'variations'}
                            price={selectedProductList.variations[itemId].price}
                            setDownloadSubmitted={setDownloadSubmitted}
                            filterInStock={filterInStock}
                          />
                        )
                      }
                    </div>
                  </div>
                </>
              }
            </div>
          </div>
        </div>
      </div>
      { showGenerateDialog && 
        <ConfirmGenerationDialog
          payload={payload}
          setShowGenerateDialog={setShowGenerateDialog}
        />
      }
      { showGenerateAllDialog && 
        <ConfirmGenerateAllDialog
          payload={payload}
          setShowGenerateAllDialog={setShowGenerateAllDialog}
        />
      }
    </>
  )
}

const mapStateToProps = state => ({
  isSearching: state.products.isFetching,
  searchedProducts: state.products.searchedProducts,
  selectedProductList: state.products.selectedProductList,
  selectedProductCount: Object.keys(state.products.selectedProductList.variations).length + Object.keys(state.products.selectedProductList.products).length,
  generateActions: state.barcodes.generateActions,
  downloadUrl: state.barcodes.downloadUrl,
  barcodeTemplates: state.barcodeTemplates,
  searchedBarcodes: state.products.searchedBarcodes,
  categories: state.categories,
});

const mapDispatchToProps = (dispatch) => ({
  submitSearch: (payload, searchTerm, filterInStock, filterCategory) => dispatch(searchProducts(payload, searchTerm, filterInStock, filterCategory)),
  batchRefresh: (payload) => dispatch(refreshBatches(payload)),
  getTemplates: (payload) => dispatch(indexBarcodeTemplates(payload)),
  loadCategories: (payload) => dispatch(getCategories(payload)),

  setSelectedProductList: (items) => dispatch(updateSelectedItems(items)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AssignBarcodes);
