import { connect } from 'react-redux';
import { getBatchResults } from '../../redux/actions/batchInventoryResults';

const EmptyResults = ({ payload, loadBatchResults }) => {
  return (
    <div className="settings-page cf">
      <div className="settings-page__header">
        <div className="named-area">
          <div className="named-area__header">
            <div className="named-area__titles">
              <div className="named-area__title">Batch Update Results</div>
            </div>
            <div className="named-area__description">
            </div>
          </div>
          <div className="named-area__body">
            <div className="a-card a-card--compact">
              <div className="a-card__paddings">
                <h2>Refresh Batch Results</h2>
                <button className="btn btn-primary btn-medium" onClick={() => loadBatchResults(payload)}>Refresh Batches</button>
              </div>
            </div>

            <div className="a-card a-card--compact a-card--info">
              <div className="a-card__paddings">
                <div className="iconable-block iconable-block--hide-in-mobile iconable-block--info">
                  <div className="iconable-block__infographics"></div>
                  <div className="iconable-block__content">
                    <div className="cta-block">
                      <div className="cta-block__central">
                        <div className="cta-block__title">You have no stored batch changes</div>
                        <div className="cta-block__content">When additions, updates or inventory reductions are performed, results of those actions will appear here. Live modifications are not saved. Information is saved for 2 weeks after a batch change is made.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({

});

const mapDispatchToProps = (dispatch) => ({
  loadBatchResults: (payload) => dispatch(getBatchResults(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(EmptyResults); 