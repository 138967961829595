import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import LoadingSpinner from '../partials/LoadingSpinner';

const RequestRow = ({ event }) => {
  return(
    <div className="list-element list-element--normal list-element--has-hover">
      <div className="list-element__image">
        <LoadingSpinner statusText=' ' />
      </div>
      <div className="list-element__content">
        <div className="list-element__info">
          <div className="list-element__header">
            <div className="list-element__main-info">
              <div className="list-element__title"><h3>{event.actionType}: <span>{event.sku}</span></h3></div><br/>
            </div>
          </div>

          <div className="flex-container">

            <div className="list-element__data-row flex-1">
              <div className="list-element__description">SKU: <span className="muted">{event.sku}</span></div><br/>
            </div>
            <div className="list-element__data-row flex-1">

            </div>

            <div className="list-element__data-row flex-1 text-center">

            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default RequestRow;