const LoadingPlaceholder = ({ title }) => {
  return(
    <div className='settings-page cf'>
        <div className='settings-page__body'>
          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">{title}</div>
              </div>
              <div className="named-area__description">
              </div>
            </div>
            <div className="named-area__body">
              <div className="a-card a-card--normal">
                <div className="a-card__paddings">
                  <div className="feature-element">
                    <div className="feature-element__core">
                      <div className="feature-element__data">
                        <div className="placeholder-titles placeholder-titles--small">
                          <div className="placeholder-blink placeholder-title placeholder-blink--delay2x"></div>
                          <div className="placeholder-subtitle">
                            <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"></div>
                            <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"></div>
                            <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"></div>
                            <div className="placeholder-blink placeholder-subtitle__line placeholder-blink--delay2x"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default LoadingPlaceholder;
