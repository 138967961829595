import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useParams } from "react-router-dom";

import LoadingSpinner from '../partials/LoadingSpinner';
import PageHeader from '../partials/PageHeader';

import LineItem from "./LineItem";

import CorrectScanDialog from "./CorrectScanDialog";
import IncorrectScanDialog from "./IncorrectScanDialog";
import RepeatScanDialog from "./RepeatScanDialog";

import { downloadOrderReceipt } from '../../redux/actions/orderSessions';
import { readOrder, setCheckedList, setUnpickableList, clearOrder, indexOrderStatuses, updateOrderStatus } from '../../redux/actions/pickOrder';

const PickOrder = ({ payload, pickOrder, lineItems, fulfillmentStatus, searchOrder, pushCheckedList, resetOrder, loadOrderStatuses, updateStatus, pushUnpickableList, downloadReceipt, sessionPdf, downloadError }) => {
  let { orderId } = useParams();

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState(null);

  const [searchSubmitted, setSearchSubmitted] = useState(false);

  const [displayCorrect, setDisplayCorrect] = useState(false);
  const [displayIncorrect, setDisplayIncorrect] = useState(false);
  const [displayRepeat, setDisplayRepeat] = useState(false);

  const [dialogQuantity, setDialogQuantity] = useState(0);
  const [dialogLineItemId, setDialogLineItemId] = useState();
  const [displayConfirmOrderUpdate, setDisplayConfirmOrderUpdate] = useState(false);

  const [orderScan, setOrderScan] = useState('');
  const [scanTerm, setScanTerm] = useState('');

  const [selectedStatus, setSelectedStatus] = useState('');

  const [hidePickedItems, setHidePickedItems] = useState(true);

  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  const updateOrder = (event) => {
    let value = event.target.value;
    setOrderScan(value);
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const updateScan = (event) => {
    let value = event.target.value;
    setScanTerm(value);
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const submitSearch = () => {
    searchOrder(payload, orderScan);
    setSearchSubmitted(true);
  }

  const toggleChecked = (lineItemId) => {
    let newChecked = pickOrder.checkedItems;

    if(pickOrder.checkedItems.includes(lineItemId)) {
      newChecked.splice(pickOrder.checkedItems.indexOf(lineItemId), 1);
    } else {
      newChecked.push(lineItemId);
    }

    pushCheckedList(newChecked);
  }

  const switchUnpickable = (lineItemId) => {
    let newUnpickable = pickOrder.unpickableItems;

    if(pickOrder.unpickableItems.includes(lineItemId)) {
      newUnpickable.splice(pickOrder.unpickableItems.indexOf(lineItemId), 1);
    } else {
      newUnpickable.push(lineItemId);
    }

    pushUnpickableList(newUnpickable);
  }

  const focusScanArea = () => {
    let area = document.getElementById('barcode-scan');
    area.focus();
  }

  const reset = () => {
    resetOrder();
    setOrderScan('');
    setSearchSubmitted(false);
  }

  const switchFulfillmentStatus = (event) => {
    let status = pickOrder.fulfillmentStatuses.find(status => status.status_id === event.target.value);
    console.log(status);
    setSelectedStatus(status.status_id);
  }

  const updateOrderStatus = () => {
    updateStatus(payload, pickOrder.orderId, selectedStatus);
    reset();
  }

  const requestReceipt = (payload, orderId) => {
    setSubmitted(true);
    setError(null);
    downloadReceipt(payload, orderId);
  }

  useEffect(() => {
    if(orderId) {
      searchOrder(payload, orderId);
      setSearchSubmitted(true);
    }

    loadOrderStatuses(payload);
  }, []);

  useEffect(() => {
    setSelectedStatus(fulfillmentStatus);
  }, [fulfillmentStatus])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if(scanTerm !== ''){
        let matchingLine = lineItems.find(lineItem => { return ((lineItem.sku === scanTerm) || (lineItem.upc === scanTerm)); });
        let confirmId = '';

        if(!matchingLine) {
          setDisplayIncorrect(true);
        } else {
          setDialogQuantity(matchingLine.quantity);

          if(pickOrder.checkedItems.includes(matchingLine.lineItemId)) {
            setDisplayRepeat(true);
          } else {
            setDialogLineItemId(matchingLine.lineItemId);
            setDisplayCorrect(true);
            toggleChecked(matchingLine.lineItemId);
          }
        }

        setScanTerm('');
      }
    }, 500);
    return () => clearTimeout(timeoutId);
  }, [scanTerm, 500]);

  useEffect(() => {
    if(pickOrder.orderId){
      flashScanBar();
    }
  }, [pickOrder]);

  useEffect(() => {
    if(sessionPdf) {
      setSubmitted(false);
      let win = window.open(sessionPdf, '_blank');
      win.focus();
    }
  }, [sessionPdf]);

  useEffect(() => {
    setSubmitted(false);
    setError(downloadError);
  }, [downloadError]);


  const flashScanBar = () => {
    let scanBarElement = document.querySelector("#scan-bar");

    setTimeout(() => {
      scanBarElement?.classList?.add("blink-action");
    }, 500);

    setTimeout(() => {
      scanBarElement?.classList?.remove("blink-action");
    }, 1500);
  }

  if(!pickOrder.orderId) {
    return(
      <>
        <PageHeader stripeBackground='#070808' helpLink="ecwid/pick-and-verify-orders-with-ecwid/" />

        <div className="settings-page cf">
          <div className="settings-page__header">
            <div className="named-area">
              <div className="named-area__header">
                <div className="named-area__titles">
                  <div className="named-area__title">Pick and Verify Orders</div>
                </div>
                <div className="named-area__description">
                  <p>Enter an order number or scan an order barcode to load the order.</p>
                </div>
              </div>
              <div className="named-area__body">

                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">
                    <div className="iconable-block iconable-block--hide-in-mobile">
                      <div className="iconable-block__infographics">
                        <span className="iconable-block__icon">
                          <svg width="1.25rem" width="18" height="18" fill="#189dee" viewBox="0 0 32 32" id="svg5" version="1.1">
                            <defs id="defs2"/>
                            <g id="layer1" transform="translate(-108,-100)">
                              <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                              <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            </g>
                          </svg>
                        </span>
                      </div>

                      <div className="iconable-block__content">
                        <div className="status-block">
                          <div className="status-block__central">
                            <div className="status-block__header">
                              <span className="status-block__title">Order Number</span>
                              <span className="status-block__edit">Edit</span>
                            </div>
                            <div className="status-block__content">
                              <div className="fieldset fieldset__half-width">
                                <div className="field field--large">
                                  <span className="fieldset__svg-icon"></span>
                                  <label className="field__label">Scan or Enter an Order Number</label>
                                  
                                  { !pickOrder.isFetching && <input type="text" value={orderScan} onFocus={focus} onBlur={blur} onChange={updateOrder} className="field__input" tabindex="4" /> }
                                  { pickOrder.isFetching && <input type="text" value={orderScan} onFocus={focus} onBlur={blur} onChange={updateOrder} className="field__input" tabindex="4" disabled /> }
                                  
                                  <div className="field__placeholder">Scan or enter an order number</div>
                                  <span className="field-state--success"></span>
                                  <span className="field-state--close"></span>
                                </div>
                                <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                              </div>
                              
                              { !pickOrder.isFetching && <button className="btn btn-primary btn-medium left-15 top-5" onClick={() => submitSearch()}>Find Order</button> }
                              { pickOrder.isFetching &&
                                <>
                                  <button className="btn btn-primary btn-medium left-15 top-5" disabled>Find Order</button> 
                                  <LoadingSpinner
                                    statusText='Loading order'
                                  />
                                </>
                              }

                            </div>
                            { pickOrder.status === "failure" && <p className="error">Could not find searched order.</p> }
                          </div>

                        </div>

                      </div>

                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

      </>
    )
  }

  return(
    <>
      <PageHeader stripeBackground='#39a0a0' helpLink="ecwid/pick-and-verify-orders-with-ecwid/" />
      <div className="settings-page cf">
        <div className="settings-page__header">

          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Pick and Verify Order: {pickOrder.orderId}</div>
              </div>
              <div className="named-area__description">
                <p>Select the "Scan Barcode" bar and scan a generated Code 128 SKU (the generated barcodes from the "Labels + Print" section of this app, or similar) with a barcode scanner. This scan should also work with UPCs, EANs or ISBN values if they have been set in products or variations.</p>
              </div>
            </div>
            <div className="named-area__body">

              <div className="a-card a-card--compact">
                <div className="a-card__paddings">
                  <div className="iconable-block iconable-block--hide-in-mobile">
                    <div className="iconable-block__infographics">
                      <span className="iconable-block__icon">
                        <svg width="1.25rem" width="18" height="18" fill="#189dee" viewBox="0 0 32 32" id="svg5" version="1.1">
                          <defs id="defs2"/>
                          <g id="layer1" transform="translate(-108,-100)">
                            <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                            <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                          </g>
                        </svg>
                      </span>
                    </div>
                    <div className="iconable-block__content">
                      <div className="status-block">
                        <div className="status-block__central">
                          <div className="status-block__header">
                            <span className="status-block__title">Scan Barcode (SKU, UPC, EAN)</span>
                            <span className="status-block__edit">Edit</span>
                          </div>
                          <div className="status-block__content">
                            <div className="fieldset fieldset__max">
                              <div id="scan-bar" className="field field--large">
                                <span className="fieldset__svg-icon"></span>
                                <label className="field__label">Scan Value</label>
                                
                                <input id="barcode-scan" type="text" value={scanTerm} onFocus={focus} onBlur={blur} onChange={updateScan} className="field__input" tabindex="4" inputmode='none' />
                                
                                <div className="field__placeholder">Click here to begin scanning</div>
                                <span className="field-state--success"></span>
                                <span className="field-state--close"></span>
                              </div>
                              <div className="field__error" aria-hidden="true" style={{display: 'none'}}></div>
                            </div>
                          </div>
                          <button className="btn btn-delete btn-medium top-15" onClick={() => reset()}>Clear Order</button>
                        </div>

                      </div>
                    </div>

                  </div>

                  <hr/>

                  <div class="iconable-block iconable-block--hide-in-mobile">
                    <div class="iconable-block__content">
                      <div class="toggle-row">
                        <div class="form-area">
                          <div class="form-area__title toggle-row__title form-area__title--medium">
                            <span class="gwt-InlineLabel form-area__title-text">Hide Picked Items</span>
                            <label class="checkbox tiny">
                              { hidePickedItems && <input name="" type="checkbox" checked onClick={() => setHidePickedItems(!hidePickedItems)} />}
                              { !hidePickedItems && <input name="" type="checkbox" onClick={() => setHidePickedItems(!hidePickedItems)} />}
                              <div data-on="Hide" data-off="Show"><div></div></div>
                              <span class="checkbox__on-text-placeholder">Hide</span>
                              <span class="checkbox__off-text-placeholder">Show</span>
                            </label>
                          </div>
                          <div>
                            <span class="gwt-InlineHTML">
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

              { lineItems.length > 0 && lineItems.length === pickOrder.checkedItems.length && pickOrder.unpickableItems.length === 0 &&
                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">
                    <h3>Picking Complete</h3>

                    <h4>Next Steps</h4>
                      <button className={`btn btn-default btn--ico-after btn-medium ${submitted ? 'btn-loading': ''}`} disabled={submitted} onClick={() => requestReceipt(payload, pickOrder.orderId)}>
                      <span className="gwt-InlineLabel">Download Order Details {`${pickOrder.orderId}`}</span>
                      <span className="svg-icon">
                        <svg width="18" height="18" viewBox="0 0 32 32" version="1.1"><path d="M30 14.25h-3.25v-8.25c0-0 0-0.001 0-0.001 0-0.206-0.084-0.393-0.219-0.529v0l-4-4c-0.136-0.136-0.324-0.22-0.531-0.22-0 0-0 0-0 0h-17c-0.414 0-0.75 0.336-0.75 0.75v0 12.25h-2.25c-0.414 0-0.75 0.336-0.75 0.75v0 9c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-8.25h26.5v8.25c0 0.414 0.336 0.75 0.75 0.75s0.75-0.336 0.75-0.75v0-9c-0-0.414-0.336-0.75-0.75-0.75v0zM5.75 2.75h15.939l3.561 3.561v7.939h-19.5zM26 21.25h-20c-0.414 0-0.75 0.336-0.75 0.75v0 8c0 0.414 0.336 0.75 0.75 0.75h20c0.414-0 0.75-0.336 0.75-0.75v0-8c-0-0.414-0.336-0.75-0.75-0.75v0zM25.25 29.25h-18.5v-6.5h18.5zM26.279 19.199c0.178-0.099 0.322-0.242 0.417-0.415l0.003-0.005c0.027-0.067 0.043-0.145 0.043-0.227 0-0.018-0.001-0.037-0.002-0.054l0 0.002c-0.004-0.21-0.087-0.399-0.221-0.54l0 0c-0.142-0.122-0.327-0.196-0.53-0.196s-0.389 0.074-0.531 0.196l0.001-0.001c-0.122 0.145-0.197 0.334-0.199 0.54v0c-0.001 0.011-0.001 0.024-0.001 0.037 0 0.189 0.077 0.359 0.2 0.483v0c0.132 0.136 0.317 0.221 0.521 0.221 0.007 0 0.014-0 0.021-0l-0.001 0c0.016 0.001 0.034 0.002 0.052 0.002 0.082 0 0.16-0.016 0.231-0.045l-0.004 0.001z"></path></svg>
                      </span>
                    </button>
                    { error &&
                      <div className="text-default error">
                        { error }
                      </div>
                    }

                    <hr/>

                    <p>Update the order's fulfillment status if needed, and clear the order to pick and verify another order.</p>

                    { pickOrder.fulfillmentStatuses.length > 0 && !pickOrder.orderStatusesFetching &&
                      <div className="fieldset fieldset--select">
                        <div className="field field--medium field--filled">
                          <label className="field__label">Fulfillment Status</label>
                          <select value={selectedStatus} id="statuses" onChange={(e) => switchFulfillmentStatus(e)} className="field__select">
                            <option hidden disabled selected value></option>
                            { pickOrder.fulfillmentStatuses.map(status =>
                              <option value={status.status_id}>{ status.name || status.status_id }</option>
                            )}
                          </select>

                          <div className="field__placeholder">Fulfillment Status</div>
                        </div>
                      </div>
                    }

                    { pickOrder.fulfillmentStatuses.length === 0 && pickOrder.orderStatusesFetching &&
                      <LoadingSpinner
                        statusText='Loading fulfillment statuses'
                      />
                    }

                    <button className="btn btn-primary-accent btn-medium left-15" onClick={() => updateOrderStatus()}>Update Status and Clear</button>

                  </div>
                </div>
              }

              { pickOrder.unpickableItems.length > 0 &&
                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">
                    <h3 class="text-default warning">Could Not Pick</h3>
                    <p>Update the order's status if needed, and clear the order to pick and verify another order.</p>

                    { pickOrder.fulfillmentStatuses.length > 0 && !pickOrder.orderStatusesFetching &&
                      <div className="fieldset fieldset--select">
                        <div className="field field--medium field--filled">
                          <label className="field__label">Fulfillment Status</label>
                          <select value={selectedStatus} id="statuses" onChange={(e) => switchFulfillmentStatus(e)} className="field__select">
                            <option hidden disabled selected value></option>
                            { pickOrder.fulfillmentStatuses.map(status =>
                              <option value={status.status_id}>{ status.name || status.status_id }</option>
                            )}
                          </select>

                          <div className="field__placeholder">Fulfillment Status</div>
                        </div>
                      </div>
                    }

                    { pickOrder.fulfillmentStatuses.length === 0 && pickOrder.orderStatusesFetching &&
                      <LoadingSpinner
                        statusText='Loading fulfillment statuses'
                      />
                    }

                    <button className="btn btn-primary-accent btn-medium left-15" onClick={() => updateOrderStatus()}>Update Status and Clear</button>

                  </div>
                </div>
              }

              <div className="filtered-list">
                <div className="filtered-list__items long-list">
                  { lineItems.map((lineItem, index) =>
                    <LineItem
                      lineItem={lineItem}
                      toggleChecked={toggleChecked}
                      setDialogLineItemId={setDialogLineItemId}
                      setDisplayConfirmOrderUpdate={setDisplayConfirmOrderUpdate}
                      switchUnpickable={switchUnpickable}
                      hidePickedItems={hidePickedItems}
                    />
                  )}
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
      { displayCorrect && 
        <CorrectScanDialog
          setShowCorrectScanDialog={setDisplayCorrect}
          focusScanArea={focusScanArea}
          quantity={dialogQuantity}
          switchUnpickable={switchUnpickable}
          dialogLineItemId={dialogLineItemId}
        />
      }
      { displayIncorrect && 
        <IncorrectScanDialog
          setShowIncorrectScanDialog={setDisplayIncorrect}
          focusScanArea={focusScanArea}
        />
      }
      { displayRepeat && 
        <RepeatScanDialog
          setShowRepeatScanDialog={setDisplayRepeat}
          focusScanArea={focusScanArea}
          quantity={dialogQuantity}
        />
      }
    </>
  )

}

const mapStateToProps = state => ({
  pickOrder: state.pickOrder,
  lineItems: state.pickOrder.lineItems,
  fulfillmentStatus: state.pickOrder.fulfillmentStatus,
  sessionPdf: state.orderSessions.invoicePdf,
  downloadError: state.orderSessions.invoiceError,
});

const mapDispatchToProps = (dispatch) => ({
  searchOrder: (payload, orderId) => dispatch(readOrder(payload, orderId)),
  pushUnpickableList: (skuList) => dispatch(setUnpickableList(skuList)),
  pushCheckedList: (skuList) => dispatch(setCheckedList(skuList)),
  resetOrder: () => dispatch(clearOrder()),
  loadOrderStatuses: (payload) => dispatch(indexOrderStatuses(payload)),
  updateStatus: (payload, orderId, status) => dispatch(updateOrderStatus(payload, orderId, status, 'fulfillment')),
  downloadReceipt: (payload, orderId) => dispatch(downloadOrderReceipt(payload, orderId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PickOrder);