import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { updateSettings } from '../../redux/actions/settings';
import PageHeader from '../partials/PageHeader';

import UploadStoreLogo from './UploadStoreLogo';

const StoreSettings = ({ payload, accounts, settings, submitSettings }) => {
  const [submitted, setSubmitted] = useState(false);
  const [settingsSaved, setSettingsSaved] = useState(true);

  const [settingsForm, setSettingsForm] = useState(settings);

  const [manufacturerMode, setManufacturerMode] = useState(settings['manufacturer_mode']);
  const [manufacturerBarcodeType, setManufacturerBarcodeType] = useState(settings['manufacturer_barcode_type']);

  const [displayTotals, setDisplayTotals] = useState(settings['display_totals']);

  useEffect(() => {
    setSubmitted(false);
  }, [settings]);

  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  const handleSwitch = () => {
    let updatedSettings = settingsForm;
    updatedSettings['manufacturer_mode'] = !manufacturerMode;

    setManufacturerMode(!manufacturerMode);
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const handleTotalsSwitch = () => {
    let updatedSettings = settingsForm;
    updatedSettings['display_totals'] = !displayTotals;

    setDisplayTotals(!displayTotals);
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const barcodeTypeSwitch = (e) => {
    setManufacturerBarcodeType(e.target.value);
    let updatedSettings = settingsForm;
    updatedSettings['manufacturer_barcode_type'] = e.target.value;
    setSettingsForm(updatedSettings);
    setSettingsSaved(false);
  }

  const handleSubmit = () => {
    setSubmitted(true);

    submitSettings(payload, settingsForm);
    setSettingsSaved(true);
  }

  return(
    <>
      <PageHeader stripeBackground='#ef8f2f' helpLink="ecwid/app-settings/" />

      <div className="settings-page cf">
        <div className="settings-page__header">

          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Enable Manufacturer Mode</div>
              </div>
              <div className="named-area__description">
                
              </div>
            </div>
            <div className="named-area__body">
              <div className="a-card-stack">

                <div className="a-card a-card--normal">
                  <div className="a-card__paddings">
                    <div className="status-block__primary-action">

                      { !submitted && <button className="btn btn-primary btn-medium" onClick={() => handleSubmit()}>Save Settings</button> }
                      { submitted && <button className="btn btn-primary btn-medium btn-loading" disabled /> }

                      { !settingsSaved && <div className="remove-button canonical-status text-default canonical-status--warning">
                          <div className="canonical-status__text">Settings changes have not been saved</div>
                        </div> }

                    </div>
                  </div>
                </div>

                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">
                    <div className="iconable-block iconable-block--hide-in-mobile">
                      <div className="iconable-block__infographics">
                        <span className="iconable-block__icon">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="#189dee" viewBox="0 0 20 20" focusable='false'>
                            <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm5.71 8.11l-5.95 6.52c-.24.24-.56.37-.87.37-.32 0-.63-.12-.87-.37L4.7 11.55c-.48-.48-.48-1.28 0-1.77a1.21 1.21 0 011.73.01l2.46 2.19 5.08-5.64a1.22 1.22 0 011.73 0c.48.5.48 1.28.01 1.77z"/>
                          </svg>
                        </span>
                      </div>
                      <div className="iconable-block__content">
                        <div className="status-block">
                          <div className="status-block__central">
                            <div className="status-block__header">
                              <span className="status-block__title">
                                Enable Manufacturer Mode
                              </span>
                            </div>
                            <div className="status-block__content">
                              <p>
                                Enable this switch to activate manufacturer mode. When on, barcodes will generate from the "UPC" field on products and variations. Select the manufacturer barcode type from the options that are presented.
                              </p>
                            </div>
                          </div>
                          <div className="status-block__primary-action">
                            <label className="checkbox big">
                              { manufacturerMode && <input type="checkbox" checked="true" onClick={() => handleSwitch()}/>}
                              { !manufacturerMode && <input type="checkbox" onClick={() => handleSwitch()}/>}
                              <div><div></div></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                { manufacturerMode && <div className="a-card a-card--normal">
                  <div className="a-card__paddings">
                    <div className="fieldset__title">Barcode Type</div>

                    <div className="graphic-radio-group">
                      <div className="graphic-radio-group__items">
                        <div className="graphic-radio graphic-radio--small">
                          <label className="graphic-radio__label">
                            <input 
                              type="radio" 
                              name="barcode-type" 
                              value="ean13" 
                              checked={manufacturerBarcodeType === "ean13"}
                              className="graphic-radio__input" 
                              onChange={ (e) => barcodeTypeSwitch(e) }
                            />
                            <div className="graphic-radio__icon">
                              <span className="svg-icon">
                                <svg width="1.25rem" height="1.25rem" viewBox="0 0 32 32" id="svg5" version="1.1">
                                  <defs id="defs2"/>
                                  <g id="layer1" transform="translate(-108,-100)">
                                    <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                  </g>
                                </svg>
                              </span>

                              <div className="graphic-radio__bg"></div>
                            </div>
                            <span className="graphic-radio__title">EAN 13</span>
                          </label>
                        </div>
                        <div className="graphic-radio graphic-radio--small">
                          <label className="graphic-radio__label">
                            <input 
                              type="radio" 
                              name="barcode-type" 
                              value="upca" 
                              checked={manufacturerBarcodeType === "upca"}
                              className="graphic-radio__input" 
                              onChange={ (e) => barcodeTypeSwitch(e) }
                            />
                            <div className="graphic-radio__icon">
                              <span className="svg-icon">
                                <svg width="1.25rem" height="1.25rem" viewBox="0 0 32 32" id="svg5" version="1.1">
                                  <defs id="defs2"/>
                                  <g id="layer1" transform="translate(-108,-100)">
                                    <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                  </g>
                                </svg>
                              </span>
                              <div className="graphic-radio__bg"></div>
                            </div>
                            <span className="graphic-radio__title">UPC-A</span>
                          </label>
                        </div>
                        <div className="graphic-radio graphic-radio--small">
                          <label className="graphic-radio__label">
                            <input 
                              type="radio" 
                              name="barcode-type" 
                              value="ean8" 
                              checked={manufacturerBarcodeType === "ean8"}
                              className="graphic-radio__input" 
                              onChange={ (e) => barcodeTypeSwitch(e) }
                            />
                            <div className="graphic-radio__icon">
                              <span className="svg-icon">
                                <svg width="1.25rem" height="1.25rem" viewBox="0 0 32 32" id="svg5" version="1.1">
                                  <defs id="defs2"/>
                                  <g id="layer1" transform="translate(-108,-100)">
                                    <path d="m 111,106 a 1.0001,1.0001 0 0 0 -1,1 v 3 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -2 h 2 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 z" id="path11698" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 134,106 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 2 v 2 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -3 a 1.0001,1.0001 0 0 0 -1,-1 z" id="path11700" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 137,121 a 1,1 0 0 0 -1,1 v 2 h -2 a 1,1 0 0 0 -1,1 1,1 0 0 0 1,1 h 3 a 1.0001,1.0001 0 0 0 1,-1 v -3 a 1,1 0 0 0 -1,-1 z" id="path11702" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 111,121 a 1,1 0 0 0 -1,1 v 3 a 1.0001,1.0001 0 0 0 1,1 h 3 a 1,1 0 0 0 1,-1 1,1 0 0 0 -1,-1 h -2 v -2 a 1,1 0 0 0 -1,-1 z" id="path11704" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 115,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11706" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 118,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11708" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 121,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11710" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 124,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11712" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 127,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11714" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 130,110 a 1,1 0 0 0 -1,1 v 10 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 v -10 a 1,1 0 0 0 -1,-1 z" id="path11716" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                    <path d="m 133,110 a 1,1 0 0 0 -1,1 v 5.20703 1.31445 V 121 a 1,1 0 0 0 1,1 1,1 0 0 0 1,-1 V 117.52148 116.20703 111 a 1,1 0 0 0 -1,-1 z" id="path11720" style={{ 'fill-rule':'evenodd', 'stroke-linecap':'round', 'stroke-linejoin':'round', 'stroke-miterlimit':'4.1', '-inkscape-stroke':'none'}}/>
                                  </g>
                                </svg>
                              </span>
                              <div className="graphic-radio__bg"></div>
                            </div>
                            <span className="graphic-radio__title">EAN 8</span>
                          </label>
                        </div>
                      </div>
                    </div>

                  </div>
                </div> }

                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">
                    <div className="iconable-block iconable-block--hide-in-mobile">
                      <div className="iconable-block__infographics">
                        <span className="iconable-block__icon">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="#189dee" viewBox="0 0 20 20" focusable='false'>
                            <path d="M10 0C4.48 0 0 4.48 0 10s4.48 10 10 10 10-4.48 10-10S15.52 0 10 0zm5.71 8.11l-5.95 6.52c-.24.24-.56.37-.87.37-.32 0-.63-.12-.87-.37L4.7 11.55c-.48-.48-.48-1.28 0-1.77a1.21 1.21 0 011.73.01l2.46 2.19 5.08-5.64a1.22 1.22 0 011.73 0c.48.5.48 1.28.01 1.77z"/>
                          </svg>
                        </span>
                      </div>
                      <div className="iconable-block__content">
                        <div className="status-block">
                          <div className="status-block__central">
                            <div className="status-block__header">
                              <span className="status-block__title">
                                Enable Totals
                              </span>
                            </div>
                            <div className="status-block__content">
                              <p>
                                Enable totals. When enabled, totals will appear on modification tools, based on the produce prices and quantities set. 
                              </p>
                            </div>
                          </div>
                          <div className="status-block__primary-action">
                            <label className="checkbox big">
                              { displayTotals && <input type="checkbox" checked="true" onClick={() => handleTotalsSwitch()}/>}
                              { !displayTotals && <input type="checkbox" onClick={() => handleTotalsSwitch()}/>}
                              <div><div></div></div>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <UploadStoreLogo 
                  payload={payload}
                />

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  accounts: state.accounts,
  settings: state.settings.settings,
});

const mapDispatchToProps = (dispatch) => ({
  submitSettings: (payload, newSettings) => dispatch(updateSettings(payload, newSettings)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoreSettings);