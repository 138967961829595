import Axios from 'axios';
import { Config } from '../apiRoutes';
import * as mime from 'mime-types';

import {
  SETTINGS_REQUESTED,
  SETTINGS_SUCCESS,
  SETTINGS_FAILURE,
  SETTINGS_UPDATE_REQUESTED,
  SETTINGS_UPDATE_SUCCESS,
  SETTINGS_UPDATE_FAILURE,
  UPLOAD_TOKEN_REQUESTED,
  UPLOAD_TOKEN_SUCCESS,
  LOGO_UPLOAD_REQUESTED,
  LOGO_UPLOAD_SUCCESS,
  LOGO_UPLOAD_FAILURE,
} from '../types';

export const settingsRequested = () => {
  return {
    type: SETTINGS_REQUESTED
  }
};

export const settingsSuccess = (settings) => {
  return {
    type: SETTINGS_SUCCESS,
    payload: settings
  }
};

export const settingsFailure = () => {
  return {
    type: SETTINGS_FAILURE
  }
};

export const settingsUpdateRequested = () => {
  return {
    type: SETTINGS_UPDATE_REQUESTED
  }
};

export const settingsUpdateSuccess = (settings) => {
  return {
    type: SETTINGS_UPDATE_SUCCESS,
    payload: settings
  }
};

export const settingsUpdateFailure = () => {
  return {
    type: SETTINGS_UPDATE_FAILURE
  }
};

export const setUploadTokenRequested = () => {
  return {
    type: UPLOAD_TOKEN_REQUESTED
  }
};

export const setUploadTokenSuccess = (uploadLink) => {
  return {
    type: UPLOAD_TOKEN_SUCCESS,
    uploadLink: uploadLink
  }
};

export const setUploadRequested = () => {
  return {
    type: LOGO_UPLOAD_REQUESTED
  }
};

export const setUploadSuccess = () => {
  return {
    type: LOGO_UPLOAD_SUCCESS
  }
};

export const setUploadFailure = () => {
  return {
    type: LOGO_UPLOAD_FAILURE
  }
};

export const getSettings = (ecwidPayload) => {
  return (dispatch) => {
    dispatch(settingsRequested());

    let endPoint = Config.baseUrl + Config.settings;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(settingsSuccess(response.data));
      })
  }
}


export const updateSettings = (ecwidPayload, settings) => {
  return (dispatch) => {
    dispatch(settingsUpdateRequested());

    let endPoint = Config.baseUrl + Config.settings;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.post(endPoint, { 'settings': settings } , { headers: headers })
      .then((response) => {
        dispatch(settingsUpdateSuccess(response.data));
      })
  }
}

export const uploadLogo = (ecwidPayload, file) => {
  return (dispatch) => {
    dispatch(setUploadTokenRequested());

    let lastPeriodIndex = file.name.lastIndexOf(".");
    let extension = file.name.substring(lastPeriodIndex + 1);
    let fileNameWithoutExtension = file.name.substring(0, lastPeriodIndex);
    let cleanedFileName = fileNameWithoutExtension.replace(/ /g, '_').replace(/\./g, '_') + '.' + extension;

    let endPoint = Config.baseUrl + Config.settings + '/' + Config.upload + '/' + cleanedFileName;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    console.log(file);

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(setUploadTokenSuccess(response.data));
        dispatch(uploadAttachment(ecwidPayload, file, response.data.upload_link));
      })

  }
}

export const uploadAttachment = (ecwidPayload, file, uploadLink) => {
  return (dispatch) => {
    console.log(uploadLink);

    dispatch(setUploadRequested());

    let type = mime.contentType(file.name);

    var options = {
        headers: {
          'Content-Type': type
        }
      };

    Axios.put(uploadLink, file, options).then((response) => {
      console.log(response);
      dispatch(setUploadSuccess());
      dispatch(getSettings(ecwidPayload));
    })
    .catch((error) => {
      dispatch(setUploadFailure(error));
    })
    ;
  }
}