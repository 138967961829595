import React, { useState, useEffect } from 'react';

const DeleteDialog = ({ payload, setShowDeleteDialog, removeTemplate, activeUuid }) => {

  return (
    <div className="main-overlay">
      <div className="main-popup main-popup-top-only main-popup_center main-popup--thin-padding main-popup_animation">
        <div className="main-popup__container">
          <div className="main-popup__close" onClick={() => setShowDeleteDialog(false)}>
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.7929 1.7929c.3905-.3905 1.0237-.3905 1.4142 0L9 7.5858l5.7929-5.793c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L10.4142 9l5.7929 5.7929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L9 10.4142l-5.7929 5.7929c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L7.5858 9l-5.793-5.7929c-.3904-.3905-.3904-1.0237 0-1.4142z">
              </path>
            </svg>
          </div>
          <div className="main-popup__subcontainer">
            <h3 className="main-popup__title">
              <span className="gwt-InlineHTML">Delete Template</span>
            </h3>
            <div className="main-popup__text" aria-hidden="true" style={{display: 'none'}}></div>
            <div className="main-popup__content">
              Are you sure you want to delete this template?
            </div>
            <div className="main-popup__buttons">
              <button type="button" className="btn btn-primary btn-medium" tabindex="1000" onClick={() => setShowDeleteDialog(false)}>
                Cancel
              </button>
              <button type="button" className="btn btn-primary btn-medium" tabindex="1000" onClick={() => removeTemplate(activeUuid)}>
                Delete Template
              </button>
            </div>
            <div className="main-popup__footer-text" aria-hidden="true" style={{display: 'none'}}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteDialog;