import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import LoadingPlaceholder from '../partials/LoadingPlaceholder';
import PageHeader from "../partials/PageHeader";
import DeleteDialog from './DeleteDialog';

import { indexBarcodeTemplates, createBarcodeTemplate, updateBarcodeTemplate, previewBarcodeTemplate, deleteBarcodeTemplate } from '../../redux/actions/barcodeTemplates';


const BarcodeTemplates = ({ payload, getTemplates, barcodeTemplates, deleteTemplate }) => {
  const [showDeleteDialog, setShowDeleteDialog] = useState(false);
  const [activeUuid, setActiveUuid] = useState(false);

  useEffect(() => {
    getTemplates(payload);
  }, []);

  const removeTemplate = (uuid) => {
    deleteTemplate(payload, uuid);
    setShowDeleteDialog(false);
    getTemplates(payload);
  }

  const deleteDialog = (uuid) => {
    setActiveUuid(uuid);
    setShowDeleteDialog(true);
  }

  return(
    <>
    { barcodeTemplates.status === 'success' && !barcodeTemplates.isFetching && !barcodeTemplates.isDeleting &&
      <>
        <PageHeader stripeBackground='#5bc6cc' helpLink="ecwid/manage-barcode-label-templates/" />

        <div className="settings-page cf">
          <div className="settings-page__header">
            <div className="named-area">
              <div className="named-area__header">
                <div className="named-area__titles">
                  <div className="named-area__title">Label Templates</div>
                </div>
                <div className="named-area__description">
                  <p>Label templates are used for printing labels, usually with barcodes, and selected additional information.</p>
                  <p>From this page, you can add new templates, edit existing templates or delete templates that should no longer be used.</p>
                </div>
              </div>
              <div className="named-area__body">
                
                <div className="a-card-stack">
                  <div className="a-card a-card--compact">
                    <div className="a-card__paddings">
                      <h3>Existing Templates</h3>
                      { !barcodeTemplates.isFetching &&
                        <div className="flex-table">
                          <div className="flex-table__head">
                            <div className="flex-table__col flex-table__col--align-left">Template Name</div>
                            <div className="flex-table__col flex-table__col--align-center">Template Options</div>
                            <div className="flex-table__col flex-table__col--align-right">Actions</div>
                          </div>
                          { barcodeTemplates.templates.map(template =>
                            <div className="flex-table__row">
                              <div className="flex-table__col flex-table__col--align-left">{template.template_name}</div>
                              <div className="flex-table__col flex-table__col--align-center">
                                <strong>Print Type</strong>: { template.print_type }
                                <br/>
                                <strong>Size</strong>: {template.width || 3}{template.size_measurement} x {template.length || 1}{template.size_measurement}
                                <br/>
                                <strong>Rotate</strong>: {template.rotate && "Rotated"}{!template.rotate && "Not rotated"}
                              </div>
                              <div className="flex-table__col flex-table__col--align-right">
                                <Link to={`/templates/edit/${template.uuid}`}>
                                  <button className="btn btn-primary-accent btn-medium left-15 bottom-15">Edit</button>
                                </Link>
                                <button className="btn btn-destructive btn-medium left-15 bottom-15" onClick={() => deleteDialog(template.uuid)}>Delete</button>
                              </div>
                            </div>
                          )}
                        </div>
                      }

                    </div>
                  </div>
                  <div className="a-card a-card--compact">
                    <div className="a-card__paddings">
                      <Link to="/templates/new">
                        <button className="btn btn-success btn-medium">+ New Template</button>
                      </Link>
                    </div>
                  </div>

                </div>

              </div>

            </div>
          </div>
        </div>

        { showDeleteDialog && 
          <DeleteDialog
            payload={payload}
            setShowDeleteDialog={setShowDeleteDialog}
            removeTemplate={removeTemplate}
            activeUuid={activeUuid}
          />
        }

      </>
    }

    { (barcodeTemplates.status !== 'success' || barcodeTemplates.isFetching || barcodeTemplates.isDeleting) &&
      <>
        <PageHeader stripeBackground='#5bc6cc' helpLink="ecwid/manage-barcode-label-templates/" />
        <LoadingPlaceholder title="Barcode Templates" />
      </>
    }
    </>
  )

}

const mapStateToProps = state => ({
  barcodeTemplates: state.barcodeTemplates,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (payload) => dispatch(indexBarcodeTemplates(payload)),
  deleteTemplate: (payload, templateId) => dispatch(deleteBarcodeTemplate(payload, templateId)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BarcodeTemplates);