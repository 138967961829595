import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { generateAllBarcodes } from '../../redux/actions/barcodes';

const ConfirmGenerateAllDialog = ({ payload, setShowGenerateAllDialog, generateAllImages, barcodes }) => {
  return(
    <div className="main-overlay">
      <div className="main-popup main-popup-top-only main-popup_center main-popup--thin-padding main-popup_animation">
        <div className="main-popup__container">
          <div className="main-popup__close" onClick={() => setShowGenerateAllDialog(false)}>
            <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M1.7929 1.7929c.3905-.3905 1.0237-.3905 1.4142 0L9 7.5858l5.7929-5.793c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L10.4142 9l5.7929 5.7929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L9 10.4142l-5.7929 5.7929c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L7.5858 9l-5.793-5.7929c-.3904-.3905-.3904-1.0237 0-1.4142z">
              </path>
            </svg>
          </div>
          <div className="main-popup__subcontainer">

            <div className="a-card a-card--compact a-card--warning">
              <div className="a-card__paddings">
                <div className="iconable-block iconable-block--hide-in-mobile iconable-block--warning">
                  <div className="iconable-block__infographics"></div>
                  <div className="iconable-block__content">
                    <div className="cta-block">
                      <div className="cta-block__central">
                        <div className="cta-block__title">Performance warning</div>
                        <div className="cta-block__content">Depending on how many products and variations your store has, generating all barcodes may take a substantial amount of time.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h3 className="main-popup__title">
              <span className="gwt-InlineHTML">Generate All Barcodes</span>
            </h3>
            <div className="main-popup__text" aria-hidden="true" style={{display: 'none'}}></div>
            <div className="main-popup__content">
              <div className="text-default warning">
                Existing barcodes will be deleted. This action can't be undone.
              </div>
            </div>
            <div className="main-popup__buttons">
            { barcodes.status !== "requesting" &&
              <button type="button" className="btn btn-primary btn-medium" tabindex="1000" onClick={() => generateAllImages(payload)}>
                Generate All Barcodes
              </button>
            }
            { barcodes.status === "requesting" &&
              <button type="button" className="btn btn-primary btn-medium btn-loading" tabindex="1000" disabled>
                Generate All Barcodes
              </button>
            }
            </div>
            <div className="main-popup__footer-text" aria-hidden="true" style={{display: 'none'}}></div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  selectedProductList: state.products.selectedProductList,
  barcodes: state.barcodes,
});

const mapDispatchToProps = (dispatch) => ({
  generateAllImages: (payload) => dispatch(generateAllBarcodes(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmGenerateAllDialog);