export const Config = {
  headers: {
    'Content-Type': 'application/json',
    'Accept': '*/*'
  },

  baseUrl: process.env.REACT_APP_BACKEND_BASE_URL,
  account: 'account/',
  barcode: 'barcode/',
  barcodeTemplate: 'barcode_template',
  inventory: 'inventory/',
  bulkInventory: 'batch_inventory/',
  productSearch: 'product_search/',
  sku: 'sku/',
  search: 'find',
  replace: 'replace',
  delete: 'delete',
  generate: 'generate',
  generateAll: 'generate_all',
  batches: 'batches',
  download: 'download',
  update: 'update',
  preview: 'preview',
  print: 'print',
  addInventory: 'add_inventory',
  subtractInventory: 'subtract_inventory',
  setInventory: 'set_inventory',
  scanInventory: 'scan_inventory',
  batchResults: 'batch',
  allResults: 'results',
  batchReport: 'report',
  settings: 'settings',
  imageCheck: 'image_check',
  fungibles: 'fungibles',
  lineSettings: 'line_settings',
  upgrade: 'upgrade',
  categories: 'categories',
  order: 'order',
  order_statuses: 'order_statuses',
  orders: 'orders/',
  upload: 'upload',
  reverse: 'reverse/',
}
