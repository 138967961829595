import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import LoadingSpinner from '../partials/LoadingSpinner';

const ErrorRow = ({ event }) => {
  return(
    <div className="list-element list-element--normal list-element--has-hover">
      <div className="list-element__image">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18"><circle cx="9" cy="9" r="8" stroke="#F43C36" fill="none" stroke-width="2"/><path fill="#F43C36" d="M8.998 3.904a.908.908 0 0 0-.384.08.985.985 0 0 0-.325.237 1.098 1.098 0 0 0-.216.358c-.05.134-.075.278-.073.422v4.802c0 .29.105.57.292.776a.955.955 0 0 0 .706.321.955.955 0 0 0 .706-.321c.187-.206.293-.485.293-.776V5a1.108 1.108 0 0 0-.053-.434 1.032 1.032 0 0 0-.215-.37.918.918 0 0 0-.336-.235.847.847 0 0 0-.395-.058ZM9 14.1a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2Z"/></svg>
      </div>
      <div className="list-element__content">
        <div className="list-element__info">
          <div className="list-element__header">
            <div className="list-element__main-info">
              <div className="list-element__title"><h3>Error</h3></div><br/>
            </div>
          </div>

          <div className="flex-container">

            <div className="list-element__data-row flex-1">
              <div className="list-element__description">Error description: <span className="error">{event.error}</span></div><br/>
            </div>
            <div className="list-element__data-row flex-1">

            </div>

          </div>

        </div>
      </div>
    </div>
  )
}

export default ErrorRow;