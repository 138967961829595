import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  BATCH_INVENTORY_RESULTS_REQUESTED,
  BATCH_INVENTORY_RESULTS_SUCCESS,
  BATCH_INVENTORY_RESULTS_FAILURE,
  BATCH_INVENTORY_DOWNLOAD_REQUESTED,
  BATCH_INVENTORY_DOWNLOAD_SUCCESS,
  BATCH_INVENTORY_DOWNLOAD_FAILURE,
  BATCH_INVENTORY_REVERSE_REQUESTED,
  BATCH_INVENTORY_REVERSE_SUCCESS,
  BATCH_INVENTORY_REVERSE_FAILURE,
} from '../types';

export const batchInventoryResultsRequested = () => {
  return {
    type: BATCH_INVENTORY_RESULTS_REQUESTED
  }
};

export const batchInventoryResultsSuccess = (payload) => {
  return {
    type: BATCH_INVENTORY_RESULTS_SUCCESS,
    payload: payload
  }
};

export const batchInventoryResultsFailure = (error) => {
  return {
    type: BATCH_INVENTORY_RESULTS_FAILURE,
    error: error
  }
};

export const batchInventoryDownloadRequested = () => {
  return {
    type: BATCH_INVENTORY_DOWNLOAD_REQUESTED
  }
};

export const batchInventoryDownloadSuccess = (payload) => {
  return {
    type: BATCH_INVENTORY_DOWNLOAD_SUCCESS,
    payload: payload
  }
};

export const batchInventoryDownloadFailure = (error) => {
  return {
    type: BATCH_INVENTORY_DOWNLOAD_FAILURE,
    error: error
  }
};


export const reverseBatchRequested = () => {
  return {
    type: BATCH_INVENTORY_REVERSE_REQUESTED
  }
}

export const reverseBatchSuccess = (payload) => {
  return {
    type: BATCH_INVENTORY_REVERSE_SUCCESS,
    payload: payload
  }
}

export const reverseBatchFailure = (error) => {
  return {
    type: BATCH_INVENTORY_REVERSE_FAILURE,
    error: error
  }
}

export const getBatchResults = (ecwidPayload) => {
  return (dispatch) => {
    dispatch(batchInventoryResultsRequested());

    let endPoint = Config.baseUrl + Config.bulkInventory + Config.allResults;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(batchInventoryResultsSuccess(response.data));
      })
      .catch((error) => {
        dispatch(batchInventoryResultsFailure(error.response.data));
      });
  }
}

export const downloadBatchResult = (ecwidPayload, batchId) => {
  return (dispatch) => {
    dispatch(batchInventoryDownloadRequested());

    let endPoint = Config.baseUrl + Config.bulkInventory + Config.batchReport + '?batchId=' + batchId;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(batchInventoryDownloadSuccess(response.data));
      })
      .catch((error) => {
        dispatch(batchInventoryDownloadFailure(error.response.data));
      });

  }
}

export const reverseBatchAction = (ecwidPayload, ticketId) => {
  return (dispatch) => {
    dispatch(reverseBatchRequested());

    let endPoint = Config.baseUrl + Config.bulkInventory + Config.reverse + ticketId;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(reverseBatchSuccess(response.data));
        dispatch(getBatchResults(ecwidPayload));
      })
      .catch((error) => {
        dispatch(reverseBatchFailure(error.response.data));
      });
  }
}
