import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from "react-router-dom";

import PrintLine from "./PrintLine";
import LoadingSpinner from '../partials/LoadingSpinner';
import ModifyCountDialog from './ModifyCountDialog';
import PageHeader from '../partials/PageHeader';

import { indexBarcodeTemplates } from '../../redux/actions/barcodeTemplates';
import { requestPrint } from '../../redux/actions/prints';
import NoLabelTemplatesBox from '../partials/NoLabelTemplatesBox';

const PrintBarcodes = ({ payload, getTemplates, selectedProductList, barcodeTemplates, postPrint, downloadUrl, prints, missingBarcodes }) => {
  const [submitted, setSubmitted] = useState(false);
  const [activeTemplate, setActiveTemplate] = useState({});
  const [modifyItem, setModifyItem] = useState(null);
  const [modifyType, setModifyType] = useState(null);
  const [modifyCount, setModifyCount] = useState(null);
  const [modifyItemId, setModifyItemId] = useState(null);
  const [showPrintCountDialog, setShowPrintCountDialog] = useState(false);
  const [labelCount, setLabelCount] = useState(0);
  const [printCounts, setPrintCounts] = useState({
      products: {},
      variations: {}
    }
  )

  const switchActiveTemplate = (event) => {
    let template = barcodeTemplates.templates.find(template => template.uuid === event.target.value);
    setActiveTemplate(template);
  }

  const setPrintCount = (type, itemId, modifyItem, count) => {
    let tempCounts = printCounts;

    tempCounts[type][itemId] = {
      count: count,
      item: modifyItem
    };

    setPrintCounts(tempCounts);
    calculateTotal();
  }

  const calculateTotal = () => {
    let tempTotal = 0;

    Object.keys(selectedProductList.products).map(itemId =>
      tempTotal = tempTotal + (parseInt(printCounts.products[itemId]?.count) || 0)
    )

    Object.keys(selectedProductList.variations).map(itemId => 
      tempTotal = tempTotal + (parseInt(printCounts.variations[itemId]?.count) || 0)
    )

    setLabelCount(tempTotal);
  }

  const setAllToZero = () => {
    let tempCounts = {
      products: {},
      variations: {}
    };

    Object.keys(selectedProductList.products).map(itemId =>
      tempCounts.products[itemId] = {
        count: 0,
        item: selectedProductList.products[itemId]
      }
    )

    Object.keys(selectedProductList.variations).map(itemId =>
      tempCounts.variations[itemId] = {
        count: 0,
        item: selectedProductList.variations[itemId]
      }
    )

    setPrintCounts(tempCounts);
  }

  const setAllToOne = () => {
    let tempCounts = {
      products: {},
      variations: {}
    };

    Object.keys(selectedProductList.products).map(itemId =>
      tempCounts.products[itemId] = {
        count: 1,
        item: selectedProductList.products[itemId]
      }
    )

    Object.keys(selectedProductList.variations).map(itemId =>
      tempCounts.variations[itemId] = {
        count: 1,
        item: selectedProductList.variations[itemId]
      }
    )

    setPrintCounts(tempCounts);
  }

  const setAllToInventory = () => {
    let tempCounts = {
      products: {},
      variations: {}
    };

    Object.keys(selectedProductList.products).map(itemId =>
      tempCounts.products[itemId] = {
        count: selectedProductList.products[itemId].quantity,
        item: selectedProductList.products[itemId]
      }
    )

    Object.keys(selectedProductList.variations).map(itemId =>
      tempCounts.variations[itemId] = {
        count: selectedProductList.variations[itemId].quantity,
        item: selectedProductList.variations[itemId]
      }
    )

    setPrintCounts(tempCounts);
  }

  const download = () => {
    postPrint(payload, activeTemplate.uuid, printCounts);
    setSubmitted(true);
  }

  useEffect(() => {
    getTemplates(payload);
    setAllToZero();
  }, []);

  useEffect(() => {
    calculateTotal();
  }, [printCounts]);

  useEffect(() => {
    if(downloadUrl && submitted) { 
      let win = window.open(downloadUrl, '_blank');
      win.focus();
      setSubmitted(false);
    }
  }, [downloadUrl]);

  useEffect(() => {
    if (barcodeTemplates.status === 'success' && barcodeTemplates.templates.length > 0) {      
      setActiveTemplate(barcodeTemplates.templates[0]);
    }
  }, [barcodeTemplates]);

  return(
    <>
      <PageHeader stripeBackground='#1774bb' helpLink="ecwid/print-barcode-labels-2/" />
      <div className="settings-page cf">
        <div className="settings-page__header">
          <div className="named-area">
            <div className="named-area__header">
              <div className="named-area__titles">
                <div className="named-area__title">Print Barcodes</div>
              </div>
              <div className="named-area__description">
                <p>Select a template to print the selected barcodes with by choosing it from the Templates dropdown.</p>
                <p>You can select the number of labels to print for all products from the buttons from the top card. "Set All to 0" sets the print count of all items to 0, "Set All to 1" sets the counts to 1, and "Set All to Quantity in Stock" will set the number of labels to print to match the number of items of that type in stock.</p>
                <p>You can also click on any item to modify the print count of any individual item.</p>
                <p>Once you have a template selected and a print count set for your items, click the "Confirm and Download Print File" button to download a print pdf.</p>
                <p>Note: you can still print labels for items that are missing barcodes. The labels will have an empty space where the barcode would have been. If your label template doesn't use a barcode row, you can ignore these warnings.</p>
              </div>
            </div>
            <div className="named-area__body">
              <div className="a-card-stack">
                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">
                    { barcodeTemplates.status === 'success' && barcodeTemplates.templates.length > 0 && 
                      <div className="fieldset fieldset--select">
                        <div className="field field--medium field--filled">
                          <label className="field__label">Templates</label>
                          <select value={activeTemplate?.uuid} id="templates" onChange={(e) => switchActiveTemplate(e)} className="field__select">
                            <option hidden disabled selected value></option>
                            { barcodeTemplates.templates.map(template =>
                              <option value={template.uuid}>{template.template_name}</option>
                            )}
                          </select>

                          <div className="field__placeholder">Templates</div>
                        </div>
                      </div>
                    }
                    { barcodeTemplates.status === 'success' && barcodeTemplates.templates.length === 0 && 
                      <NoLabelTemplatesBox/>
                    }
                    
                    { barcodeTemplates.status !== 'success' &&
                      <LoadingSpinner statusText="Loading Templates" />
                    }
                    <br/>
                    
                    { activeTemplate?.uuid && (labelCount > 0) && (prints.status !== "requesting") && 
                      <>
                        <button className="top-15 btn btn-primary btn-medium" onClick={() => download()}>Confirm and Download Print File</button> 
                        <div class="text-default warning">
                          Please ensure your browser allows popups for this page when downloading your print file.
                        </div>
                      </>
                    }
                    { (!activeTemplate.uuid || (labelCount === 0)) && (prints.status !== "requesting") && <button className="top-15 btn btn-primary btn-medium" disabled>Confirm and Download Print File</button> }
                    { prints.status === "requesting" && 
                      <>
                        <LoadingSpinner statusText="Generating Print File" />
                        <div class="text-default warning">
                          Please ensure your browser allows popups for this page when downloading your print file.
                        </div>
                      </>
                    }
                  </div>
                </div>

                <div className="a-card a-card--compact">
                  <div className="a-card__paddings">
                    <div className="flex-container flex-end">
                      <button className="fit-content flex-1 btn btn-default btn-medium left-15" onClick={() => setAllToZero()}>Set All to 0</button>
                      <button className="fit-content flex-1 btn btn-default btn-medium left-15" onClick={() => setAllToOne()}>Set All to 1</button>
                      <button className="fit-content flex-1 btn btn-default btn-medium left-15" onClick={() => setAllToInventory()}>Set All to Quantity in Stock</button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="filtered-list__items long-list">
                { Object.keys(selectedProductList.products).map(itemId =>
                    <PrintLine
                      id={itemId}
                      itemId={itemId}
                      item={selectedProductList.products[itemId]}
                      type='products'
                      printCount={printCounts.products[itemId]?.count}
                      setModifyItem={setModifyItem}
                      setModifyType={setModifyType}
                      setModifyCount={setModifyCount}
                      setModifyItemId={setModifyItemId}
                      setShowPrintCountDialog={setShowPrintCountDialog}
                      missingBarcode={ (missingBarcodes.indexOf(selectedProductList.products[itemId].sku) !== -1) || (missingBarcodes.indexOf(selectedProductList.products[itemId].upc) !== -1) }
                    />
                  )
                }
                { Object.keys(selectedProductList.variations).map(itemId =>
                    <PrintLine
                      id={itemId}
                      itemId={itemId}
                      item={selectedProductList.variations[itemId]}
                      type='variations'
                      printCount={printCounts.variations[itemId]?.count}
                      setModifyItem={setModifyItem}
                      setModifyType={setModifyType}
                      setModifyCount={setModifyCount}
                      setModifyItemId={setModifyItemId}
                      setShowPrintCountDialog={setShowPrintCountDialog}
                      missingBarcode={ (missingBarcodes.indexOf(selectedProductList.variations[itemId].sku) !== -1) || (missingBarcodes.indexOf(selectedProductList.variations[itemId].upc) !== -1) }
                    />
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      
      { showPrintCountDialog && 
        <ModifyCountDialog
          modifyItemId={modifyItemId}
          modifyItem={modifyItem}
          modifyType={modifyType}
          modifyCount={modifyCount}
          setPrintCount={setPrintCount}
          setShowPrintCountDialog={setShowPrintCountDialog}
        />
      }
    </>
  )
}

const mapStateToProps = state => ({
  selectedProductList: state.products.selectedProductList,
  missingBarcodes: state.products.missingBarcodes,
  barcodeTemplates: state.barcodeTemplates,
  prints: state.prints,
  downloadUrl: state.prints.downloadUrl,
});

const mapDispatchToProps = (dispatch) => ({
  getTemplates: (payload) => dispatch(indexBarcodeTemplates(payload)),
  postPrint: (payload, template, printCounts) => dispatch(requestPrint(payload, template, printCounts)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PrintBarcodes);
