import React, { useState, useEffect } from 'react';

const ConfirmReverse = ({ payload, reverseAndRefresh, ticketId, setReversalId }) => {
  return(
    <div className="a-card a-card--compact a-card--error">
      <div className="a-card__paddings">
        <div className="iconable-block iconable-block--hide-in-mobile iconable-block--warning">
          <div className="iconable-block__infographics"></div>
          <div className="iconable-block__content">
            <div className="cta-block">
              <div className="cta-block__central">
                <div className="cta-block__title">Reverse Changes</div>
                <div className="cta-block__content">Reverse the actions taken in ticket {ticketId}. This action can't be undone.</div>
              </div>
              <div className="cta-block__cta">
                <button type="button" className="btn btn-primary btn-medium" onClick={() => reverseAndRefresh(payload, ticketId)}>
                  Confirm Reversal
                </button>
              </div>
            </div>
            <span className="alert-close-mark" onClick={() => setReversalId(null)}>
              <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M1.7929 1.7929c.3905-.3905 1.0237-.3905 1.4142 0L9 7.5858l5.7929-5.793c.3905-.3904 1.0237-.3904 1.4142 0 .3905.3906.3905 1.0238 0 1.4143L10.4142 9l5.7929 5.7929c.3905.3905.3905 1.0237 0 1.4142-.3905.3905-1.0237.3905-1.4142 0L9 10.4142l-5.7929 5.7929c-.3905.3905-1.0237.3905-1.4142 0-.3905-.3905-.3905-1.0237 0-1.4142L7.5858 9l-5.793-5.7929c-.3904-.3905-.3904-1.0237 0-1.4142z"></path></svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmReverse;