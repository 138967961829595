import Axios from 'axios';
import { Config } from '../apiRoutes';

import {
  READ_ORDER_REQUESTED,
  READ_ORDER_SUCCESS,
  READ_ORDER_FAILURE,
  UPDATE_ORDER_STATUS_REQUESTED,
  UPDATE_ORDER_STATUS_SUCCESS,
  UPDATE_ORDER_STATUS_FAILURE,
  INDEX_ORDER_STATUSES_REQUESTED,
  INDEX_ORDER_STATUSES_SUCCESS,
  INDEX_ORDER_STATUSES_FAILURE,
  CLEAR_ORDER,
  SET_CHECKED_LIST,
  SET_UNPICKABLE_LIST,
} from '../types';


export const readOrderRequested = () => {
  return {
    type: READ_ORDER_REQUESTED
  }
};

export const readOrderSuccess = (payload) => {
  return {
    type: READ_ORDER_SUCCESS,
    payload: payload
  }
};

export const readOrderFailure = () => {
  return {
    type: READ_ORDER_FAILURE
  }
};

export const indexOrderStatusesRequested = () => {
  return {
    type: INDEX_ORDER_STATUSES_REQUESTED
  }
};

export const indexOrderStatusesSuccess = (payload) => {
  return {
    type: INDEX_ORDER_STATUSES_SUCCESS,
    payload: payload
  }
};

export const indexOrderStatusesFailure = () => {
  return {
    type: INDEX_ORDER_STATUSES_FAILURE
  }
};


export const updateFulfillmentStatusRequested = () => {
  return {
    type: UPDATE_ORDER_STATUS_REQUESTED
  }
}

export const updateOrderStatusSuccess = (payload) => {
  return {
    type: UPDATE_ORDER_STATUS_SUCCESS,
    payload: payload
  }
}

export const updateOrderStatusFailure = () => {
  return {
    type: UPDATE_ORDER_STATUS_FAILURE
  }
}


export const readOrder = (ecwidPayload, orderId) => {
  return (dispatch) => {
    dispatch(readOrderRequested());

    let endPoint = Config.baseUrl + Config.order + '/' + orderId;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(readOrderSuccess(response.data));
      })
      .catch((error) => {
        dispatch(readOrderFailure());
      });
  }
}

export const indexOrderStatuses = (ecwidPayload) => {
  return (dispatch) => {
    dispatch(indexOrderStatusesRequested());

    let endPoint = Config.baseUrl + Config.order_statuses;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.get(endPoint, { headers: headers })
      .then((response) => {
        dispatch(indexOrderStatusesSuccess(response.data));
      })
      .catch((error) => {
        dispatch(indexOrderStatusesFailure());
      });
  }
}

export const updateOrderStatus = (ecwidPayload, orderId, status, statusType) => {
  return (dispatch) => {
    dispatch(updateFulfillmentStatusRequested());

    let endPoint = Config.baseUrl + Config.orders + orderId;
    let headers = Object.assign(Config.headers);
    headers.payload = ecwidPayload;

    return Axios.put(endPoint, { status: status, status_type: statusType } , { headers: headers })
      .then((response) => {
        dispatch(updateOrderStatusSuccess(response.data));
      })
      .catch((error) => {
        dispatch(updateOrderStatusFailure());
      });
  }
}

export const setCheckedList = (checkedList) => {
  return (dispatch) => {
    dispatch({
      type: SET_CHECKED_LIST,
      checkedList: checkedList
    })
  }
}

export const setUnpickableList = (unpickableList) => {
  return (dispatch) => {
    dispatch({
      type: SET_UNPICKABLE_LIST,
      unpickableList: unpickableList
    })
  }
}

export const clearOrder = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_ORDER
    })
  }
}

