const LineItem = ({ lineEvent, lineItems, lineItemValues, setValue, actionId, submitted, removeItem }) => {
  const validateValue = (event) => {
    let value = event.target.value.replace(/\D/g,'');
    setValue(value, lineEvent);
    if (value) {
      event.target.parentElement.classList.add("field--filled");
    } else {
      event.target.parentElement.classList.remove("field--filled");
    }
  }

  const focus = (event) => {
    event.target.parentElement.classList.add("field--focus");
  }

  const blur = (event) => {
    event.target.parentElement.classList.remove("field--focus");
  }

  if(lineEvent.error) {
    return(
      <div className="list-element list-element--normal list-element--has-hover">
        <div className="list-element__image">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="none" viewBox="0 0 18 18"><circle cx="9" cy="9" r="8" stroke="#F43C36" fill="none" stroke-width="2"/><path fill="#F43C36" d="M8.998 3.904a.908.908 0 0 0-.384.08.985.985 0 0 0-.325.237 1.098 1.098 0 0 0-.216.358c-.05.134-.075.278-.073.422v4.802c0 .29.105.57.292.776a.955.955 0 0 0 .706.321.955.955 0 0 0 .706-.321c.187-.206.293-.485.293-.776V5a1.108 1.108 0 0 0-.053-.434 1.032 1.032 0 0 0-.215-.37.918.918 0 0 0-.336-.235.847.847 0 0 0-.395-.058ZM9 14.1a1.1 1.1 0 1 0 0-2.2 1.1 1.1 0 0 0 0 2.2Z"/></svg>
        </div>
        <div className="list-element__content">
          <div className="list-element__info">
            <div className="list-element__header">
              <div className="list-element__main-info">
                <div className="list-element__title"><h3>Error</h3></div>
                <a className="icolink" onClick={() => removeItem(lineEvent, actionId)}>
                  <span class="svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M7 0h4l1 2H6l1-2z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2 3c-.5523 0-1 .4477-1 1s.4477 1 1 1v11c0 1.1046.8954 2 2 2h10c1.1046 0 2-.8954 2-2V5c.5523 0 1-.4477 1-1s-.4477-1-1-1H2zm12 13V5H4v11h10z"/><path d="M6 7.5c0-.5523.4477-1 1-1s1 .4477 1 1v6c0 .5523-.4477 1-1 1s-1-.4477-1-1v-6zM10 7.5c0-.5523.4477-1 1-1s1 .4477 1 1v6c0 .5523-.4477 1-1 1s-1-.4477-1-1v-6z"/></svg>
                  </span>
                </a>
                <br/>
              </div>
            </div>

            <div className="flex-container">

              <div className="list-element__data-row flex-1">
                <div className="list-element__description">Error description: <span className="error">{lineEvent.error}</span></div><br/>
              </div>
              <div className="list-element__data-row flex-1">

              </div>

            </div>

          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="list-element list-element--normal list-element--has-hover">
      <div className="list-element__image">
        { lineEvent.smallThumbnailUrl && <img src={lineEvent.smallThumbnailUrl} /> }
        { !lineEvent.smallThumbnailUrl && <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 123 123"><g stroke="#c2cbd3" stroke-miterlimit="10"><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M77.46 22.7H48.28l2.02-7.04h25.15zm0 43.26H48.28l2.02 7.05h25.15z"/><path fill="#edeff2" d="M52.63 7.59h20.48v74.29H52.63z"/><path fill="#fff" stroke-linecap="round" stroke-linejoin="round" d="M91.903 39.907v8.59h-3.82v-8.59z"/><ellipse cx="63.08" cy="44.21" fill="#fff" stroke-linecap="round" stroke-linejoin="round" rx="25.76" ry="25.53"/><g fill="#fff" stroke-linecap="round" stroke-linejoin="round"><ellipse cx="63.08" cy="44.13" rx="21.8" ry="21.6"/><path d="M51.34 33.05l11.91 11.24 7.47-3.93"/><ellipse cx="63.08" cy="44.13" rx="1.78" ry="1.76"/></g></g></svg> }
      </div>
      <div className="list-element__content">
        <div className="list-element__info">
          <div className="list-element__header">
            <div className="list-element__main-info">
              <div className="list-element__title">
                <div className="flex-container">
                  <h3>{lineEvent.name}</h3>
                </div>
              </div>
              <a className="icolink" onClick={() => removeItem(lineEvent, actionId)}>
                <span class="svg-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18"><path d="M7 0h4l1 2H6l1-2z"/><path fill-rule="evenodd" clip-rule="evenodd" d="M2 3c-.5523 0-1 .4477-1 1s.4477 1 1 1v11c0 1.1046.8954 2 2 2h10c1.1046 0 2-.8954 2-2V5c.5523 0 1-.4477 1-1s-.4477-1-1-1H2zm12 13V5H4v11h10z"/><path d="M6 7.5c0-.5523.4477-1 1-1s1 .4477 1 1v6c0 .5523-.4477 1-1 1s-1-.4477-1-1v-6zM10 7.5c0-.5523.4477-1 1-1s1 .4477 1 1v6c0 .5523-.4477 1-1 1s-1-.4477-1-1v-6z"/></svg>
                </span>
              </a>
              <br/>
            </div>
          </div>
          <div className="flex-container">
            <div className="list-element__data-row flex-1">
              <div className="list-element__description">SKU: <span className="muted">{lineEvent.sku}</span></div><br/>
              <div className="list-element__description">Quantity In Stock: <span className="info">{lineEvent.quantity}</span></div><br/>
            </div>

            <div className="list-element__data-row flex-1">
              <ul>
                { lineEvent.options && lineEvent.options.map(option =>
                    <li>
                      <strong>{ option.name }</strong>: { option.value }
                    </li>
                  )
                }
                { !lineEvent.options &&
                  <div class="text-default warning">
                    Base Product
                  </div>
                }
              </ul>
            </div>

            <div className="list-element__data-row flex-1 text-center">
              <h4>Order Quantity</h4>
              
              { !lineEvent.placeholder && <div className="fieldset">
                <div className="field field--medium field--filled">
                  <span className="fieldset__svg-icon"></span>
                  <label className="field__label">Quantity</label>
                  { !submitted && <input type="text" value={lineItemValues[lineEvent.sku] || ''} onFocus={focus} onBlur={blur} onChange={validateValue} className="field__input" tabindex="4" /> }
                  { submitted && <input type="text" value={lineItemValues[lineEvent.sku] || ''} onFocus={focus} onBlur={blur} onChange={validateValue} className="field__input" tabindex="4" disabled /> }
                  <div className="field__placeholder">Quantity</div>
                </div>
              </div> }

            </div>

          </div>

        </div>
      </div>
    </div>
  )

}

export default LineItem;


